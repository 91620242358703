const MODULES = {
  users: 'ary.users',
  users_groups: 'ary.users.groups',
  clients: 'ary.clients',
  clients_banking: 'ary.clients.banking',
  organizations: 'ary.orgs',
  permissions: 'ary.permissions',
  modules: 'ary.modules',
  groups: 'ary.groups',
  process_ary: 'ary.process.ary',
  process_erp: 'ary.process.erp',
  billing_dashboard: 'ary.billing.dashboard',
  diagrams_bpmn: 'ary.diagrams.bpmn',
  google_authentication: 'ary.auth.google',
  business: 'ary.business',
  bi_nickel: 'ary.nickel.dashboard',
  bi_geofolia: 'ary.geofolia.dashboard',
  bi_alfalite: '',
  bam: 'ary.bam',
  accounting: 'ary.ayesa.contable',
  catalog: 'ary.catalogs',
  expense_process: 'ary.dashboard.states',
  income_process: 'aggraria.dashboard.income.states',
  income_analytics: 'aggraria.dashboard.income.analytics',
  expense_analytics: 'aggraria.dashboard.expenses.analytics',
  results_analytics: 'aggraria.dashboard.resulting.analytics',
}

export default MODULES;