import React from 'react';
// Redux
import { useAppDispatch } from '../../../consts/ReduxHooks';
import { GroupFilters } from '../../../types/redux/filtersTypes';
import { setFilters, resetFilters } from '../../../store/reducers/filtersReducer';
// App
import DashboardComponent from '../../layouts/DashboardComponent';
import GroupListComponent from '../../views/Groups/GroupsListComponent';
import MODULES from '../../../consts/Modules';
import PERMISSIONS from '../../../consts/Permissions';
// Services
import hasModule from '../../../services/hasModule';
// Other
import { Switch, Route, Redirect, RouteComponentProps } from 'react-router-dom';

export default function GroupsRouting(props: RouteComponentProps) {
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    dispatch(setFilters({ ...new GroupFilters() }));
    return () => {
      dispatch(resetFilters());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DashboardComponent
      title="Grupos"
      children={<Switch>
        <Route exact path={props.match.path + '/list'} render={props => <GroupListComponent
          hideList={!hasModule(MODULES.groups, PERMISSIONS.list)}
          hideCreate={!hasModule(MODULES.groups, PERMISSIONS.create)}
          hideEdit={!hasModule(MODULES.groups, PERMISSIONS.update)}
        />} />
        <Redirect to={props.match.path + '/list'} />
      </Switch>}
    />
  );
}
