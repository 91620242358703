//v1.7
import React, { FC } from 'react';
// Material UI
import { makeStyles, Theme, createStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import RootRef from '@material-ui/core/RootRef';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Collapse from '@material-ui/core/Collapse';
import Accordion, { AccordionProps } from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import IconButton from '@material-ui/core/IconButton';
import Checkbox from '@material-ui/core/Checkbox';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
// App
import { FancyProgress, useFancyStyles } from '../FancyComponents';
import FancyActionButton from './FancyActionButton';
import { spreadProps } from './utils';
// Types
import { FancyListProps, FancyListItemProps } from './types';
// Other
import InfiniteScroll from 'react-infinite-scroll-component';
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';

// Dependencies:
// @material-ui/core and @material-ui/icons
// react-beautiful-dnd and @types/react-beautiful-dnd
// react-infinite-scroll-component

export default function FancyList<T extends any>(props: FancyListProps<T>) {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      list: {
        marginBottom: 50
      },
      listItem: {
        width: '100%',
      },
      listItemIcon: {
        [theme.breakpoints.down('xs')]: {
          minWidth: 40,
        }
      },
      checkbox: {
        [theme.breakpoints.down('xs')]: {
          padding: theme.spacing(0.5),
        }
      },
      listItem_secondaryAction: {
        paddingRight: (30 + theme.spacing(1)) * (!props.expand && !!props.recursive ? 2 : 1),
        paddingLeft: !!props.expand ? 0 : 8,
        [theme.breakpoints.up('sm')]: {
          paddingLeft: !!props.expand ? 0 : 16,
          paddingRight: (48 + theme.spacing(1)) * ((props.actionButtons ? props.actionButtons.filter(button => !button.menuOnly).length : 0) + (!props.expand && !!props.recursive ? 1 : 0))
        },
      },
      listItemSecondaryAction: {
        right: theme.spacing(1),
      },
      accordionSummary: {
        [theme.breakpoints.down('xs')]: {
          padding: theme.spacing(0, 1),
        }
      },
    })
  );
  const classes = useStyles();
  const styles = useFancyStyles();
  const theme = useTheme();
  const matchesDesktop = useMediaQuery(theme.breakpoints.up('sm'));
  const [expanded, setExpanded] = React.useState<number | false>(false);

  const getId = (element: any) => props.idKey ? element[props.idKey] : element.id;

  const checkboxToggleHandler = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, element: T) => {
    event.stopPropagation();
    if (props.check) {
      props.checkboxOnChange(element);
    }
  };

  const accordionChangeHandler = (element: T) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
    setExpanded(isExpanded ? getId(element) : false);
    if (props.expand && props.onAccordionChange) {
      props.onAccordionChange(isExpanded, element);
    }
  };

  const listItemClickHandler = (event: any, element: T) => {
    const ListItemProps = spreadProps(element, props.ListItemProps);
    if (ListItemProps && ListItemProps.onClick) {
      ListItemProps.onClick(event);
    }
  };

  const listItemIconClickHandler = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, element: T) => {
    event.stopPropagation();
    const listItemIconProps = spreadProps(element, props.ListItemIconProps)?.onClick;
    if (listItemIconProps) {
      listItemIconProps(event);
    }
  };

  const reorder = (list: T[], startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result: DropResult) => {
    if (props.dragDrop) {
      // dropped outside the list
      if (!result.destination) {
        return;
      }
      const items = reorder(
        props.elements,
        result.source.index,
        result.destination.index
      );
      props.onDragEnd(items, result);
    }
  }

  const FancyListItem = (listProps: FancyListItemProps) => {
    const level = listProps.level - 1;
    const maxLevel = matchesDesktop ? 5 : 3;
    const increment = level > maxLevel ? maxLevel : level;
    const spacing = matchesDesktop ? 4 : 2;
    const leftPadding = matchesDesktop ? 2 : 1;
    return <React.Fragment>
      <ListItem
        button={!props.expand && (!!props.recursive || !!spreadProps(listProps.element, props.ListItemProps)?.onClick)}
        {...listProps.ListItemProps}
        ContainerComponent="div"
        {...spreadProps(listProps.element, props.ListItemProps)}
        style={{
          ...listProps.ListItemProps?.style,
          ...spreadProps(listProps.element, props.ListItemProps)?.style,
          paddingLeft: theme.spacing((increment * spacing) + leftPadding)
        }}
        classes={{
          secondaryAction: [classes.listItem_secondaryAction, listProps.ListItemProps?.classes?.secondaryAction, spreadProps(listProps.element, props.ListItemProps)?.classes?.secondaryAction].join(' '),
          container: [classes.listItem, listProps.ListItemProps?.classes?.container, spreadProps(listProps.element, props.ListItemProps)?.classes?.container].join(' '),
        }}
        onClick={(event: any) => listItemClickHandler(event, listProps.element)}
        ContainerProps={{ role: 'listitem' }}
      >
        {
          props.check &&
          <ListItemIcon
            {...spreadProps(listProps.element, props.ListItemIconProps)}
            className={[classes.listItemIcon, spreadProps(listProps.element, props.ListItemIconProps)?.className].join(' ')}
            onClick={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => listItemIconClickHandler(event, listProps.element)}
          >
            <Checkbox
              color="primary"
              size={matchesDesktop ? 'medium' : 'small'}
              checked={props.checked && props.checked.indexOf(getId(listProps.element)) !== -1}
              {...spreadProps(listProps.element, props.CheckboxProps)}
              className={[classes.checkbox, spreadProps(listProps.element, props.CheckboxProps)?.className].join(' ')}
              onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => checkboxToggleHandler(event, listProps.element)}
              inputProps={{ 'aria-label': `listitem ${getId(listProps.element)}` }}
            />
          </ListItemIcon>
        }
        {
          props.ListItemIconProps && !props.check &&
          <ListItemIcon {...spreadProps(listProps.element, props.ListItemIconProps)} />
        }
        {
          (props.AvatarProps) &&
          <ListItemAvatar {...spreadProps(listProps.element, props.ListItemAvatarProps)}>
            <Avatar {...spreadProps(listProps.element, props.AvatarProps)} />
          </ListItemAvatar>
        }
        <ListItemText {...spreadProps(listProps.element, props.ListItemTextProps)} />
        {
          (props.actionButtons || (!props.expand && !!props.recursive)) &&
          <ListItemSecondaryAction
            {...spreadProps(listProps.element, props.ListItemSecondaryActionProps)}
            className={[classes.listItemSecondaryAction, spreadProps(listProps.element, props.ListItemSecondaryActionProps)?.className].join(' ')}
          >
            {
              props.actionButtons && props.actionButtons.filter(button => button.show === undefined || (typeof button.show === 'boolean' ? button.show : button.show(listProps.element))).length > 0 &&
              <FancyActionButton actions={props.actionButtons} element={listProps.element} />
            }
            {
              !props.expand && !!props.recursive && props.hasChildren(listProps.element, level) &&
              <IconButton
                color="primary"
                onClick={(event) => props.onRecursiveClick && props.onRecursiveClick(event, listProps.element)}
                size={matchesDesktop ? 'medium' : 'small'}
                className={styles.ml_1}
              >
                {
                  props.expandedElements && props.expandedElements.includes(getId(listProps.element))
                    ? <ExpandLessIcon /> : <ExpandMoreIcon />
                }
              </IconButton>
            }
          </ListItemSecondaryAction>
        }
      </ListItem>
      {
        !props.expand && !!props.recursive && <React.Fragment>
          <Divider style={{ marginLeft: theme.spacing(increment * spacing) }} />
          {
            props.hasChildren && props.hasChildren(listProps.element, level) &&
            <Collapse
              in={props.expandedElements && props.expandedElements.includes(getId(listProps.element))}
              timeout="auto"
            >
              {
                props.recursiveChildren && props.recursiveChildren(listProps.element, FancyListItem, { level: listProps.level + 1 })
              }
            </Collapse>
          }
        </React.Fragment>
      }
    </React.Fragment>
  };

  const AccordionWrapper: FC<{ element: T, AccordionProps?: Omit<AccordionProps, 'children'> }> = accProps => <React.Fragment>
    {
      !!props.expand ?
        <Accordion
          variant="outlined"
          {...accProps.AccordionProps}
          expanded={expanded === getId(accProps.element)}
          onChange={accordionChangeHandler(accProps.element)}
          TransitionProps={{ unmountOnExit: true }}
        >
          <AccordionSummary
            expandIcon={matchesDesktop ? <ExpandMoreIcon color="primary" /> : null}
            IconButtonProps={{ size: matchesDesktop ? 'medium' : 'small' }}
            className={classes.accordionSummary}
            id={`accordion_summary_${getId(accProps.element)}}`}
            aria-controls={`accordion_summary_${getId(accProps.element)}}`}
            aria-label={'accordion header: ' + getId(accProps.element)}
          >
            {accProps.children}
          </AccordionSummary>
          {
            props.expandContent &&
            <AccordionDetails>
              {props.expandContent(accProps.element)}
            </AccordionDetails>
          }
        </Accordion> : accProps.children
    }
  </React.Fragment>;

  const InfiniteWrapper: FC = (wrapperProps) => <List
    {...props.ListProps}
    className={[!props.disablePadding ? classes.list : '', props.ListProps?.className].join(' ')}
  >
    {
      props.infinite ?
        <InfiniteScroll
          dataLength={props.elements.length}
          next={props.fetchElements ?? (() => null)}
          hasMore={!!props.hasMore}
          loader={<FancyProgress color="primary" size={50} />}
        >
          {wrapperProps.children}
        </InfiniteScroll> : wrapperProps.children
    }
  </List>

  const ListContent = <React.Fragment>
    {
      props.elements.length > 0 ? props.elements.map((element: T, index) => <React.Fragment key={getId(element)}>
        {
          props.dragDrop ?
            <Draggable draggableId={getId(element).toString()} index={index}>
              {(provided, snapshot) => {
                const hasSecondaryAction = !!props.actionButtons || (!props.expand && !!props.recursive);
                const draggableProps = {
                  ...provided.draggableProps,
                  ...provided.dragHandleProps,
                  style: {
                    ...provided.draggableProps.style,
                    ...(snapshot.isDragging && { background: 'rgb(235,235,235)' })
                  }
                };
                return (
                  <RootRef rootRef={provided.innerRef}>
                    <AccordionWrapper
                      element={element}
                      AccordionProps={draggableProps}
                    >
                      <FancyListItem
                        element={element}
                        level={1}
                        ListItemProps={!props.expand ?
                          (hasSecondaryAction ? { ContainerProps: draggableProps } : draggableProps) : undefined}
                      />
                      {
                        !props.expand && props.divider && (index !== props.elements.length - 1) &&
                        <Divider component="li" aria-label={`listitem ${index + 1}`} />
                      }
                    </AccordionWrapper>
                  </RootRef>
                )
              }}
            </Draggable> : <AccordionWrapper element={element}>
              <FancyListItem element={element} level={1} />
              {
                !props.expand && props.divider && (index !== props.elements.length - 1) &&
                <Divider component="li" aria-label={`listitem ${index + 1}`} />
              }
            </AccordionWrapper>
        }
      </React.Fragment>
      ) : <Typography variant="h6">{props.emptyMessage || 'No se encontraron resultados'}</Typography>
    }
  </React.Fragment >;

  return <React.Fragment>
    {
      props.dragDrop ? <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {provided => (
            <RootRef rootRef={provided.innerRef}>
              <InfiniteWrapper>
                {ListContent}
                {provided.placeholder}
              </InfiniteWrapper>
            </RootRef>
          )}
        </Droppable>
      </DragDropContext> : <InfiniteWrapper>{ListContent}</InfiniteWrapper>
    }
  </React.Fragment >;
}
