import React, { useEffect, useState } from 'react';
// Material UI
import { makeStyles, createStyles } from '@material-ui/core/styles';
// import Grid from '@material-ui/core/Grid';
// import Typography from '@material-ui/core/Typography';
import DialogContent from '@material-ui/core/DialogContent';
import Fab from '@material-ui/core/Fab';
// import SvgIcon from '@material-ui/core/SvgIcon';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
// import DeleteIcon from '@material-ui/icons/Delete';
// Services
// import { iconAccessibilityProps } from '../../../services/test';
// Types
import ListComponentProps from '../../../types/propTypes/ListComponent';
// Redux
import { useAppDispatch, useAppSelector } from '../../../consts/ReduxHooks';
import { showDialogEdit, dismissDialogEdit, dismissDialog, showDialog } from '../../../store/reducers/dialogReducer';
// App
import { FancyModal, FancyProgress } from '../../common/FancyComponents';
import FancyList, { ActionButton } from '../../common/FancyList';
import { ORGANIZATION_ID } from '../../../consts/App';
import { CLIENT_GROUP } from '../../../consts/Groups';
// import { ReactComponent as SuperuserIcon } from '../../../assets/icons/superuser.svg';
// import { ReactComponent as UserIcon } from '../../../assets/icons/client.svg';
// Other
import axios, { CancelTokenSource } from 'axios';
import { getBusinesses } from '../../../services/API/BusinessServices';
import { Business, Customer } from '../../../types/Business';
import BussinesFormComponent from './BusinessFormComponent';

const useStyles = makeStyles(theme =>
  createStyles({
    button: {
      position: 'fixed',
      bottom: '3%',
      right: '3%'
    },
  })
);

const source: CancelTokenSource[] = new Array(2).fill(axios.CancelToken.source());

export default function BusinessListComponent(props: ListComponentProps) {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const dialog = useAppSelector(state => state.dialog);
  const [business, setBusiness] = useState<Business[]>([]);
  const [selectedBusiness, setSelectedBusiness] = useState<Business | null>(null);
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(false);
  const [loading, setLoading] = useState(false);
  // const [busy, setBusy] = useState(false);
  const limit = 30;
  const actionButtons: ActionButton<Business>[] = [
    {
      text: 'Editar',
      icon: <EditIcon />,
      onClick: (event, business) => handleOptions('edit', business, event),
      show: !props.hideEdit,
      // disabled: busy,
    },
    // {
    //   text: 'Eliminar',
    //   icon: <DeleteIcon />,
    //   onClick: (event, client) => handleOptions('delete', client, event),
    //   show: !props.hideDelete,
    //   disabled: busy,
    // },
  ];

  async function fetchBusiness(reset: boolean = false) {
    if (!props.hideList) {
      if (reset) {
        setLoading(true);
      }
      const newPage = reset ? 0 : page;
      const params = {
        limit: limit,
        offset: newPage * limit,
        group: CLIENT_GROUP,
        organization: ORGANIZATION_ID,
      }
      source[0] = axios.CancelToken.source();
      getBusinesses(source[0], params)
        .then(response => {
          setBusiness(response.results);
          // setBusiness(prev => reset ? response.results : prev.concat(response.results));
          setPage(prev => reset ? 1 : prev + 1);
          // setHasMore(!!response.next);
          setLoading(false);
        })
        .catch(error => {
          if (!error.isCanceled) {
            setBusiness([]);
            setPage(0);
            setHasMore(false);
            setLoading(false);
          }
        });
    }
  };

  useEffect(() => {
    source[1] = axios.CancelToken.source();
    fetchBusiness(true);
    return () => {
      source.forEach(s => s.cancel());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClickOpen = () => {
    dispatch(showDialog());
  };

  const handleClose = () => {
    dispatch(dismissDialog());
    dispatch(dismissDialogEdit());
  };

  const handleSubmit = () => {
    fetchBusiness(true);
    handleClose();
  };

  const handleOptions = (option: 'edit' | 'delete', business: Business, event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation();
    switch (option) {
      case 'edit':
        setSelectedBusiness(business)
        dispatch(showDialogEdit());
        break;
      // case 'delete':
      //   setBusy(true);
      //   deleteUser(client.userId, source[1])
      //     .then(() => {
      //       fetchClients(true);
      //       setBusy(false);
      //     })
      //     .catch(error => {
      //       if (!error.isCanceled) {
      //         setBusy(false);
      //       }
      //     })
      //   break;
    }
  };

  return (
    <React.Fragment>
      {
        !props.hideList && <React.Fragment>
          {
            !loading &&
            <FancyList<Business>
              infinite
              divider
              elements={business}
              hasMore={hasMore}
              fetchElements={fetchBusiness}
              idKey="cif"
              ListProps={{ "aria-label": "Empresas" }}
              ListItemTextProps={business => ({
                primary: `${business.name} - ${business.cif}`,
                secondary: `${business.customer?.slice(0, 3)?.map((customer: Customer)=>(` ${customer.email}`))}`
              })}
              actionButtons={actionButtons}
            />
          }
          {loading && <FancyProgress aria-label="Lista de empresas" color="primary" size={100} />}
        </React.Fragment>
      }
      {
        !props.hideCreate && <Fab
          color="primary"
          aria-label="Agregar"
          className={classes.button}
          onClick={handleClickOpen}
        >
          <AddIcon />
        </Fab>
      }
      <FancyModal
        open={dialog.open || dialog.openEdit}
        onClose={handleClose}
        aria-label="Formulario de empresa"
      >
        <DialogContent>
          <BussinesFormComponent
            onSubmit={handleSubmit}
            data={dialog.openEdit ? selectedBusiness : null}
          />
        </DialogContent>
      </FancyModal>
    </React.Fragment>
  );
}