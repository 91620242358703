
export const POWER_BY_DATA: { [key: string]: { name: string, useFilters: boolean } } = {
  geofolia: {
    name: 'geofolia',
    useFilters: true,
  },
  alfalite: {
    name: '2',
    useFilters: false,
  },
  nickel: {
    name: 'nickel',
    useFilters: true,
  },
  aggraria: {
    name: 'aggraria',
    useFilters: true,
  }
}
