import React from 'react';
// App
import DashboardComponent from '../../layouts/DashboardComponent';
import ClientListComponent from '../../views/Clients/ClientsListComponent';
import MODULES from '../../../consts/Modules';
import PERMISSIONS from '../../../consts/Permissions';
// Services
import hasModule from '../../../services/hasModule';
// Other
import { Switch, Route, Redirect, RouteComponentProps } from 'react-router-dom';

export default function ClientsRouting(props: RouteComponentProps) {
  return (
    <DashboardComponent
      title="Clientes"
      children={<Switch>
        <Route exact path={props.match.path + '/list'} render={props => <ClientListComponent
          hideList={!hasModule(MODULES.clients, PERMISSIONS.list)}
          hideCreate={!hasModule(MODULES.clients, PERMISSIONS.create)}
          hideEdit={!hasModule(MODULES.clients, PERMISSIONS.update)}
        />} />

        <Redirect to={props.match.path + '/list'} />
      </Switch>}
    />
  );
}
