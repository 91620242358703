import { DateRange } from "@material-ui/pickers/DateRangePicker/RangeTypes";
import moment, { Moment } from "moment";

export type FilterType = (
  null
  | UserFilters
  | OrganizationFilters
  | PermissionFilters
  | ModuleFilters
  | TaskFilters
  | StatisticsFilters
  | GroupFilters
);

export class UserFilters {
  id: 'user' = 'user';
  search?: string = '';
  organization?: null | number = null;
  group?: null | number = null;
}

export class OrganizationFilters {
  id: 'organization' = 'organization';
  search?: string = '';
}

export class PermissionFilters {
  id: 'permission' = 'permission';
  search?: string = '';
}

export class ModuleFilters {
  id: 'module' = 'module';
  search?: string = '';
  organization?: null | number = null;
  permission?: null | number = null;
}

export class GroupFilters {
  id: 'group' = 'group';
  // search?: string = '';
  organization?: null | number = null;
  // permission?: null | number = null;
  // module?: null | number = null;
}

export class TaskFilters {
  id: 'task' = 'task';
  date?: DateRange<Moment> = [moment.utc().startOf('week'), moment.utc().endOf('week')];
  status?: string = '';
  client?: null | number = null;
}

export class StatisticsFilters {
  id: 'statistics' = 'statistics';
  search?: string = '';
  date?: Moment | null = moment.utc();
  user?: null | number = null;
  client?: null | number = null;
}
