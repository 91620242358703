import store from "../store";
import { MenuItem } from "../types/Menu";

const { getState } = store;

const filterByModules = (items: MenuItem[]) => {
  const state = getState();
  const elements = items.filter(item => {
    for (const module of item.modules) {
      if (state.auth.user.roles.find(group => !!group.modules.find(mod => mod.name === module))) {
        return true;
      }
    }
    return false;
  });
  elements.forEach((el, index) => {
    if (el.expand) {
      elements[index] = {
        ...el,
        children: filterByModules(el.children),
      };
    }
  })
  return elements;
};

export default filterByModules;