import { createSlice, PayloadAction } from '@reduxjs/toolkit';
// types
import { DiagramsReducer } from '../../types/redux/diagramsTypes';
import { DiagramsBpmn } from '../../types/BPMN';

// Can´t instantiate new classes with new Class() as initalState,
// use spread operator to convert to object instead or don´t use Classes
const initialState: DiagramsReducer = { ...new DiagramsReducer() };

export const diagramSlice = createSlice({
  name: 'diagram',
  initialState,
  reducers: {
    showListFileBpmn: (state, action: PayloadAction<DiagramsBpmn[]>) => {
      state.fileList = action.payload;
    },
    showDiagramBpmn: (state, action: PayloadAction<DiagramsBpmn>) => {
      state.fileView = action.payload;
    },
    selectDiagramBpmn: (state, action: PayloadAction<DiagramsBpmn>) => {
      state.fileSelected = action.payload;
    },
    handlerListDiagramBpmn: (state, action: PayloadAction<boolean>) => {
      state.handlerList = action.payload;
    },
  }
});

export const { showListFileBpmn, showDiagramBpmn, selectDiagramBpmn, handlerListDiagramBpmn } = diagramSlice.actions;

export default diagramSlice.reducer;
