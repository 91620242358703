import React, { useEffect, useState } from 'react';
// Material UI
import { makeStyles, Theme, createStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import DialogContent from '@material-ui/core/DialogContent';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
// Services
import { getOrganizations } from '../../../services/API/OrganizationServices';
// Types
import ListComponentProps from '../../../types/propTypes/ListComponent';
import { Organization } from '../../../types/Organization';
// Redux
import { useAppDispatch, useAppSelector } from '../../../consts/ReduxHooks';
import { OrganizationFilters } from '../../../types/redux/filtersTypes';
import { dismissDialog, showDialog } from '../../../store/reducers/dialogReducer';
// App
import { FancyModal, FancyProgress } from '../../common/FancyComponents';
import FancyList from '../../common/FancyList';
// import FancyFilter from '../../common/FancyFilter';
import OrganizationFormComponent from './OrganizationFormComponent';
// Other
import axios, { CancelTokenSource } from 'axios';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      position: 'fixed',
      bottom: '3%',
      right: '3%'
    },
  })
);

const source: CancelTokenSource[] = new Array(2).fill(axios.CancelToken.source());

export default function OrganizationListComponent(props: ListComponentProps) {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const dialog = useAppSelector(state => state.dialog);
  const filters = useAppSelector(state => state.filters);
  const theme = useTheme();
  const matchesDesktop = useMediaQuery(theme.breakpoints.up('sm'));
  const [organizations, setOrganizations] = useState<Organization[]>([]);
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(false);
  const [loading, setLoading] = useState(false);
  const limit = 30;

  async function fetchOrganizations(reset: boolean = false) {
    if (!props.hideList && filters && filters.id === new OrganizationFilters().id) {
      if (reset) {
        setLoading(true);
      }
      const newPage = reset ? 0 : page;
      const params = {
        ...filters,
        limit: limit,
        offset: newPage * limit,
      }
      source[0] = axios.CancelToken.source();
      getOrganizations(source[0], params)
        .then(response => {
          // setOrganizations(prev => reset ? response.results : prev.concat(response.results));
          setOrganizations(response);
          setPage(prev => reset ? 1 : prev + 1);
          // setHasMore(!!response.next);
          setLoading(false);
        })
        .catch(error => {
          if (!error.isCanceled) {
            setOrganizations([]);
            setPage(0);
            setHasMore(false);
            setLoading(false);
          }
        });
    }
  };

  useEffect(() => {
    source[1] = axios.CancelToken.source();
    return () => {
      source.forEach(s => s.cancel());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchOrganizations(true);
    return () => {
      source[0].cancel();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const handleClickOpen = () => {
    dispatch(showDialog());
  };

  const handleClose = () => {
    dispatch(dismissDialog());
  };

  const handleSubmit = () => {
    fetchOrganizations(true);
    handleClose();
  };

  return (
    <React.Fragment>
      {
        !props.hideList && <React.Fragment>
          {/* {
            filters && <FancyFilter
              defaultValues={new OrganizationFilters()}
              formProps={{ "aria-label": 'Filtros lista de organizaciones' }}
            />
          } */}
          {
            !loading && <FancyList<Organization>
              infinite
              divider
              elements={organizations}
              hasMore={hasMore}
              fetchElements={fetchOrganizations}
              ListProps={{ "aria-label": "Organizaciones" }}
              ListItemTextProps={organization => ({
                primary: organization.name,
              })}
            />
          }
          {loading && <FancyProgress aria-label="Lista de organizaciones" color="primary" size={100} />}
        </React.Fragment>
      }
      {
        !props.hideCreate && <Fab
          color="primary"
          aria-label="Agregar"
          className={classes.button}
          onClick={handleClickOpen}
        >
          <AddIcon />
        </Fab>
      }
      <FancyModal
        fullWidth
        open={dialog.open}
        onClose={handleClose}
        fullScreen={!matchesDesktop}
        maxWidth={matchesDesktop ? 'sm' : false}
        aria-label="Formulario de organizacion"
      >
        <DialogContent>
          <OrganizationFormComponent onSubmit={handleSubmit} />
        </DialogContent>
      </FancyModal>
    </React.Fragment>
  );
}
