import axios, { /*CancelTokenSource,*/ AxiosRequestConfig, CancelTokenSource } from 'axios';
// Redux
import store from '../../store';
import { handlerListDiagramBpmn, showListFileBpmn } from '../../store/reducers/diagramsReducer';
// Types
import {
  // AuthenticationResponse,
  // RandomAuthenticationResponse,
  FilesResponse,
  RandomFilesResponse,
  DiagramsBpmn,
  RandomDiagramsBpmn,
  DiagramsBpmnParams,
} from '../../types/BPMN';
// App
import { MAKE_API_REQUEST, DRIVE_SERVICE } from '../../consts/App';
// import { showSuccessSnackbar } from '../../store/reducers/alertReducer';
import { googleAuthentication } from './GoogleAccountService'

const { dispatch } = store;

const defaultConfig: AxiosRequestConfig = {
  baseURL: DRIVE_SERVICE,
  headers: { 'NO-HEADERS': true },
}

export async function driveFiles(params: DiagramsBpmnParams, source: CancelTokenSource) {
  if (MAKE_API_REQUEST) {
    const config: AxiosRequestConfig = {
      ...defaultConfig,
      cancelToken: source.token,
      params: params,
    }
    return axios.get<FilesResponse>('/files', config)
      .then(response => {
        dispatch(showListFileBpmn(response.data.files));
        dispatch(handlerListDiagramBpmn(false));
        return response.data;
      })
      .catch(error => {
        if (!error.isCanceled) {
          googleAuthentication(source);
        }
        return Promise.reject(error);
      });
  } else {
    const response = new RandomFilesResponse() as FilesResponse;
    dispatch(showListFileBpmn(response.files));
    dispatch(handlerListDiagramBpmn(false));
    return Promise.resolve(response);
  }
}

export async function downloadDriveFiles(params: DiagramsBpmnParams, source: CancelTokenSource) {
  if (MAKE_API_REQUEST) {
    const config: AxiosRequestConfig = {
      ...defaultConfig,
      cancelToken: source.token,
      params: params,
    }
    return axios.get<DiagramsBpmn>('/download', config)
      .then(response => response.data);
  } else {
    return Promise.resolve(new RandomDiagramsBpmn());
  }
};
