import React, { useState, useEffect } from "react";
// Material UI
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
// Forms
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers";
// App
import { FancyTextInput, FancyButton } from "../../common/FancyComponents";
// Types
import { GeneralFormProps } from "../../../types/propTypes/misc";
// Others
import axios, { CancelTokenSource } from "axios";
import VirtualizedAutocompleteComponent from "../../common/VirtualizedAutocompleteComponent";
// import { CircularProgress } from "@material-ui/core";
import { Category, Crop, Invoice, Split, SplitForm } from "../../../types/Invoice";
import { getCategory, getCrop } from "../../../services/API/InvoiceServices";
import { CircularProgress } from "@material-ui/core";

const schema = yup.object().shape({
  crop: yup.mixed().required(),
  code: yup.mixed().required(),
  category: yup.mixed().required(),
  base: yup.number().required(),
});

let source: CancelTokenSource = axios.CancelToken.source();

export default function SplitFormComponent(
  props: GeneralFormProps<Split> & { invoice: Invoice }
) {
  const methods = useForm<SplitForm>({
    resolver: yupResolver(schema),
    defaultValues: new SplitForm(),
  });

  const { handleSubmit, control, errors, reset, setValue } = methods;
  const [busy1, setBusy1] = useState(false);
  const [loadingCrop, setLoadingCrop] = useState(false);
  const [loadingCategory, setLoadingCategory] = useState(false);
  const [crops, setCrops] = useState<Crop[]>([]);
  const [categorys, setCategorys] = useState<Category[]>([]);
  const isEdit = !!props.data;

  useEffect(() => {
    source = axios.CancelToken.source();
    fetchCrop();
    fetchCategory();
    if (isEdit && props.data) {
      reset({
        crop: { description: props.data.crop, },
        code: { code: props.data.code },
        category: { category: props.data.category },
        base: props.data.base,
      });
    }
    return () => {
      source.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = (data: SplitForm) => {
    setBusy1(false);
    if (props.data) {
      const newData: Split = {
        id: props.data.id,
        crop: data.crop?.description || '',
        code: data.code?.code || 0,
        category: data.category?.category || '',
        base: data.base
      }
      console.log(data);
      if (props.onSubmit) {
        props.onSubmit(newData);
      }
    }
  };

  async function fetchCrop() {
    setLoadingCrop(true);
    getCrop(source)
      .then((response) => {
        setCrops(response);
        setLoadingCrop(false);
      })
      .catch((error) => {
        if (!error.isCanceled) {
          setLoadingCrop(false);
        }
      });
  }

  async function fetchCategory() {
    setLoadingCategory(true);
    const params = {
      cif: props.invoice.societyCif,
    }
    getCategory(source, params)
      .then((response) => {
        setCategorys(response);
        setLoadingCategory(false);
      })
      .catch((error) => {
        if (!error.isCanceled) {
          setLoadingCategory(false);
        }
      });
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} aria-label="Visado">
      <Grid container direction="row" justify="center" spacing={1}>
        <Grid item xs={12}>
          <Typography variant="h6" align="center">
            Información de Cultivo
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Controller
            render={(renderProps) => (
              <VirtualizedAutocompleteComponent<Crop>
                {...renderProps}
                options={crops}
                autoSelect={true}
                onChange={(event, newValue) => {
                  renderProps.onChange(newValue);
                  // setSelectedModules([]);
                  if (newValue) {
                    // fetchModules(newValue);
                  }
                }}
                getOptionSelected={(option, selected) =>
                  option.description === selected.description
                }
                getOptionLabel={(option) => option.description}
                renderOption={(option) => (
                  <Typography noWrap>{option.description}</Typography>
                )}
                loading={loadingCrop}
                aria-label="Tipo de cultivo"
                renderInput={(params) => (
                  <FancyTextInput
                    {...params}
                    label="Tipo de cultivo"
                    id="invoice_Tipo de cultivo"
                    error={!!errors.crop}
                    helperText={
                      errors.crop && errors.crop.message
                    }
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {loadingCrop ? (
                            <CircularProgress
                              aria-label="Tipo de cultivo"
                              color="inherit"
                              size={20}
                            />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
            )}
            control={control}
            name="crop"
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            render={(renderProps) => (
              <VirtualizedAutocompleteComponent<Category>
                {...renderProps}
                options={categorys}
                onChange={(event, newValue) => {
                  renderProps.onChange(newValue);
                  if (newValue?.code) {
                    setValue('code', newValue);
                  }
                }}
                getOptionSelected={(option, selected) =>
                  option.id === selected.id
                }
                getOptionLabel={(option) => option.category}
                renderOption={(option) => (
                  <Typography noWrap>{option.category}</Typography>
                )}
                loading={loadingCategory}
                aria-label="Conceptos de Visado"
                renderInput={(params) => (
                  <FancyTextInput
                    {...params}
                    label="Concepto de Visado"
                    id="invoice_Conceptos"
                    error={!!errors.category}
                    helperText={
                      errors.category && errors.category.message
                    }
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {loadingCategory ? (
                            <CircularProgress
                              aria-label="visaConcept"
                              color="inherit"
                              size={20}
                            />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
            )}
            control={control}
            name="category"
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            render={(renderProps) => (
              <VirtualizedAutocompleteComponent<Category>
                {...renderProps}
                options={categorys}
                onChange={(event, newValue) => {
                  renderProps.onChange(newValue);
                  if (newValue?.category) {
                    setValue('category', newValue);
                  }
                }}
                getOptionSelected={(option, selected) =>
                  option.id === selected.id
                }
                getOptionLabel={(option) => option.code?.toString()}
                renderOption={(option) => (
                  <Typography noWrap>{option.code}</Typography>
                )}
                loading={loadingCategory}
                aria-label="Código de Visado"
                renderInput={(params) => (
                  <FancyTextInput
                    {...params}
                    label="Código de Visado"
                    id="invoice_code"
                    error={!!errors.code}
                    helperText={
                      errors.code && errors.code.message
                    }
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {loadingCategory ? (
                            <CircularProgress
                              aria-label="Código de Visado"
                              color="inherit"
                              size={20}
                            />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
            )}
            control={control}
            name="code"
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            render={(props) => (
              <FancyTextInput
                {...props}
                error={!!errors.base}
                helperText={errors.base && errors.base.message}
                label="Base Imponible"
                id="invoice_taxBase"
                type="number"
              />
            )}
            control={control}
            name="base"
          />
        </Grid>
        <Grid item xs={12} container direction="row" justify="center">
          <FancyButton
            variant="contained"
            color="primary"
            type="submit"
            disabled={busy1}
            loading={busy1}
          >
            {isEdit ? "Guardar" : "Crear"}
          </FancyButton>
        </Grid>
      </Grid>
    </form>
  );
}
