import React, { useEffect, useState } from 'react';
// Material UI
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import DialogContent from '@material-ui/core/DialogContent';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
// Services
import { getGroups } from '../../../services/API/GroupServices';
import { getOrganizations } from '../../../services/API/OrganizationServices';
// import { getModules } from '../../../services/API/ModuleServices';
// import { getPermissions } from '../../../services/API/PermissionServices';
// Types
import ListComponentProps from '../../../types/propTypes/ListComponent';
import { Group } from '../../../types/Group';
// import { Permission } from '../../../types/Permission';
// import { Module } from '../../../types/Module';
import { Organization } from '../../../types/Organization';
// Redux
import { useAppSelector, useAppDispatch } from '../../../consts/ReduxHooks';
import { GroupFilters } from '../../../types/redux/filtersTypes';
import { dismissDialog, dismissDialogEdit, showDialog, showDialogEdit } from '../../../store/reducers/dialogReducer';
// App
import { FancyModal, FancyProgress } from '../../common/FancyComponents';
import FancyList, { ActionButton } from '../../common/FancyList';
import FancyFilter, { FilterElement } from '../../common/FancyFilter';
import GroupFormComponent from './GroupFormComponent';
// Other
import axios, { CancelTokenSource } from 'axios';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      position: 'fixed',
      bottom: '3%',
      right: '3%'
    },
  })
);

const source: CancelTokenSource[] = new Array(2).fill(axios.CancelToken.source());

export default function GroupListComponent(props: ListComponentProps) {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const dialog = useAppSelector(state => state.dialog);
  const filters = useAppSelector(state => state.filters);
  const [groups, setGroups] = useState<Group[]>([]);
  const [selectedGroup, setSelectedGroup] = useState<Group | null>(null);
  // const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(false);
  const [loading, setLoading] = useState(true);
  const [organizations, setOrganizations] = useState<Organization[]>([]);
  const [loadingOrganizations, setLoadingOrganizations] = useState(false);
  // const [permissions, setPermissions] = useState<Permission[]>([]);
  // const [loadingPermissions, setLoadingPermissions] = useState(false);
  // const [modules, setModules] = useState<Module[]>([]);
  // const [loadingModules, setLoadingModules] = useState(false);
  // const limit = 30;
  const actionButtons: ActionButton<Group>[] = [
    {
      text: 'Editar',
      icon: <EditIcon />,
      onClick: (event, module) => handleOptions('edit', module, event),
      show: !props.hideEdit,
    },
  ];
  const filterElements: FilterElement[] = [
    {
      type: 'Autocomplete',
      key: 'organization',
      name: 'Organización',
      autocompleteOptions: organizations.map(el => ({ value: el.id, name: el.name })),
      loading: loadingOrganizations,
    },
    // {
    //   type: 'Autocomplete',
    //   key: 'module',
    //   name: 'Módulo',
    //   autocompleteOptions: modules.map(el => ({ value: el.id, name: el.name })),
    //   loading: loadingModules,
    // },
    // {
    //   type: 'Autocomplete',
    //   key: 'permission',
    //   name: 'Permiso',
    //   autocompleteOptions: permissions.map(el => ({ value: el.id, name: el.name })),
    //   loading: loadingPermissions,
    // },
  ];

  async function fetchGroups(reset: boolean = false) {
    if (!props.hideList && organizations.length > 0 && filters && filters.id === new GroupFilters().id) {
      if (reset) {
        setLoading(true);
      }
      // const newPage = reset ? 0 : page;
      const params = {
        ...filters,
        organization: filters.organization || organizations[0].id,
        // limit: limit,
        // offset: newPage * limit,
      }
      source[0] = axios.CancelToken.source();
      getGroups(source[0], params)
        .then(response => {
          setGroups(response);
          // setGroups(prev => reset ? response.results : prev.concat(response.results));
          // setPage(prev => reset ? 1 : prev + 1);
          // setHasMore(!!response.next);
          setLoading(false);
        })
        .catch(error => {
          if (!error.isCanceled) {
            setGroups([]);
            // setPage(0);
            setHasMore(false);
            setLoading(false);
          }
        });
    }
  };

  async function fetchOrganizations() {
    setLoadingOrganizations(true);
    const params = {
      paginate: false as false,
    }
    getOrganizations(source[1], params)
      .then(response => {
        setOrganizations(response);
        setLoadingOrganizations(false);
      })
      .catch((error) => {
        if (!error.isCanceled) {
          setLoadingOrganizations(false);
        }
      });
  };

  // async function fetchPermissions() {
  //   setLoadingPermissions(true);
  //   const params = {
  //     paginate: false as false,
  //   }
  //   getPermissions(source[1], params)
  //     .then(response => {
  //       setPermissions(response);
  //       setLoadingPermissions(false);
  //     })
  //     .catch((error) => {
  //       if (!error.isCanceled) {
  //         setLoadingPermissions(false);
  //       }
  //     });
  // };

  // async function fetchModules() {
  //   setLoadingModules(true);
  //   const params = {
  //     paginate: false as false,
  //   }
  //   getModules(source[1], params)
  //     .then(response => {
  //       setModules(response);
  //       setLoadingModules(false);
  //     })
  //     .catch((error) => {
  //       if (!error.isCanceled) {
  //         setLoadingModules(false);
  //       }
  //     });
  // };

  useEffect(() => {
    source[1] = axios.CancelToken.source();
    if (!props.hideList) {
      fetchOrganizations();
      // fetchPermissions();
      // fetchModules();
    }
    return () => {
      source.forEach(s => s.cancel());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchGroups(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizations]);

  useEffect(() => {
    fetchGroups(true);
    return () => {
      source[0].cancel();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const handleClickOpen = () => {
    dispatch(showDialog());
  };

  const handleClose = () => {
    dispatch(dismissDialog());
    dispatch(dismissDialogEdit());
  };

  const handleSubmit = () => {
    fetchGroups(true);
    handleClose();
  };

  const handleOptions = (option: 'edit', group: Group, event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation();
    switch (option) {
      case 'edit':
        setSelectedGroup(group);
        dispatch(showDialogEdit());
        break;
    }
  };

  const ExpandContent = ({ group }: { group: Group }) => (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Typography variant="subtitle2"><strong>Módulos</strong></Typography>
        <Typography variant="body2">
          {group.modules.map(mod => mod.name).join(', ')}
        </Typography>
      </Grid>
    </Grid>
  );

  return (
    <React.Fragment>
      {
        !props.hideList && <React.Fragment>
          {
            filters && <FancyFilter
              noSearch
              submitFilterButtonProps={{ variant: 'outlined' }}
              defaultValues={new GroupFilters()}
              elements={filterElements}
              formProps={{ "aria-label": 'Filtros lista de grupos' }}
            />
          }
          {
            !loading && <FancyList<Group>
              infinite
              expand
              elements={groups}
              idKey="group"
              hasMore={hasMore}
              fetchElements={fetchGroups}
              ListProps={{ "aria-label": "Grupos" }}
              ListItemTextProps={group => ({
                primary: group.group,
                secondary: group.organization.name,
              })}
              actionButtons={actionButtons}
              expandContent={group => <ExpandContent group={group} />}
            />
          }
          {loading && <FancyProgress aria-label="Lista de grupos" color="primary" size={100} />}
        </React.Fragment>
      }
      {
        !props.hideCreate && <Fab
          color="primary"
          aria-label="Agregar"
          className={classes.button}
          onClick={handleClickOpen}
        >
          <AddIcon />
        </Fab>
      }
      <FancyModal
        open={dialog.open || dialog.openEdit}
        onClose={handleClose}
        aria-label="Formulario de grupo"
      >
        <DialogContent>
          <GroupFormComponent
            onSubmit={handleSubmit}
            data={dialog.openEdit ? selectedGroup : null}
          />
        </DialogContent>
      </FancyModal>
    </React.Fragment>
  );
}
