import React from 'react';
// Material UI
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
// App
import Logo from '../../../assets/images/logo_home.svg';

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      height: 'calc(100vh - 48px)',
    },
    logo: {
      height: 60,
      width: 'auto',
    },
  })
)

export default function HomeComponent() {
  const styles = useStyles();

  return <Box p={1}>
    <Grid container direction="column" justify="center" alignItems="center" spacing={2} className={styles.root}>
      <Grid item>
        <Typography variant="h1">Bienvenido a</Typography>
      </Grid>
      <Grid item>
        <img src={Logo} alt="logo" className={styles.logo} />
      </Grid>
    </Grid>
  </Box>
}