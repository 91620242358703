import store from "../store";

const { getState } = store;

const hasModule = (module: string, permission?: number) => {
  const state = getState();
  return !!state.auth.user.roles.find(
    role => {
      const userModule = role.modules.find(mod => mod.name === module);
      if (userModule && permission) {
        return !!userModule.permissions.find(p => p.id === permission);
      }
      return !!userModule;
    }
  );
}

export default hasModule;