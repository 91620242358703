import React, { useState } from 'react'
// Material ui
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
// App
import { FancyButton, PasswordTextInput } from '../../common/FancyComponents';
import Logo from '../../../assets/images/logo.svg';
// Form
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers';
import * as yup from "yup";
// Services
import { newPassword } from '../../../services/API/AuthServices';
// Types
import { RestoreBody } from '../../../types/Auth';
// Others
import { Link, useLocation } from 'react-router-dom';
import axios, { CancelTokenSource } from 'axios';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    logo: {
      height: 150,
      width: 'auto'
    },
    span: {
      textAlign: 'center',
      textDecoration: 'none',
      color: theme.palette.primary.main,
      '&:hover': {
        color: theme.palette.primary.dark,
      }
    },
    paper: {
      width: '350px',
      height: '100%',
      padding: theme.spacing(2, 3),
      [theme.breakpoints.down('xs')]: {
        width: 'auto',
        padding: theme.spacing(1, 2),
      }
    }
  }),
);

const schema = yup.object().shape({
  password: yup.string().required().min(6),
  passwordConfirm: yup.string().oneOf([yup.ref('password')], 'La confirmación de la contraseña no coincide'),
});

let source: CancelTokenSource = axios.CancelToken.source();

export default function RestorePasswordForm() {
  const location = useLocation();
  const styles = useStyles();
  const methods = useForm<RestoreBody>({
    resolver: yupResolver(schema),
    defaultValues: new RestoreBody(),
  });
  const { handleSubmit, control, errors } = methods;
  const [busy, setBusy] = useState(false);

  React.useEffect(() => {
    source = axios.CancelToken.source();
    return () => {
      source.cancel();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = (data: RestoreBody) => {
    const queryparams = new URLSearchParams(location.search);
    if (queryparams.has('pin')) {
      data.code = queryparams.get('pin') || '';
    }
    if (queryparams.has('email')) {
      data.email = queryparams.get('email') || '';
    }
    setBusy(true);
    newPassword(data, source)
      .catch(error => {
        if (!error.isCanceled) {
          setBusy(false);
        }
      });
  }

  return (
    <Paper className={styles.paper}>
      <form onSubmit={handleSubmit(onSubmit)} aria-label="Cambio de contraseña">
        <Grid container direction="row" spacing={1}>
          <Grid item xs={12} container justify="center">
            <img src={Logo} alt="logo" className={styles.logo} />
          </Grid>
          <Grid item xs={12}>
            <Typography align="center" variant="h6">Cambiar Contraseña</Typography>
          </Grid>
          <Grid item xs={12}>
            <Controller
              as={PasswordTextInput}
              control={control}
              error={!!errors.password}
              helperText={errors.password && errors.password.message}
              name="password"
              label="Nueva Contraseña"
              id="restore_new_password"
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              as={PasswordTextInput}
              control={control}
              error={!!errors.passwordConfirm}
              helperText={errors.passwordConfirm && errors.passwordConfirm.message}
              name="passwordConfirm"
              label="Confirmación de Contraseña"
              id="restore_confirm_password"
            />
          </Grid>
          <Grid item xs={12} container direction="row" justify="center">
            <FancyButton
              variant="contained"
              color='primary'
              type="submit"
              disabled={busy}
              loading={busy}
            >
              Recuperar contraseña
            </FancyButton>
            <Grid item xs={12} container direction="row" justify="center">
              <Typography component={Link} to='/' className={styles.span}>
                Volver al inicio de sesión
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Paper>
  )
}
