import React, { useState, useEffect } from 'react';
// Material UI
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
// Forms
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers';
// App
import { FancyTextInput, FancyButton } from '../../common/FancyComponents';
import VirtualizedAutocompleteComponent from '../../common/VirtualizedAutocompleteComponent';
// Services
// Types
import { RequiredGeneralFormProps } from '../../../types/propTypes/misc';
import { Account, AccountBody, AccountForm, AccountFormDefault, Business } from '../../../types/Banking';
// Others
import axios, { CancelTokenSource } from 'axios';
import { getBusinesses, updateAccount } from '../../../services/API/BankingServices';
import { useAppSelector } from '../../../consts/ReduxHooks';

const schema = yup.object().shape({
  business: yup.array().required(),
});

let source: CancelTokenSource = axios.CancelToken.source();

export default function BankAccountFormComponent(props: RequiredGeneralFormProps<Account>) {
  const user = useAppSelector(state => state.auth.user);
  const methods = useForm<AccountForm>({
    resolver: yupResolver(schema),
    defaultValues: new AccountFormDefault(),
  });
  const { handleSubmit, control, errors, reset } = methods;
  const [busy, setBusy] = useState(false);
  const [businesses, setBusinesses] = useState<Business[]>([]);
  const [loadingBusiness, setLoadingBusiness] = useState(false);

  async function fetchBusiness() {
    setLoadingBusiness(true);
    const params = {
      paginate: false as false,
      customer: user.idx,
    }
    getBusinesses(source, params)
      .then(response => {
        setBusinesses(response);
        setLoadingBusiness(false);
      })
      .catch(error => {
        if (!error.isCanceled) {
          setLoadingBusiness(false);
        }
      });
  };

  useEffect(() => {
    source = axios.CancelToken.source();
    fetchBusiness();
    reset({
      business: props.data.business,
    });
    return () => {
      source.cancel();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = (data: AccountForm) => {
    setBusy(true);
    const dataBody: AccountBody = {
      business: data.business.map(b => b.id),
    }
    updateAccount(props.data.account_number, dataBody, source)
      .then(response => {
        setBusy(false);
        if (props.onSubmit) {
          props.onSubmit(response);
        }
      })
      .catch(error => {
        if (!error.isCanceled) {
          setBusy(false);
        }
      });
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} aria-label="Cuenta">
      <Grid container direction="row" justify="center" spacing={1}>
        <Grid item xs={12}>
          <Typography variant="h6" align="center">Información de la Cuenta</Typography>
        </Grid>
        <Grid item xs={12}>
          <Controller
            render={props => <VirtualizedAutocompleteComponent<Business, true>
              {...props}
              multiple
              disableCloseOnSelect
              options={businesses}
              onChange={(event, newValue) => props.onChange(newValue)}
              getOptionSelected={(option, selected) => option.id === selected.id}
              getOptionLabel={option => option.name}
              renderOption={option => <Typography noWrap>{option.name}</Typography>}
              loading={loadingBusiness}
              aria-label="Empresas"
              renderInput={params => <FancyTextInput
                {...params}
                label="Empresas"
                id="moduleForm_business"
                error={!!errors.business}
                helperText={errors.business && errors.business.message}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {loadingBusiness ? <CircularProgress aria-label="Empresas" color="inherit" size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
              />}
            />}
            control={control}
            name="business"
          />
        </Grid>
        <Grid item xs={12} container direction="row" justify="center">
          <FancyButton
            variant="contained"
            color='primary'
            type="submit"
            disabled={busy}
            loading={busy}
          >
            Guardar
          </FancyButton>
        </Grid>
      </Grid>
    </form>
  );
}