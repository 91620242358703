import axios, { CancelTokenSource } from 'axios';
// Redux
import store from '../../store';
import { showSnackbar } from '../../store/reducers/alertReducer';
// Types
import { MessageResponse, Params/*, ListResponse*/ } from '../../types/Api';
import { User, UserBody, RandomUser, UserGroupBody } from '../../types/User';
// App
import { MAKE_API_REQUEST, USERMANAGEMENT_SERVICE } from '../../consts/App';
import getListData from '../getListData';

const { dispatch } = store;
const defaultConfig = {
  baseURL: USERMANAGEMENT_SERVICE,
}

// export async function getUsers(source: CancelTokenSource, params?: { paginate?: true } & Params): Promise<ListResponse<User>>;
// export async function getUsers(source: CancelTokenSource, params: { paginate: false } & Params): Promise<User[]>;
// export async function getUsers(source: CancelTokenSource, params: Params): Promise<User[] | ListResponse<User>>;
export async function getUsers(source: CancelTokenSource, params?: Params) {
  if (MAKE_API_REQUEST) {
    return axios.get<User[]>('users/', { ...defaultConfig, params: params, cancelToken: source.token })
      .then(response => response.data);
  } else {
    const count = params?.limit || 10;
    const response = getListData<User>(count, () => new RandomUser(), true);
    // const response = getListData<User>(count, () => new RandomUser(), params?.paginate === false);
    return Promise.resolve(response);
  }
}

export async function registerUser(data: UserBody, source: CancelTokenSource) {
  if (MAKE_API_REQUEST) {
    return axios.post<User>('users/', data, { ...defaultConfig, cancelToken: source.token })
      .then(response => {
        dispatch(showSnackbar({ message: 'Registro exitoso', type: 'success' }));
        return response.data;
      });
  } else {
    dispatch(showSnackbar({ message: 'Registro exitoso', type: 'success' }));
    return Promise.resolve(new RandomUser() as User);
  }
};

export async function updateUser(email: string, data: Partial<UserBody>, source: CancelTokenSource) {
  if (MAKE_API_REQUEST) {
    return axios.put<User>(`users/${email}/`, data, { ...defaultConfig, cancelToken: source.token })
      .then(response => {
        dispatch(showSnackbar({ message: 'Se actualizó la información con éxito', type: 'success' }));
        return response.data;
      });
  } else {
    dispatch(showSnackbar({ message: 'Se actualizó la información con éxito', type: 'success' }));
    return Promise.resolve(new RandomUser() as User);
  }
}

export async function registerUserGroup(data: UserGroupBody, source: CancelTokenSource) {
  if (MAKE_API_REQUEST) {
    const { user, organization, ...others } = data;
    return axios.put<MessageResponse<'groups'>>(`users/${user}/organizations/${organization}/groups`, others, { ...defaultConfig, cancelToken: source.token })
      .then(response => {
        dispatch(showSnackbar({ message: 'Registro exitoso', type: 'success' }));
        return response.data;
      });
  } else {
    dispatch(showSnackbar({ message: 'Registro exitoso', type: 'success' }));
    return Promise.resolve({ user: 'Correcto' });
  }
};
