import React from 'react';
// Redux
import { useAppDispatch } from '../../../consts/ReduxHooks';
import { ModuleFilters } from '../../../types/redux/filtersTypes';
import { setFilters, resetFilters } from '../../../store/reducers/filtersReducer';
// App
import DashboardComponent from '../../layouts/DashboardComponent';
import ModuleListComponent from '../../views/Modules/ModulesListComponent';
import MODULES from '../../../consts/Modules';
import PERMISSIONS from '../../../consts/Permissions';
// Services
import hasModule from '../../../services/hasModule';
// Other
import { Switch, Route, Redirect, RouteComponentProps } from 'react-router-dom';

export default function ModulesRouting(props: RouteComponentProps) {
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    dispatch(setFilters({ ...new ModuleFilters() }));
    return () => {
      dispatch(resetFilters());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DashboardComponent
      title="Módulos"
      children={<Switch>
        <Route exact path={props.match.path + '/list'} render={props => <ModuleListComponent
          hideList={!hasModule(MODULES.modules, PERMISSIONS.list)}
          hideCreate={!hasModule(MODULES.modules, PERMISSIONS.create)}
          hideEdit={!hasModule(MODULES.modules, PERMISSIONS.update)}
        />} />
        <Redirect to={props.match.path + '/list'} />
      </Switch>}
    />
  );
}
