import React from 'react';
// App
import DashboardComponent from '../../layouts/DashboardComponent';
// import OcrRouting from './OcrSection/OcrRouting';
import BankingRouting from './BankingRouting';
import BpmnRouting from './BpmnRouting';
import IframeComponent from '../../common/IframeComponent';
import PowerBIComponent from '../../views/PowerBI/PowerBIComponent';
import InvoiceComponent from '../../views/Invoice/InvoiceComponent';
import MODULES from '../../../consts/Modules';
import { ACCOUNTING_PROCESS, ARYA_CORE, ARYA_PROCESS, ERP_PROCESS, SUPERSET_SERVICE } from '../../../consts/App';
// Services
import hasModule from '../../../services/hasModule';
// Redux
import { useAppSelector } from '../../../consts/ReduxHooks';
// Other
import { Switch, Redirect, RouteComponentProps, Route } from "react-router-dom";

export default function ProcessRouting(props: RouteComponentProps) {
  const user = useAppSelector(state => state.auth.user);
  const token = localStorage.getItem('token');

  // Default redirect
  const redirectTo = () => {
    if (hasModule(MODULES.diagrams_bpmn)) {
      return props.match.path + '/models';
    } else if (hasModule(MODULES.process_ary)) {
      return props.match.path + '/task';
    } else if (hasModule(MODULES.process_erp)) {
      return props.match.path + '/erp';
    } else if (hasModule(MODULES.clients_banking)) {
      return props.match.path + '/banking';
    } else if (hasModule(MODULES.billing_dashboard)) {
      return props.match.path + '/billing';
    } else if (hasModule(MODULES.bam)) {
      return props.match.path + '/bam';
    } else if (hasModule(MODULES.accounting)) {
      return props.match.path + '/accounting';
    }
    return '/';
  }

  return (
    <Switch>
      {/* <Route path={props.match.path + '/ocr'} component={OcrRouting} /> */}
      {
        hasModule(MODULES.diagrams_bpmn) && <Route
          path={props.match.path + '/models'}
          component={BpmnRouting}
        />
      }
      {
        hasModule(MODULES.clients_banking) && <Route
          path={props.match.path + '/banking'}
          component={BankingRouting}
        />
      }
      {
        hasModule(MODULES.process_ary) && <Route
          path={props.match.path + '/task'}
          render={() => <DashboardComponent>
            <IframeComponent
              src={`${ARYA_PROCESS}camunda/app/tasklist/default/?token=${token}&username=${user.idx}&url=${ARYA_CORE}`}
              title="Tareas"
            />
          </DashboardComponent>}
        />
      }
      {
        hasModule(MODULES.process_erp) && <Route
          path={props.match.path + '/erp'}
          render={() => <DashboardComponent>
            <IframeComponent
              src={`${ERP_PROCESS}camunda/app/tasklist/default/?token=${token}&username=${user.idx}&url=${ARYA_CORE}`}
              title="Facturación"
            />
          </DashboardComponent>}
        />
      }
      {
        hasModule(MODULES.process_erp) && <Route
          path={props.match.path + '/invoice/:id'}
          component={InvoiceComponent}
        />
      }
      {
        hasModule(MODULES.accounting) && <Route
          path={props.match.path + '/accounting'}
          render={() => <DashboardComponent>
            <IframeComponent
              src={`${ACCOUNTING_PROCESS}camunda/app/tasklist/default/?token=${token}&username=${user.idx}&url=${ARYA_CORE}`}
              title="Contabilidad"
            />
          </DashboardComponent>}
        />
      }
      {
        hasModule(MODULES.billing_dashboard) && <Route
          path={props.match.path + '/billing'}
          render={() => <DashboardComponent>
            <PowerBIComponent path="aggraria" />
          </DashboardComponent>}
        />
      }
      {
        hasModule(MODULES.bam) && <Route
          path={props.match.path + '/bam'}
          render={() => <DashboardComponent>
            <IframeComponent
              src={`${SUPERSET_SERVICE}login?username=${user.idx}&redirect=/superset/dashboard/bam/?standalone=true`}
              title="Dashboard"
            />
          </DashboardComponent>}
        />
      }

      <Redirect to={redirectTo()} />
    </Switch>
  );
}
