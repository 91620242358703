import axios, { CancelTokenSource } from 'axios';
// Redux
import store from '../../store';
import { login as loginAction, logout as logoutAction, setAuthenticatedLoading } from '../../store/reducers/authReducer';
import { showSnackbar } from '../../store/reducers/alertReducer';
// App
import { MAKE_API_REQUEST, ARYA_CORE, ORGANIZATION_ID } from '../../consts/App';
import { HISTORY } from '../../consts/Router';
import MODULES from '../../consts/Modules';
// Types
import { MessageResponse } from '../../types/Api';
import { Group } from '../../types/Group';
import {
  AuthUser,
  RandomAuthUser,
  LoginBody,
  LoginResponse,
  RandomLoginResponse,
  RefreshBody,
  RecoveryBody,
  RestoreBody,
  ChangePasswordBody,
} from '../../types/Auth';
// Services
// import { googleAuthentication } from './GoogleAccountService';
import { getGroups } from './GroupServices';

let calledBefore: boolean = false;
const { dispatch, getState } = store;
const defaultConfig = {
  baseURL: ARYA_CORE,
}

const loginOnSuccess = (data: LoginResponse, source: CancelTokenSource, groups?: Group[]) => {
  localStorage.setItem('token', data.access);
  localStorage.setItem('refresh', data.refresh);
  dispatch(showSnackbar({ message: 'Ha iniciado sesion exitosamente', type: 'success' }));
  dispatch(loginAction());
  if (groups) {
    // if (groups.find(group => group.modules.find(module => module.name === MODULES.google_authentication))) {
    //   googleAuthentication(source);
    // }
    if (groups.find(group => group.modules.find(module => module.name === MODULES.process_erp))) {
      HISTORY.push('/process/erp');
    }
  }
  else {
    HISTORY.push('/home');
  }
  return data
};

export async function login(data: LoginBody, source: CancelTokenSource) {
  if (MAKE_API_REQUEST) {
    return axios.post<LoginResponse>('authentication/token/', data, { ...defaultConfig, cancelToken: source.token })
      .then(response => {
        const params = {
          uid: data.username,
          organization: ORGANIZATION_ID,
        }
        return getGroups(source, params)
          .then(res => {
            return loginOnSuccess(response.data, source, res)
          })
          .catch(error => {
            loginOnSuccess(response.data, source);
            return Promise.reject(error);
          })
      })
  } else {
    const response = new RandomLoginResponse();
    return Promise.resolve(loginOnSuccess(response, source, []));
  }
};

export async function logout() {
  try {
    localStorage.removeItem('token');
    localStorage.removeItem('refresh');
    calledBefore = false;
    dispatch(logoutAction());
    return true;
  } catch (error) {
    return Promise.reject(error);
  }
};

export async function authenticated(source: CancelTokenSource) {
  if (!calledBefore) {
    calledBefore = true;
    if (MAKE_API_REQUEST) {
      return axios.get<AuthUser>('authentication/authenticated/', { ...defaultConfig })
        .then(response => {
          const params = {
            uid: response.data.idx,
            organization: ORGANIZATION_ID,
          }
          return getGroups(source, params)
            .then(res => {
              const user: AuthUser = {
                ...response.data,
                roles: res,
              }
              return user;
            })
            .catch(error => {
              if (!error.isCanceled) {
                dispatch(setAuthenticatedLoading(false));
              }
              return Promise.reject(error);
            })
        })
        .catch(error => {
          if (!error.isCanceled) {
            dispatch(setAuthenticatedLoading(false));
          }
          return Promise.reject(error);
        });
    } else {
      const response = new RandomAuthUser();
      return Promise.resolve(response);
    }
  } else {
    return Promise.resolve(getState().auth.user);
  }
}

const refreshOnSuccess = (data: LoginResponse) => {
  localStorage.setItem('token', data.access);
  localStorage.setItem('refresh', data.refresh);
  return data;
};
export async function refreshToken() {
  if (MAKE_API_REQUEST) {
    const data: RefreshBody = { refresh: localStorage.getItem('refresh') || '' };
    return axios.post<LoginResponse>('authentication/token/refresh/', data, { ...defaultConfig })
      .then(response => refreshOnSuccess(response.data))
      .catch(error => {
        localStorage.removeItem('token');
        localStorage.removeItem('refresh');
        return Promise.reject(error);
      });
  } else {
    const response = new RandomLoginResponse();
    return Promise.resolve(refreshOnSuccess(response));
  }
};

export async function requestChangePassword(data: RecoveryBody, source: CancelTokenSource) {
  if (MAKE_API_REQUEST) {
    return axios.post<MessageResponse<'email'>>('authentication/recovery/', data, { ...defaultConfig, cancelToken: source.token })
      .then(response => {
        dispatch(showSnackbar({ message: `Se le ha enviado un correo a ${data.email}`, type: 'info' }));
        HISTORY.push('/forget-success');
        return response.data;
      });
  } else {
    dispatch(showSnackbar({ message: `Se le ha enviado un correo a ${data.email}`, type: 'info' }));
    return Promise.resolve({ email: 'Correcto' });
  }
}

export async function newPassword(data: RestoreBody, source: CancelTokenSource) {
  if (MAKE_API_REQUEST) {
    return axios.post<MessageResponse<'user'>>('authentication/restore/', data, { ...defaultConfig, cancelToken: source.token })
      .then(response => {
        dispatch(showSnackbar({ message: 'Su contraseña se ha cambiado de manera exitosa', type: 'success' }));
        HISTORY.push('/');
        return response.data;
      });
  } else {
    dispatch(showSnackbar({ message: 'Su contraseña se ha cambiado de manera exitosa', type: 'success' }));
    return Promise.resolve({ user: 'Correcto' });
  }
}

export async function changePassword(data: ChangePasswordBody, source: CancelTokenSource) {
  if (MAKE_API_REQUEST) {
    return axios.post<null>(`authentication/password/`, data, { ...defaultConfig, cancelToken: source.token })
      .then(response => {
        dispatch(showSnackbar({ message: 'Se cambio la contraseña correctamente', type: 'success' }));
        return response.data;
      });
  } else {
    dispatch(showSnackbar({ message: 'Se cambio la contraseña correctamente', type: 'success' }));
    return Promise.resolve(null);
  }
}
