import React from 'react';
// Material UI
import { createStyles, makeStyles } from '@material-ui/core/styles';
// App
import { POWER_BY_DATA } from '../../consts/PowerBi';
import PowerBiProps from '../../types/propTypes/PowerBi';
// PowerBi
import { PowerBIEmbed } from 'powerbi-client-react';
import * as models from 'powerbi-models';
import 'powerbi-report-authoring';

const useStyles = makeStyles(theme =>
  createStyles({
    iframe: {
      width: '100%',
      height: 'calc(100vh - 48px)',
      '& iframe': {
        border: 'none'
      },
      // [theme.breakpoints.down(768)]: {
      //   height: 'calc(100vh)',
      //   '& iframe': {
      //     border: 'none'
      //   },
      // },
    },
    iframeFullHeight: {
      height: '100vh',
      '& iframe': {
        border: 'none'
      },
    }
  }),
);

function PowerBI(props: PowerBiProps) {
  const styles = useStyles();
  return (
    props.powerBIData ? <PowerBIEmbed
      embedConfig={{
        type: 'report',   // Supported types: report, dashboard, tile, visual and qna
        id: props.powerBIData.embed_id,
        embedUrl: props.powerBIData.embed_url,
        accessToken: props.powerBIData.token,
        tokenType: props.powerBIData.token_type === 'embed' ? models.TokenType.Embed : models.TokenType.Aad,
        settings: {
          panes: {
            filters: {
              expanded: POWER_BY_DATA[props.path].useFilters,
              visible: POWER_BY_DATA[props.path].useFilters,
            }
          },
          navContentPaneEnabled: false,
          bookmarksPaneEnabled: false,
          background: models.BackgroundType.Default,
          layoutType: window.screen.width > 768 ? undefined : models.LayoutType.MobilePortrait,
        }
      }}
      cssClassName={props.fullHeigth ? `${styles.iframeFullHeight}` : `${styles.iframe}`}
    // eventHandlers={
    //   new Map([
    //     ['loaded', function () { console.log('Report loaded'); }],
    //     ['rendered', function () { console.log('Report rendered'); }],
    //     ['error', function (event: any) { console.log(event.detail); }]
    //   ])
    // }
    // getEmbeddedComponent={(embeddedReport) => {
    //   this.report = embeddedReport as Report;
    // }}
    /> : null
  )
}

export default PowerBI
