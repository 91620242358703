import axios, { CancelTokenSource } from 'axios';
// Redux
import store from '../../store';
import { showSnackbar } from '../../store/reducers/alertReducer'
// Types
import { Params/*, ListResponse*/ } from '../../types/Api';
import { Permission, RandomPermission, PermissionBody } from '../../types/Permission';
// App
import { MAKE_API_REQUEST, USERMANAGEMENT_SERVICE } from '../../consts/App';
import getListData from '../getListData';

const { dispatch } = store;
const defaultConfig = {
  baseURL: USERMANAGEMENT_SERVICE,
}

// export async function getPermissions(source: CancelTokenSource, params?: { paginate?: true } & Params): Promise<ListResponse<Permission>>;
// export async function getPermissions(source: CancelTokenSource, params: { paginate: false } & Params): Promise<Permission[]>;
// export async function getPermissions(source: CancelTokenSource, params: Params): Promise<Permission[] | ListResponse<Permission>>;
export async function getPermissions(source: CancelTokenSource, params?: Params) {
  if (MAKE_API_REQUEST) {
    return axios.get<Permission[]>('permissions/', { ...defaultConfig, params: params, cancelToken: source.token })
      .then(response => response.data);
  } else {
    const count = params?.limit || 10;
    const response = getListData<Permission>(count, () => new RandomPermission(), true);
    // const response = getListData<Permission>(count, () => new RandomPermission(), params?.paginate === false);
    return Promise.resolve(response);
  }
}

export async function registerPermission(data: PermissionBody, source: CancelTokenSource) {
  if (MAKE_API_REQUEST) {
    return axios.post<Permission>('permissions/', data, { ...defaultConfig, cancelToken: source.token })
      .then(response => {
        dispatch(showSnackbar({ message: 'Registro exitoso', type: 'success' }));
        return response.data;
      });
  } else {
    dispatch(showSnackbar({ message: 'Registro exitoso', type: 'success' }));
    return Promise.resolve(new RandomPermission() as Permission);
  }
};