// Redux
import store from "../store";
import { showSnackbar } from "../store/reducers/alertReducer";
// Axios
import axios, { AxiosError } from "axios";

const { dispatch } = store;

export const errorHandler = (error: Error | AxiosError) => {
  if (axios.isAxiosError(error)) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      dispatch(showSnackbar({ message: getErrorMessage(error.response.data), type: 'error' }));
      return;
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      dispatch(showSnackbar({ message: getErrorMessage(error.toString()), type: 'error' }));
      return;
    }
  }
  dispatch(showSnackbar({ message: getErrorMessage(), type: 'error' }));
};

const getErrorMessage = (error?: any): string => {
  if (error) {
    if (typeof error === 'string') {
      return error;
    } else {
      for (let entry in error) {
        if (typeof error[entry] == 'object') {
          for (const key in error[entry]) {
            return error[entry][key];
          }
        } else {
          return error[entry];
        }
      }
    }
  }
  return 'Revise su conexión a internet';
}