import { AddElement } from "../types/misc";

// Check if a prop of type AddElement is of a provided type P
export const isObject = <T, P extends {}>(element: AddElement<T, P>): element is P => {
  return typeof (element as P) === 'object';
}

// Pass provided element to a Prop of type AddElement
export const spreadProps = <T, P extends {}>(element: T, propsToSpread?: AddElement<T, P>): P | undefined => {
  return propsToSpread && (isObject(propsToSpread) ? propsToSpread : propsToSpread(element));
};