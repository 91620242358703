import React from 'react';
// Material UI
import { ThemeProvider } from '@material-ui/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { LocalizationProvider } from '@material-ui/pickers';
import MomentAdapter from '@material-ui/pickers/adapter/moment';
// App
import { THEME } from './theme/Theme';
import FancySnackbar from './components/common/FancySnackbar';
import './config/yup';
import './config/axios';
// Router
import { Router } from 'react-router-dom';
import { HISTORY } from './consts/Router';
import Routing from './components/router/Routing';
// Redux
import { Provider } from 'react-redux';
import store from './store';
// Moment
import moment from 'moment';
import 'moment/locale/es';

moment.locale('es');

export default function App() {
  return (
    <Provider store={store}>
      <ThemeProvider theme={THEME}>
        <Router history={HISTORY}>
          <LocalizationProvider dateLibInstance={moment} dateAdapter={MomentAdapter} locale="es">
            <CssBaseline />
            <Routing />
            <FancySnackbar />
          </LocalizationProvider>
        </Router>
      </ThemeProvider>
    </Provider>
  );
}
