import React, { useEffect, useState } from 'react';
// Material UI
import { createStyles, makeStyles } from '@material-ui/core/styles';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
// Services
import { getPowerBIData } from '../../../services/API/PowerBIServices';
// Types
import { PowerBi } from '../../../types/PowerBi';
// App
import { POWER_BY_DATA } from '../../../consts/PowerBi';
import { FancyModal, FancyProgress } from '../../common/FancyComponents';
import FloatingButton from '../../common/FloatingButton'
import PowerBI from '../../common/PowerBi';
// Redux
import { useAppDispatch, useAppSelector } from '../../../consts/ReduxHooks';
import { showDialog, dismissDialog } from '../../../store/reducers/dialogReducer';
// PowerBi
import 'powerbi-report-authoring';
// Others
import axios, { CancelTokenSource } from 'axios';

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      marginLeft: theme.spacing(-3),
      marginRight: theme.spacing(-3),
      [theme.breakpoints.down("xs")]: {
        marginLeft: theme.spacing(-1),
        marginRight: theme.spacing(-1),
      }
    },
    progress: {
      height: '100vh',
    },
  })
);

const source: CancelTokenSource[] = new Array(2).fill(axios.CancelToken.source());

export default function PowerBIComponent(props: { path: string }) {
  const styles = useStyles();
  const dispatch = useAppDispatch()
  const dialog = useAppSelector(state => state.dialog.open);
  const [loading, setLoading] = useState(false);
  const [powerBIData, setPowerBIData] = useState<PowerBi | null>(null);

  const fetchPowerBIData = () => {
    setLoading(true);
    const params = {
      name: POWER_BY_DATA[props.path].name,
    }
    source[0] = axios.CancelToken.source();
    getPowerBIData(source[0], params)
      .then(data => {
        setPowerBIData(data);
        setLoading(false);
      })
      .catch(error => {
        if (!error.isCanceled) {
          setLoading(false);
        }
      });
  }

  useEffect(() => {
    source[1] = axios.CancelToken.source();
    return () => {
      source.forEach(s => s.cancel());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchPowerBIData();
    return () => {
      source[0].cancel();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.path]);

  const handlerFancyModal = () => {
    dispatch(showDialog());
  }

  const handleClose = () => {
    dispatch(dismissDialog());
  };

  const Bi = <PowerBI
    fullHeigth={dialog}
    powerBIData={powerBIData}
    path={props.path}
  />

  return <React.Fragment>
    {
      !loading && powerBIData && <div className={styles.root}>
        {Bi}
        <FloatingButton
          onClick={handlerFancyModal}
          color={'secondary'}
          icon={<FullscreenIcon />} />

        <FancyModal open={dialog} onClose={handleClose} fullScreen={true}>
          {Bi}
        </FancyModal>
      </div>
    }
    {loading && <FancyProgress color="primary" size={100} containerClass={styles.progress} />}
  </React.Fragment>
}
