import axios, { AxiosRequestConfig, CancelTokenSource } from 'axios';
// Types
import { ListResponse } from '../../types/Api';
import {
  Account,
  AccountBody,
  AccountParams,
  Business,
  RandomAccount,
  RandomBusiness,
  RandomWidget,
  Widget,
} from '../../types/Banking';
// App
import { BANKING_SERVICE, MAKE_API_REQUEST } from '../../consts/App';
import getListData from '../getListData';

const defaultConfig: AxiosRequestConfig = {
  baseURL: BANKING_SERVICE,
  headers: { 'NO-HEADERS': true },
}

// export async function getAccounts(source: CancelTokenSource, params?: { paginate?: true } & AccountParams): Promise<ListResponse<Account>>;
// export async function getAccounts(source: CancelTokenSource, params: { paginate: false } & AccountParams): Promise<Account[]>
// export async function getAccounts(source: CancelTokenSource, params: AccountParams): Promise<Account[] | ListResponse<Account>>
export async function getAccounts(source: CancelTokenSource, params?: AccountParams) {
  if (MAKE_API_REQUEST) {
    return axios.get<Account[]>('accounts/', { ...defaultConfig, params: params, cancelToken: source.token })
      .then(response => response.data);
  } else {
    const count = params?.limit || 10;
    const response = getListData<Account>(count, () => new RandomAccount(), true);
    return Promise.resolve(response);
  }
}

export async function updateAccount(number: string, data: AccountBody, source: CancelTokenSource) {
  if (MAKE_API_REQUEST) {
    return axios.patch<Account>(`accounts/${number}/`, data, { ...defaultConfig, cancelToken: source.token })
      .then(response => {
        return response.data;
      });
  } else {
    return Promise.resolve(new RandomAccount() as Account);
  }
};

export async function getWidget(email: string, source: CancelTokenSource) {
  if (MAKE_API_REQUEST) {
    return axios.get<Widget>(`widgets/${email}/`, { ...defaultConfig, cancelToken: source.token })
      .then(response => response.data);
  } else {
    return Promise.resolve(new RandomWidget() as Widget);
  }
};

export async function getFitnanceReader(email: string, source: CancelTokenSource) {
  if (MAKE_API_REQUEST) {
    return axios.get<null>(`banking/events/fitnance_reader/${email}/`, { ...defaultConfig, cancelToken: source.token })
      .then(response => response.data);
  } else {
    return Promise.resolve(null);
  }
};

export async function getBusinesses(source: CancelTokenSource, params?: { paginate?: true } & AccountParams): Promise<ListResponse<Business>>;
export async function getBusinesses(source: CancelTokenSource, params: { paginate: false } & AccountParams): Promise<Business[]>
export async function getBusinesses(source: CancelTokenSource, params: AccountParams): Promise<Business[] | ListResponse<Business>>
export async function getBusinesses(source: CancelTokenSource, params?: AccountParams) {
  if (MAKE_API_REQUEST) {
    return axios.get<Business[] | ListResponse<Business>>('businesses/', { ...defaultConfig, params: params, cancelToken: source.token })
      .then(response => response.data);
  } else {
    const count = params?.limit || 10;
    const response = getListData<Business>(count, () => new RandomBusiness(), params?.paginate === false);
    return Promise.resolve(response);
  }
}