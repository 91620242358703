import faker from 'faker';

export interface Business {
  id: number;
  name: string;
  cif: string;
  customer?: Array<any>
}

export interface BusinessBody {
  name: string;
  cif: string;
  customer?: Array<any>
}

export class BusinessForm {
  name: string= '';
  cif: string= '';
  customer?: Array<any>=[]
}

export interface Customer {
  names: string,
  document: string,
  email: string
}

export class RandomCustomer implements Customer {
  document = faker.datatype.string(10);
  names = faker.name.firstName() + faker.name.lastName() ;
  email = faker.internet.email();

  constructor(init?: Partial<Customer>) {
    Object.assign(this, init);
  }
}

export class RandomBusiness implements Business {
  id = faker.unique(faker.datatype.number);
  name = faker.company.companyName();
  cif = faker.datatype.string(5);
  customer = new Array(4).fill(true).map(() => ({ ...new RandomCustomer() }));

  constructor(init?: Partial<Business>) {
    Object.assign(this, init);
  }
}

