import { createSlice } from '@reduxjs/toolkit';
import { SidebarReducer } from '../../types/redux/sidebarTypes'

// Can´t instantiate new classes with new Class() as initalState,
// use spread operator to convert to object instead or don´t use Classes
const initialState: SidebarReducer = { ...new SidebarReducer() };

export const sidebarSlice = createSlice({
  name: 'sidebar',
  initialState,
  reducers: {
    toogleSidebar: state => {
      state.open = !state.open;
    },
  }
});

export const { toogleSidebar } = sidebarSlice.actions;

export default sidebarSlice.reducer;
