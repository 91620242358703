//v1.0
// Note: Import before Routing component in App to work
import * as yup from 'yup';

yup.setLocale({
  mixed: {
    default: 'No es válido',
    required: 'Este campo es requerido'
  },
  string: {
    email: 'Ingrese un correo válido',
    // eslint-disable-next-line
    min: 'Debe tener mínimo ${min} caracteres',
    // eslint-disable-next-line
    max: 'Debe tener máximo ${max} caracteres',
  },
  number: {
    // eslint-disable-next-line
    min: 'Debe ser menor que ${min}',
    // eslint-disable-next-line
    max: 'Debe ser mayor que ${max}',
  },
});

yup.addMethod<yup.StringSchema>(yup.string, 'lettersOnly', function () {
  return this.matches(/^[A-zäáëéïíöóüúñçÄÁËÉÏÍÖÓÜÚÇÑ'\s]+$/, {
    message: 'Este campo solo puede contener letras',
    excludeEmptyString: true,
  })
})