import React from 'react';
// Material UI
import { makeStyles, Theme, createStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
// Redux
import { useAppDispatch } from '../../consts/ReduxHooks';
import { toogleSidebar } from '../../store/reducers/sidebarReducer';
// App
import { MENU } from '../../consts/Menu';
import { MenuItem } from '../../types/Menu';
// Services
import filterByModules from '../../services/filterByModules';
// Other
import { Link, LinkProps, useHistory } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      // color: theme.palette.primary.contrastText,
      // color: theme.palette.primary.main,
    },
    selectedList: {
      backgroundColor: 'rgba(0, 0, 0, 0.2) !important'
    },
    listItemIcon: {
      minWidth: theme.spacing(5),
      color: 'inherit'
    },
  }),
);

function ListItemLink(props: any) {
  const { to } = props;

  const renderLink = React.useMemo(
    () =>
      React.forwardRef<any, Omit<LinkProps, 'to'>>((itemProps, ref) => (
        <Link to={to} ref={ref} {...itemProps} />
      )),
    [to],
  );

  return (
    <ListItem {...props} component={to ? renderLink : 'button'} />
  );
};

export default function SidebarNavigation() {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const theme = useTheme();
  const matchesDesktop = useMediaQuery(theme.breakpoints.up('sm'));
  // const open = useAppSelector(state => state.sidebar.open);
  const menuItems = getMenuItems();
  const mobileMenuItems = getMenuItems(true);
  const [expanded, setExpanded] = React.useState<boolean[]>(new Array(menuItems.length).fill(false));
  const [selected, setSelected] = React.useState<(MenuItem & { level: number }) | null>(null);
  const [allSelected, setAllSelected] = React.useState<(MenuItem & { level: number })[]>([]);

  // React.useEffect(() => {
  //   if (!open) {
  //     setExpanded(new Array(menuItems.length).fill(false));
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [open]);

  React.useEffect(() => {
    const selectedMenuItemIndex = menuItems.findIndex(item => item.expand && !!item.children.find(child => child.to === history.location.pathname));
    if (selectedMenuItemIndex > -1) {
      setExpanded(expanded.map((o, i) => {
        if (i === selectedMenuItemIndex) {
          return !o;
        }
        return o;
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function getMenuItems(returnAll?: boolean) {
    const menu = MENU.find(item => item.to === '/' + history.location.pathname.split('/')[1]);
    let menuItems = (!returnAll && (menu && menu.expand)) ? [...menu.children] : [...MENU];
    return filterByModules(menuItems);
  }

  const handleBackClick = () => {
    if (!matchesDesktop && selected) {
      if (selected.level === 1) {
        setSelected(null);
        setAllSelected([]);
      } else {
        const newSelected = allSelected.find(sel => sel.level === selected.level - 1);
        if (newSelected) {
          setAllSelected(prev => prev.filter(sel => sel !== selected));
          setSelected(newSelected);
        }
      }
    } else {
      dispatch(toogleSidebar());
    }
  }

  const handleDektopClick = (index: number, item: MenuItem) => {
    if (item.expand) {
      // if (!open) {
      //   dispatch(toogleSidebar());
      // }
      setExpanded(expanded.map((o, i) => {
        if (i === index) {
          return !o;
        }
        return o;
      }));
    }
  };

  const handleMobileClick = (item: MenuItem) => {
    if (item.expand) {
      const sel = { ...item, level: selected ? (selected.level + 1) : 1 };
      setSelected(sel);
      setAllSelected(prev => prev.concat(sel));
    } else {
      dispatch(toogleSidebar());
    }
  };

  const handleSelected = (item: MenuItem) => {
    // if (item.expand && !open && matchesDesktop) {
    //   return !!item.children.find(c => c.to === history.location.pathname);
    // } else {
    return item.to === history.location.pathname;
    // }
    // const pathnames = history.location.pathname.split('/').filter((x) => x);
    // return item.to === `/${pathnames.slice(0, 2).join('/')}`
  }

  // const handlerFile = (file: any) => {
  //   dispatch(showDiagramBpmn(file))
  // }

  const SidebarDesktop = <List>
    {
      menuItems.map((item, index) => (
        <React.Fragment key={index}>
          <ListItemLink
            button
            onClick={() => handleDektopClick(index, item)}
            selected={handleSelected(item)}
            to={item.to}
            classes={{ selected: classes.selectedList }}
          >
            <ListItemIcon className={classes.listItemIcon}>{item.icon}</ListItemIcon>
            <ListItemText primary={item.name} />
            {item.expand ? expanded[index] ? <ExpandLessIcon /> : <ExpandMoreIcon /> : null}
          </ListItemLink>
          {
            item.expand && <Collapse in={expanded[index]} timeout="auto" unmountOnExit>
              <List component="div" disablePadding dense>
                {(item.children.length !== 0) &&
                  item.children.map(child => (
                    <ListItemLink
                      button
                      key={child.name}
                      to={child.to}
                      selected={child.to === history.location.pathname}
                      onClick={() => dispatch(toogleSidebar())}
                      classes={{
                        selected: classes.selectedList
                      }}
                    >
                      <ListItemIcon className={classes.listItemIcon} />
                      <ListItemText primary={child.name} />
                    </ListItemLink>
                  ))
                }
                {/* {(item.files.length !== 0) &&
                item.files.map(child => (
                  <ListItemLink
                    button
                    onClick={() => handlerFile(child)}
                    key={child.name}
                    to={child.to}
                    selected={child.to === history.location.pathname}
                    classes={{
                      selected: classes.selectedList
                    }}
                  >
                    <ListItemIcon style={{ color: 'inherit' }} />
                    <ListItemText primary={child.name} />
                  </ListItemLink>
                ))
              } */}
              </List>
            </Collapse>
          }
        </React.Fragment>
      ))
    }
  </List>

  const SidebarMobile = <List>
    {
      ((selected && selected.expand) ? selected.children : mobileMenuItems).map((item, index) => (
        <ListItemLink
          button
          key={index}
          onClick={() => handleMobileClick(item)}
          selected={handleSelected(item)}
          to={item.expand ? null : item.to}
          classes={{ selected: classes.selectedList }}
        >
          <ListItemIcon className={classes.listItemIcon}>{item.icon}</ListItemIcon>
          <ListItemText primary={item.name} />
          {item.expand ? <ArrowForwardIosIcon fontSize={'small'} /> : null}
        </ListItemLink>
      ))
    }
  </List>

  return (
    <Grid container justify="space-between" className={classes.root}>
      <Grid item xs={12}>
        <Grid container alignItems="center" justify="center" spacing={1}>
          <Grid item>
            <IconButton color="inherit" onClick={handleBackClick} edge="start">
              <ArrowBackIosIcon fontSize={'small'} />
            </IconButton>
          </Grid>
          <Grid item>
            <Typography><strong>
              {(!matchesDesktop && selected) ? selected.name : 'Bienvenido'}
            </strong></Typography>
          </Grid>
        </Grid>
        <Hidden smDown>
          {SidebarDesktop}
        </Hidden>
        <Hidden mdUp>
          {SidebarMobile}
        </Hidden>
      </Grid>
    </Grid>
  )
}
