import React, { ReactElement } from 'react';
// Material UI
import { makeStyles, Theme, createStyles/*, useTheme*/ } from '@material-ui/core/styles';
// import useMediaQuery from '@material-ui/core/useMediaQuery';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
// import Typography from '@material-ui/core/Typography';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Avatar from '@material-ui/core/Avatar';
import Divider from '@material-ui/core/Divider';
import Toolbar from '@material-ui/core/Toolbar';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import LockIcon from '@material-ui/icons/Lock';
// Redux
import { useAppDispatch, useAppSelector } from '../../consts/ReduxHooks';
import { toogleSidebar } from '../../store/reducers/sidebarReducer';
import { showSnackbar } from '../../store/reducers/alertReducer';
// App
import { useFancyStyles } from '../common/FancyComponents';
import SidebarNavigation from '../common/SidebarNavigation';
import RouterBreadcrumbs from '../common/Breadcrumbs';
import HeaderTabs from '../common/HeaderTabs';
import NotificationComponent from '../common/NotificationComponent';
import LogoHeader from '../../assets/images/logo_header.svg';
import LogoMovil from '../../assets/images/logo_header_movil.svg';
// Services
import { logout } from '../../services/API/AuthServices';
// Other
import { Link, useHistory } from 'react-router-dom';
// import clsx from 'clsx';

const drawerWidth = 250;
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      [theme.breakpoints.up('sm')]: {
        display: 'flex',
      },
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
    },
    toolbar: {
      minHeight: 48,
      height: 48,
    },
    logo: {
      height: 24,
      width: 'auto',
    },
    avatar: {
      width: theme.spacing(4),
      height: theme.spacing(4),
      backgroundColor: theme.palette.secondary.light
    },
    drawerMobilePaper: {
      width: '100vw',
      marginTop: '3rem',
    },
    drawerDesktop: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerDesktopOpen: {
      width: drawerWidth,
      marginTop: 48, // Toolbar size
      height: "calc(100% - 64px)", // 100% - Toolbar size
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
      }),
    },
    drawerDesktopClose: {
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      }),
      overflowX: "hidden",
      whiteSpace: "nowrap",
      marginTop: 48, // Toolbar size
      height: "calc(100% - 64px)", // 100% - Toolbar size
      width: theme.spacing(7) + 1,
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9) + 1
      }
    },
    content: {
      flexGrow: 1,
      marginTop: 48, // Toolbar size
      padding: theme.spacing(0, 3),
      [theme.breakpoints.down("xs")]: {
        padding: theme.spacing(0, 1),
        marginTop: 48, // movil Toolbar size
      },
    },
    contentHeader: {
      margin: 0,
      padding: theme.spacing(0.5, 0),
      [theme.breakpoints.up('sm')]: {
        margin: theme.spacing(1, 0),
        padding: 0,
      }
    },
    listItemIcon: {
      minWidth: 'auto',
      marginRight: theme.spacing(2),
    },
  }),
);

interface DasboardComponentProps {
  title?: string;
  children: ReactElement;
};

export default function DashboardComponent(props: DasboardComponentProps) {
  const styles = useStyles();
  const classes = useFancyStyles();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const open = useAppSelector(state => state.sidebar.open);
  const user = useAppSelector(state => state.auth.user);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleDrawerOpen = () => {
    dispatch(toogleSidebar());
  };

  const handleMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    // history.push('/information')
    setAnchorEl(null);
  };

  const handleSidebarToggle = () => {
    handleDrawerOpen();
  }

  const handlerLogOut = () => {
    logout()
      .then(() => {
        dispatch(showSnackbar({ message: 'Ha cerrado sesión exitosamente', type: 'success' }));
        history.push('/');
      });
  };

  return (
    <div className={styles.root}>
      <AppBar position="fixed" className={styles.appBar} color="secondary">
        <Toolbar className={styles.toolbar}>
          <Grid container justify="space-between">
            <Grid item>
              <Grid container alignItems="center" className={classes.h_100}>
                <Hidden mdUp>
                  <Grid item>
                    <IconButton color="inherit" onClick={handleSidebarToggle} edge="start">
                      <MenuIcon />
                    </IconButton>
                  </Grid>
                </Hidden>
                <Hidden xsDown>
                  <Grid item className={classes.mr_1} style={{ height: 24 }}>
                    <Hidden only="md">
                      <img src={LogoHeader} alt="logo" className={styles.logo} />
                    </Hidden>
                    <Hidden smDown lgUp>
                      <img src={LogoMovil} alt="logo" className={styles.logo} />
                    </Hidden>
                  </Grid>
                </Hidden>
                <Hidden smDown>
                  <Grid item className={classes.ml_1}>
                    <HeaderTabs />
                  </Grid>
                </Hidden>
              </Grid>
            </Grid>
            <Hidden smUp>
              <Grid item>
                <Grid container alignItems="center" className={classes.h_100}>
                  <Grid item style={{ height: 24 }}>
                    <img src={LogoMovil} alt="logo" className={styles.logo} />
                  </Grid>
                </Grid>
              </Grid>
            </Hidden>
            <Grid item>
              <Grid container alignItems="center">
                <Grid item>
                  <NotificationComponent />
                </Grid>
                <Grid item>
                  <IconButton color="inherit" onClick={handleMenu}>
                    <Avatar className={styles.avatar} />
                  </IconButton>
                  <Menu
                    anchorEl={anchorEl}
                    keepMounted
                    open={!!anchorEl}
                    onClose={handleCloseMenu}
                    getContentAnchorEl={null}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                  >
                    <ListItem>
                      <ListItemText
                        primaryTypographyProps={{ style: { fontSize: 'inherit', textAlign: 'center' } }}
                        primary={<strong>Hola {user.first_name} {user.last_name}.</strong>}
                      />
                    </ListItem>
                    <Divider />
                    <MenuItem component={Link} to="/change-password">
                      <ListItemIcon style={{ color: 'inherit' }} className={styles.listItemIcon}>
                        <LockIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText
                        primaryTypographyProps={{ style: { fontSize: 'inherit' } }}
                        primary="Cambiar contraseña"
                      />
                    </MenuItem>
                    <MenuItem onClick={handlerLogOut}>
                      <ListItemIcon style={{ color: 'inherit' }} className={styles.listItemIcon}>
                        <PowerSettingsNewIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText
                        primaryTypographyProps={{ style: { fontSize: 'inherit' } }}
                        primary="Cerrar sesión"
                      />
                    </MenuItem>
                  </Menu>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <nav>
        {/* <Hidden smUp implementation="css"> */}
        <Drawer
          variant="persistent"
          open={open}
          onClose={handleDrawerOpen}
          classes={{ paper: styles.drawerMobilePaper }}
          ModalProps={{
            keepMounted: true,  // Better open performance on mobile.
            // BackdropProps: { invisible: true }
          }}
        >
          <SidebarNavigation />
        </Drawer>
        {/* </Hidden> */}
        {/* <Hidden xsDown implementation="css">
            <Drawer
              variant="permanent"
              className={clsx(styles.drawerDesktop, {
                [styles.drawerDesktopOpen]: open,
                [styles.drawerDesktopClose]: !open
              })}
              classes={{ paper: clsx({ [styles.drawerDesktopOpen]: open, [styles.drawerDesktopClose]: !open }) }}
            >
              <SidebarNavigation />
            </Drawer>
          </Hidden> */}
      </nav>
      <div className={styles.content}>
        {
          props.title && <React.Fragment>
            <Grid container justify="space-between" alignItems="center" spacing={1} className={styles.contentHeader}>
              {/* <Grid item>
                <Typography variant="h4" paragraph className={classes.mt_2}>
                  <strong>{props.title}</strong>
                </Typography>
              </Grid> */}
              {/* <Hidden xsDown> */}
              <Grid item><RouterBreadcrumbs /></Grid>
              {/* </Hidden> */}
            </Grid>
            <Divider className={classes.mb_2} />
          </React.Fragment>
        }
        {props.children}
      </div>
    </div>
  );
}
