import React from 'react';
// router
import { Switch, Redirect } from "react-router-dom";
// App
import MODULES from '../../consts/Modules';
// Services
import hasModule from '../../services/hasModule';
// Routing
import { ProtectedRoute } from "./ProtectRoute";
import { PublicRoute } from './PublicRoute'
import MarketingRouting from './Marketing/MarketingRouting';
import ManagementRouting from './Management/ManagementRouting';
import ProcessRouting from './Process/ProcessRouting';
// Layouts
import PublicViews from '../layouts/PublicViews';
import DashboardComponent from '../layouts/DashboardComponent';
// Views
import ChangePasswordComponent from '../views/ChangePassword/ChangePasswordComponent';
import LoginComponent from '../views/Login/LoginComponent';
import GroupSelectionComponent from '../views/Login/GroupSelectionComponent';
import ForgetPasswordComponent from '../views/RestorePassword/ForgetPasswordComponent';
import ForgetPasswordSuccessComponent from '../views/RestorePassword/ForgetPasswordSuccessComponent';
import RestorePasswordForm from '../views/RestorePassword/RestorePasswordForm';
import GoogleAccountComponent from '../views/Accounts/GoogleAccountComponent';
import HomeComponent from '../views/Home/Home';
import IframeComponent from '../common/IframeComponent';
import { DATASTUDIO_SUPERSET_SERVICE, SUPERSET_SERVICE} from '../../consts/App';
import { useAppSelector } from '../../consts/ReduxHooks';


export default function Routing() {
  const user = useAppSelector(state => state.auth.user);

  return (
    <Switch>
      {/* Public Routes */}
      <PublicRoute
        exact
        path={['/', '/login']}
        render={props => <PublicViews><LoginComponent /></PublicViews>}
      />
      <PublicRoute
        exact
        path={['/groups']}
        render={props => <PublicViews><GroupSelectionComponent /></PublicViews>}
      />
      <PublicRoute
        exact
        path={['/forget']}
        render={props => <PublicViews><ForgetPasswordComponent /></PublicViews>}
      />
      <PublicRoute
        exact
        path={['/forget-success']}
        render={props => <PublicViews><ForgetPasswordSuccessComponent /></PublicViews>}
      />
      <PublicRoute
        exact
        path={['/recovery']}
        render={props => <PublicViews children={<RestorePasswordForm />} />}
      />

      {/* Protected Routes */}
      <ProtectedRoute path={['/comercial']} component={MarketingRouting} />
      <ProtectedRoute path={['/process']} component={ProcessRouting} />
      <ProtectedRoute path={['/management']} component={ManagementRouting} />
      <ProtectedRoute
        exact
        path={['/home']}
        render={props => <DashboardComponent children={<HomeComponent />} />}
      />
      <ProtectedRoute
        exact
        path={['/change-password']}
        render={props => <DashboardComponent
          title="Cambio de contraseña"
          children={<ChangePasswordComponent />}
        />} />
      <ProtectedRoute
        exact
        path={['/google-account']}
        render={props => hasModule(MODULES.google_authentication)
          ? <DashboardComponent children={<GoogleAccountComponent />} />
          : <Redirect to="/" />}
      />
      
      <ProtectedRoute
        exact
        path={['/expense-process']}
        render={props => hasModule(MODULES.expense_process)
          ? <DashboardComponent>
            <IframeComponent
              src={`${SUPERSET_SERVICE}login?username=${user.idx}&redirect=/superset/dashboard/estado-proceso-gastos/?standalone=true&native_filters_key=RXd9ESihXx5GisLlSRrWGoWf7GielQL0EQCKbuxvzfve5fKRUImNrBsdt7kmmC0v`}
              title="Dashboard"
            />
          </DashboardComponent>
          : <Redirect to="/" />}
      />
      <ProtectedRoute
        exact
        path={['/income-process']}
        render={props => hasModule(MODULES.income_process)
          ? <DashboardComponent>
            <IframeComponent
              src={`${DATASTUDIO_SUPERSET_SERVICE}embed/reporting/9ce03d8d-ea2e-45bd-8b16-895ac902b368/page/v4YAD`}
              title="Dashboard"
            />
          </DashboardComponent>
          : <Redirect to="/" />}
      />
      <ProtectedRoute
        exact
        path={['/income-analytics']}
        render={props => hasModule(MODULES.income_analytics)
          ? <DashboardComponent>
            <IframeComponent
              src={`${DATASTUDIO_SUPERSET_SERVICE}embed/reporting/ee2cc0cf-f8d1-4b9b-a917-89c11413b8ba/page/uOABD`}
              title="Dashboard"
            />
          </DashboardComponent>
          : <Redirect to="/" />}
      />
      <ProtectedRoute
        exact
        path={['/expense-analytics']}
        render={props => hasModule(MODULES.expense_analytics)
          ? <DashboardComponent>
            <IframeComponent
              src={`${DATASTUDIO_SUPERSET_SERVICE}embed/reporting/1329197f-78fa-4cec-807d-ecdad3893e70/page/p_jmherpuu1c`}
              title="Dashboard"
            />
          </DashboardComponent>
          : <Redirect to="/" />}
      />
      <ProtectedRoute
        exact
        path={['/results-analytics']}
        render={props => hasModule(MODULES.results_analytics)
          ? <DashboardComponent>
            <IframeComponent
              src={`${DATASTUDIO_SUPERSET_SERVICE}embed/reporting/ca8e3c2f-3f28-4cf0-a932-983c3350e8a4/page/9o99C`}
              title="Dashboard"
            />
          </DashboardComponent>
          : <Redirect to="/" />}
      />
      <Redirect to="/" />
    </Switch>
  );
}
