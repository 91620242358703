import React, { useState } from 'react';
// Material ui
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import InputAdornment from '@material-ui/core/InputAdornment';
import EmailIcon from '@material-ui/icons/Email';
// Forms
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers';
import * as yup from "yup";
// App
import { FancyTextInput, FancyButton, PasswordTextInput, useFancyStyles } from '../../common/FancyComponents';
import Logo from '../../../assets/images/logo.svg';
// Types
import { LoginBody } from '../../../types/Auth';
// Services
import { login } from '../../../services/API/AuthServices';
// Others
import { Link } from 'react-router-dom';
import axios, { CancelTokenSource } from 'axios';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    logo: {
      height: 150,
      width: 'auto',
    },
    span: {
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
      }
    },
    paper: {
      width: '350px',
      height: '100%',
      background: '#fafafa',
      padding: theme.spacing(2, 3),
      [theme.breakpoints.down('xs')]: {
        width: 'auto',
        padding: theme.spacing(1, 2),
      }
    }
  }),
);

const schema = yup.object().shape({
  username: yup.string().required().email(),
  password: yup.string().required(),
});

let source: CancelTokenSource = axios.CancelToken.source();

export default function LoginComponent() {
  const styles = useStyles();
  const classes = useFancyStyles();
  const methods = useForm<LoginBody>({
    resolver: yupResolver(schema),
    defaultValues: new LoginBody(),
  });
  const { handleSubmit, control, errors } = methods;
  const [busy, setBusy] = useState(false);

  React.useEffect(() => {
    source = axios.CancelToken.source();
    return () => {
      source.cancel();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = (data: LoginBody) => {
    setBusy(true);
    login(data, source)
      .catch(error => {
        if (!error.isCanceled) {
          setBusy(false);
        }
      });
  }

  return (
    <Paper className={styles.paper} elevation={0}>
      <form onSubmit={handleSubmit(onSubmit)} aria-label="Login">
        <Grid container direction="row" spacing={1}>
          <Grid item xs={12} container justify="center">
            <img src={Logo} alt="logo" className={styles.logo} />
          </Grid>
          {/* <Grid item xs={12}>
            <Typography align="center" variant="h6">Iniciar Sesión</Typography>
          </Grid> */}
          <Grid item xs={12}>
            <Controller
              render={props => <FancyTextInput
                {...props}
                InputProps={{
                  startAdornment: <InputAdornment position="start"><EmailIcon color="secondary" /></InputAdornment>,
                }}
                error={!!errors.username}
                helperText={errors.username && errors.username.message}
                type="email"
                label="Correo"
                id="login_email"
                placeholder="Ingrese su correo"
              />}
              control={control}
              name="username"
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              render={props => <PasswordTextInput
                {...props}
                error={!!errors.password}
                helperText={errors.password && errors.password.message}
                autoComplete="current-password"
                label="Contraseña"
                id="login_password"
                placeholder="Ingrese su contraseña"
              />}
              control={control}
              name="password"
            />
          </Grid>
          <Grid item xs={12} container direction="row" justify="flex-end">
            <Typography component={Link} variant='body2' to='/forget' className={[styles.span, classes.secondaryHover].join(' ')}>
              He olvidado mi contraseña
            </Typography>
          </Grid>
          <Grid container direction="row" justify="center">
            <Grid item xs={12} >
              <FancyButton
                fullWidth={true}
                variant="contained"
                color='primary'
                type="submit"
                disabled={busy}
                loading={busy}
              >
                Ingresar
              </FancyButton>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Paper>
  );
}
