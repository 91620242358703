import React from 'react';
// Material UI
import { makeStyles, createStyles } from '@material-ui/core/styles';

export interface IframeComponentProps {
  /** Iframe title */
  title: string;
  /** Iframe src */
  src: string;
  /** Used to add aditional space for dashboard title section (where breadcrumbs are showed) */
  hasTitle?: boolean;
}

export default function IframeComponent(props: IframeComponentProps) {
  const useStyles = makeStyles((theme) =>
    createStyles({
      root: {
        marginLeft: theme.spacing(-3),
        marginRight: theme.spacing(-3),
        [theme.breakpoints.down("xs")]: {
          marginLeft: theme.spacing(-1),
          marginRight: theme.spacing(-1),
        }
      },
      iframe: {
        width: '100%',
        height: `calc(100vh - 54px${props.hasTitle ? ' - 66px' : ''})`,
        [theme.breakpoints.down("xs")]: {
          height: `calc(100vh - 54px${props.hasTitle ? ' - 58px' : ''})`,
        }
      }
    })
  );
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <iframe frameBorder={0} src={props.src} title={props.title} className={classes.iframe}></iframe>
    </div>
  );
}
