import { ListResponse } from "../types/Api";

function getListData<T extends any>(count: number, newDataCallback: (index: number) => T, isListRespone: true): T[];
function getListData<T extends any>(count: number, newDataCallback: (index: number) => T, isListRespone: false): ListResponse<T>;
function getListData<T extends any>(count: number, newDataCallback: (index: number) => T, isListRespone: boolean): T[] | ListResponse<T>;
function getListData<T extends any>(count: number, newDataCallback: (index: number) => T, isListRespone: boolean) {
  const list: T[] = [];
  for (let i = 0; i < count; i++) {
    list.push(newDataCallback(i));
  }
  if (isListRespone) {
    return list;
  } else {
    const response: ListResponse<T> = {
      count: count,
      next: null,
      previous: null,
      results: list,
    }
    return response;
  }
}

export default getListData;