import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SnackbarReducer, SnackbarAction } from '../../types/redux/alertTypes'

// Can´t instantiate new classes with new Class() as initalState,
// use spread operator to convert to object instead or don´t use Classes
const initialState: SnackbarReducer = { ...new SnackbarReducer() };

export const alertSlice = createSlice({
  name: 'alert',
  initialState,
  reducers: {
    showSnackbar: (state, action: PayloadAction<SnackbarAction>) => ({
      ...action.payload,
      open: true,
    }),
    dismissSnackbar: state => initialState,
  }
});

export const { showSnackbar, dismissSnackbar } = alertSlice.actions;

export default alertSlice.reducer;
