//v3.2
import React, { forwardRef, FunctionComponent } from 'react';
// Material ui
import { makeStyles, createStyles, Theme, withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CircularProgress, { CircularProgressProps } from '@material-ui/core/CircularProgress';
import Tabs from '@material-ui/core/Tabs';
import Tab, { TabProps } from '@material-ui/core/Tab';
import TextField, { TextFieldProps } from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import Tooltip, { TooltipProps } from '@material-ui/core/Tooltip';
import Button, { ButtonProps } from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import LockIcon from '@material-ui/icons/Lock';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { DatePicker, DatePickerProps } from '@material-ui/pickers';

export const useFancyStyles = makeStyles((theme: Theme) =>
  createStyles({
    h_100: {
      height: '100% !important',
    },
    h_100v: {
      height: '100vh !important',
    },
    m_2: {
      margin: theme.spacing(2) + 'px !important',
    },
    m_05: {
      margin: theme.spacing(0.5) + 'px !important',
    },
    my_2: {
      marginTop: theme.spacing(2) + 'px !important',
      marginBottom: theme.spacing(2) + 'px !important',
    },
    mt_1: {
      marginTop: theme.spacing(1) + 'px !important',
    },
    mt_2: {
      marginTop: theme.spacing(2) + 'px !important',
    },
    mb_1: {
      marginBottom: theme.spacing(1) + 'px !important',
    },
    mb_2: {
      marginBottom: theme.spacing(2) + 'px !important',
    },
    mr_1: {
      marginRight: theme.spacing(1) + 'px !important',
    },
    ml_1: {
      marginLeft: theme.spacing(1) + 'px !important',
    },
    ml_2: {
      marginLeft: theme.spacing(2) + 'px !important',
    },
    p_0: {
      padding: 0 + ' !important',
    },
    p_1: {
      padding: theme.spacing(1) + 'px !important',
    },
    p_2: {
      padding: theme.spacing(2) + 'px !important',
    },
    px_1: {
      padding: theme.spacing(0, 1) + ' !important',
    },
    px_2: {
      padding: theme.spacing(0, 2) + ' !important',
    },
    pb_0: {
      paddingBottom: 0 + ' !important',
    },
    pt_0: {
      paddingTop: 0 + ' !important',
    },
    pointer: {
      cursor: 'pointer !important',
    },
    circularRadius: {
      borderRadius: 30,
    },
    primaryHover: {
      color: theme.palette.primary.main,
      '&:hover': {
        color: theme.palette.primary.dark,
      }
    },
    secondaryHover: {
      color: theme.palette.secondary.main,
      '&:hover': {
        color: theme.palette.secondary.dark,
      }
    },
    modalTitle: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeModalButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    tooltip: {
      backgroundColor: theme.palette.common.black,
    },
    tooltipArrow: {
      color: theme.palette.common.black,
    },
    button: {
      // borderRadius: 30,
      margin: theme.spacing(1, 0),
    },
    buttonWrapper: {
      position: 'relative',
    },
    buttonProgress: {
      color: theme.palette.success.main,
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
    scroll_auto: {
      overflow: 'auto',
    },
    centeredImageBackground: {
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
    },
    floatButton: {
      position: 'fixed',
      bottom: '3%',
      right: '3%'
    },
  }),
);

export const FancyTextInput = forwardRef<any, TextFieldProps>((props, ref) => (
  <TextField
    {...props}
    ref={ref}
    fullWidth
    margin="dense"
    variant="outlined"
  />
));

export const PasswordTextInput = forwardRef<any, TextFieldProps & { withoutIcon?: boolean }>((props, ref) => {
  const [showPassword, setShowPassword] = React.useState(false);
  const { withoutIcon, ...TextInputProps } = props;
  const label = TextInputProps["aria-label"] || TextInputProps.label;
  return (
    <FancyTextInput
      {...TextInputProps}
      ref={ref}
      type={showPassword ? 'text' : 'password'}
      InputProps={{
        startAdornment: !withoutIcon && <InputAdornment position="start"><LockIcon color="secondary" /></InputAdornment>,
        endAdornment:
          <InputAdornment position="end">
            <FancyTooltip title={!showPassword ? 'Mostrar contraseña' : 'Ocultar contraseña'}>
              <IconButton
                aria-label={showPassword ? 'Mostrar ' + label : 'Ocultar ' + label}
                onClick={() => setShowPassword(prev => !prev)}
                onMouseDown={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => event.preventDefault()}
              >
                {!showPassword ? <VisibilityIcon color="secondary" /> : <VisibilityOffIcon color="secondary" />}
              </IconButton>
            </FancyTooltip>
          </InputAdornment>
      }} />
  )
});

export const FancyButton = forwardRef(<C extends React.ElementType>(props: (ButtonProps<C, { component?: C }> & { loading?: boolean, square?: boolean }), ref: any) => {
  const classes = useFancyStyles();
  const { loading, square, ...buttonProps } = props;
  const progressLabel = buttonProps['aria-label'] || typeof buttonProps.children === 'string' ? buttonProps.children : undefined;
  return (
    <div className={classes.buttonWrapper}>
      <Button
        {...buttonProps}
        ref={ref}
        className={[!square ? classes.button : '', buttonProps.className].join(' ')}
      />
      {loading && <CircularProgress size={24} className={classes.buttonProgress} aria-label={progressLabel} />}
    </div>
  )
});

export const FancyImage = forwardRef((props: { image: string, position?: string, className?: string }, ref: any) => {
  const classes = useFancyStyles();
  return <div
    ref={ref}
    className={[classes.centeredImageBackground, props.className].join(' ')}
    style={{ backgroundImage: `url(${props.image})`, backgroundPosition: props.position || 'center' }}
  />
});

export const FancyModal = (props: DialogProps) => {
  const { onClose, children } = props;
  const classes = useFancyStyles();

  return (
    <Dialog {...props}>
      {onClose ? (
        <DialogTitle disableTypography className={classes.modalTitle}>
          <FancyTooltip title="Cerrar ventana">
            <IconButton
              size="small"
              aria-label="Cerrar ventana"
              className={classes.closeModalButton}
              onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => onClose(event, 'backdropClick')}
            >
              <CloseIcon />
            </IconButton>
          </FancyTooltip>
        </DialogTitle>
      ) : null}
      {children}
    </Dialog>
  )
};

export const FancyTooltip = (props: TooltipProps) => {
  const classes = useFancyStyles();

  return <Tooltip
    arrow
    enterDelay={1000}
    enterNextDelay={500}
    classes={{ tooltip: classes.tooltip, arrow: classes.tooltipArrow }}
    {...props}
  />;
}

export const FancyProgress = (props: CircularProgressProps & { containerClass?: string }) => {
  const classes = useFancyStyles();
  const { containerClass, ...progressProps } = props;
  return (
    <Grid container justify="center" alignItems="center" className={containerClass}>
      <Grid item className={classes.m_2}>
        <CircularProgress {...progressProps} />
      </Grid>
    </Grid>
  )
};

export const FancyTabs = withStyles((theme: Theme) => ({
  root: {
    borderBottom: '1px solid #e8e8e8',
  },
  indicator: {
    backgroundColor: theme.palette.primary.main,
  },
}))(Tabs);

export const FancyTab = withStyles((theme: Theme) => ({
  root: {
    textTransform: 'none',
    minWidth: 'auto !important',
    marginRight: theme.spacing(2),
    fontSize: theme.typography.body1.fontSize,
    fontWeight: theme.typography.fontWeightRegular,
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:hover': {
      color: theme.palette.primary.main,
      opacity: 1,
    },
    '&$selected': {
      fontWeight: theme.typography.fontWeightBold,
      color: theme.palette.primary.main,
    },
    '&:focus': {
      color: theme.palette.primary.main,
    },
  },
  selected: {},
}))((props: TabProps) => <Tab disableRipple {...props} />);

export const FancyDiv: FunctionComponent<{ color: string }> = (props) => {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      getColor: (props: { color: string }) => ({
        backgroundColor: props.color,
        color: theme.palette.getContrastText(props.color),
        padding: theme.spacing(0.5, 1),
      })
    }),
  );
  const classes = useStyles({ color: props.color });

  return <div className={classes.getColor}>
    <Typography>{props.children}</Typography>
  </div>
};

export const FancyDatePicker = forwardRef<any, DatePickerProps>((props, ref) => (
  <DatePicker
    {...props}
    ref={ref}
    toolbarTitle="Seleccione una fecha"
    clearText="Limpiar"
    cancelText="Cancelar"
    todayText="Hoy"
  />
));
