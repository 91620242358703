import faker from 'faker';
import MODULES from '../consts/Modules';
import PERMISSIONS from '../consts/Permissions';
import { Group, ListModule } from './Group';
import { Permission } from './Permission';

export class AuthUser {
  id: number = -1;
  idx: string = '';
  first_name: string = '';
  last_name: string = '';
  is_superuser: boolean = false;
  old_tasks: boolean = false;
  roles: Group[] = [];
}
export class RandomAuthUser implements AuthUser {
  id = faker.datatype.number(10000);
  idx = faker.datatype.string(5);
  first_name = faker.name.firstName();
  last_name = faker.name.lastName();
  is_superuser = true;
  old_tasks = faker.datatype.boolean();
  roles: Group[];

  constructor(init?: Partial<AuthUser>) {
    const permissions: Pick<Permission, 'id' | 'name'>[] = []
    for (const permission of Object.entries(PERMISSIONS)) {
      permissions.push({ id: permission[1], name: permission[0] });
    }
    const modules: ListModule[] = [];
    for (const module of Object.values(MODULES)) {
      modules.push({
        id: 1,
        name: module,
        permissions: permissions,
      })
    }
    this.roles = [{
      group: 'Admin',
      organization: { id: 1, name: 'Aggraria' },
      modules: modules,
    }];

    Object.assign(this, init);
  }
}

export class LoginBody {
  username: string = '';
  password: string = '';
}
export class RandomLoginBody extends LoginBody {
  username = faker.unique(faker.internet.email);
  password = faker.internet.password(8);
}

export interface LoginResponse {
  access: string;
  refresh: string;
}
export class RandomLoginResponse implements LoginResponse {
  access = faker.datatype.string(10);
  refresh = faker.datatype.string(10);

  constructor(init?: Partial<LoginResponse>) {
    Object.assign(this, init);
  }
}

export class RefreshBody {
  refresh: string = '';
}

export class RecoveryBody {
  email: string = '';
}
export class RandomRecoveryBody implements RecoveryBody {
  email = faker.internet.email();

  constructor(init?: Partial<RecoveryBody>) {
    Object.assign(this, init);
  }
}

export class RestoreBody {
  code: string = '';
  email: string = '';
  password: string = '';
  passwordConfirm: string = '';
}
export class RandomRestoreBody implements RestoreBody {
  code = faker.datatype.uuid();
  email = faker.internet.email();
  password: string;
  passwordConfirm: string;

  constructor(init?: Partial<RestoreBody>) {
    const password = faker.internet.password(8);
    this.password = password;
    this.passwordConfirm = password;

    Object.assign(this, init);
  }
}

export class ChangePasswordBody {
  old_password: string = '';
  password: string = '';
  confirmPassword: string = '';
}
