import faker from 'faker';

export interface PowerBi {
  embed_id: string;
  embed_url: string;
  token: string;
  token_type: 'embed';
}
export class RandomPowerBi implements PowerBi {
  embed_id = faker.datatype.uuid();
  embed_url = faker.internet.url();
  token = faker.datatype.string(50);
  token_type: 'embed' = 'embed';
}

export interface PowerBiParams {
  name: string;
}
