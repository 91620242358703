import React from "react";
import { Route, Redirect, RouteProps } from "react-router-dom";

export const PublicRoute = (props: RouteProps) => {
  return <React.Fragment>
    {
      !localStorage.getItem('token')
        ? <Route {...props} />
        // This redirect must be a route that any logged user has access,
        // that route must not be tied to any module or group
        : <Redirect to={{ pathname: "/process/erp", state: { from: props.location } }} />
    }
  </React.Fragment>
};
