import React, { useEffect, useState } from 'react'
// Material UI
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
// App
import { FancyButton, FancyProgress, useFancyStyles } from '../../common/FancyComponents';
import { CLIENT_GROUP, EMPLOYEE_GROUP } from '../../../consts/Groups';
// Services
import { googleCallback } from '../../../services/API/GoogleAccountService';
import { getAccounts } from '../../../services/API/BankingServices';
import hasGroup from '../../../services/hasGroup';
// Others
import axios, { CancelTokenSource } from 'axios';
import { Link, useHistory } from 'react-router-dom';

const useStyles = makeStyles(theme =>
  createStyles({
    progress: {
      height: '80vh',
    },
    iconSize: {
      fontSize: theme.spacing(10),
    },
  })
);

let source: CancelTokenSource = axios.CancelToken.source();

export default function GoogleAccountComponent() {
  const styles = useStyles();
  const classes = useFancyStyles();
  const history = useHistory();
  const params = new URLSearchParams(history.location.search);
  const code: string | null = params.get('code');
  const [loading, setLoading] = useState(true);
  const [accountsCount, setAccountsCount] = useState(0);

  useEffect(() => {
    source = axios.CancelToken.source();
    googleCallback(code, source)
      .then(() => {
        if (hasGroup(CLIENT_GROUP)) {
          getAccounts(source)
            .then(response => {
              setAccountsCount(response.length);
              setLoading(false);
              setTimeout(() => history.push(redirecTo(response.length)), 3000);
            })
            .catch(error => {
              if (!error.isCanceled) {
                setLoading(false);
                setTimeout(() => history.push(redirecTo()), 3000);
              }
            })
        } else {
          setLoading(false);
          setTimeout(() => history.push(redirecTo()), 3000);
        }
      });

      return () => {
        source.cancel();
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code]);

  function redirecTo(count?: number) {
    if (hasGroup(CLIENT_GROUP)) {
      if (count || accountsCount) {
        return '/process/banking/list';
      } else {
        return '/process/banking/register';
      }
    }
    if (hasGroup(EMPLOYEE_GROUP)) {
      return '/process/task';
    }
    return '/home';
  }

  return loading ? (
    <Grid container className={styles.progress}>
      <FancyProgress color="primary" size={150} />
    </Grid>
  ) : (
    <Grid container direction="column" alignItems="center" spacing={3} className={classes.m_2}>
      <Grid item>
        <VerifiedUserIcon className={styles.iconSize} color="primary" />
      </Grid>
      <Grid item>
        <Typography variant="h4" align="center">
          La conexión con su cuenta de google se ha realizado de manera exitosa.
        </Typography>
        <Typography variant="h6" align="center">
          Se le redireccionará de forma automática, por favor espere.
        </Typography>
      </Grid>
      <Grid item>
        <FancyButton color="primary" variant="outlined" component={Link} to={redirecTo()}>
          Ir al portal
        </FancyButton>
      </Grid>
    </Grid>
  )
}