import axios, { CancelTokenSource } from 'axios';
// Redux
import store from '../../store';
import { showSnackbar } from '../../store/reducers/alertReducer';
// Types
import { Params/*, ListResponse*/ } from '../../types/Api';
import { Organization, RandomOrganization, OrganizationBody } from '../../types/Organization';
// App
import { MAKE_API_REQUEST, USERMANAGEMENT_SERVICE } from '../../consts/App';
import getListData from '../getListData';

const { dispatch } = store;
const defaultConfig = {
  baseURL: USERMANAGEMENT_SERVICE,
}

// export async function getOrganizations(source: CancelTokenSource, params?: { paginate?: true } & Params): Promise<ListResponse<Organization>>;
// export async function getOrganizations(source: CancelTokenSource, params: { paginate: false } & Params): Promise<Organization[]>;
// export async function getOrganizations(source: CancelTokenSource, params: Params): Promise<Organization[] | ListResponse<Organization>>;
export async function getOrganizations(source: CancelTokenSource, params?: Params) {
  if (MAKE_API_REQUEST) {
    return axios.get<Organization[]>('organizations/', { ...defaultConfig, params: params, cancelToken: source.token })
      .then(response => response.data);
  } else {
    const count = params?.limit || 10;
    // const response = getListData<Organization>(count, () => new RandomOrganization(), params?.paginate === false);
    const response = getListData<Organization>(count, () => new RandomOrganization(), true);
    return Promise.resolve(response);
  }
}

export async function registerOrganization(data: OrganizationBody, source: CancelTokenSource) {
  if (MAKE_API_REQUEST) {
    return axios.post<Organization>('organizations/', data, { ...defaultConfig, cancelToken: source.token })
      .then(response => {
        dispatch(showSnackbar({ message: 'Registro exitoso', type: 'success' }));
        return response.data;
      });
  } else {
    dispatch(showSnackbar({ message: 'Registro exitoso', type: 'success' }));
    return Promise.resolve(new RandomOrganization() as Organization);
  }
};
