import React, { useEffect, useState } from 'react';
// Material UI
import { makeStyles, createStyles } from '@material-ui/core/styles';
import DialogContent from '@material-ui/core/DialogContent';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
// Services
import { getAccounts, updateAccount } from '../../../services/API/BankingServices';
import { onMessage } from '../../../services/API/SocketServices';
// Types
import ListComponentProps from '../../../types/propTypes/ListComponent';
import { Account } from '../../../types/Banking';
import { Notification } from '../../../types/Notification';
// App
import { FancyModal, FancyProgress } from '../../common/FancyComponents';
import FancyList, { ActionButton, FancyItemText } from '../../common/FancyList';
import BankAccountFormComponent from './BankAccountFormComponent';
// Redux
import { useAppDispatch, useAppSelector } from '../../../consts/ReduxHooks';
import { dismissDialogEdit, showDialogEdit } from '../../../store/reducers/dialogReducer';
// Other
import axios, { CancelTokenSource } from 'axios';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(theme =>
  createStyles({
    button: {
      position: 'fixed',
      bottom: '3%',
      right: '3%'
    },
    error: {
      color: theme.palette.error.main,
    },
    success: {
      color: theme.palette.success.main,
    },
  })
);

const source: CancelTokenSource[] = new Array(2).fill(axios.CancelToken.source());

export default function BankAccountListComponent(props: ListComponentProps) {
  const styles = useStyles();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const dialog = useAppSelector(state => state.dialog);
  const user = useAppSelector(state => state.auth.user);
  const [accounts, setAccounts] = useState<Account[]>([]);
  const [selectedAccount, setSelectedAccount] = useState<Account | null>(null);
  // const [page, setPage] = useState(0);
  // const [hasMore, setHasMore] = useState(false);
  const [loading, setLoading] = useState(false);
  const [busy, setBusy] = useState(false);
  // const limit = 30;
  const actionButtons: ActionButton<Account>[] = [
    {
      text: 'Editar',
      icon: <EditIcon />,
      onClick: (event, account) => handleOptions('edit', account, event),
      disabled: busy,
      show: account => !props.hideEdit && account.owner
    },
    {
      text: account => account.active ? 'Desactivar' : 'Activar',
      icon: account => account.active
        ? <NotInterestedIcon color="error" />
        : <CheckCircleOutlineIcon className={styles.success} />,
      onClick: (event, account) => handleOptions('statusChange', account, event),
      disabled: busy,
    },
  ];

  async function fetchAccounts(reset: boolean = false) {
    // if (reset) {
      setLoading(true);
    // }
    // const newPage = reset ? 0 : page;
    const params = {
      // limit: limit,
      // offset: newPage * limit,
      customer: user.idx,
    }
    source[0] = axios.CancelToken.source();
    getAccounts(source[0], params)
      .then(response => {
        setAccounts(response);
        // setPage(prev => reset ? 1 : prev + 1);
        // setHasMore(!!response.next);
        setLoading(false);
      })
      .catch(error => {
        if (!error.isCanceled) {
          setAccounts([]);
          // setPage(0);
          // setHasMore(false);
          setLoading(false);
        }
      });
  };

  useEffect(() => {
    source[1] = axios.CancelToken.source();
    fetchAccounts(true);
    const subscription = onMessage<Notification>('notifications')
      .subscribe(data => {
        if (data.status === 'SUCCESS' && data.description === 'Recepción de datos exitosa.') {
          fetchAccounts(true);
        }
      });
    return () => {
      source.forEach(s => s.cancel());
      subscription.unsubscribe();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClickOpen = () => {
    history.push('/process/banking/register');
  };

  const handleClose = () => {
    dispatch(dismissDialogEdit());
  };

  const handleSubmit = () => {
    fetchAccounts(true);
    handleClose();
  };

  const handleOptions = (option: 'edit' | 'statusChange', account: Account, event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation();
    switch (option) {
      case 'edit':
        setSelectedAccount(account);
        dispatch(showDialogEdit());
        break;
      case 'statusChange':
        setBusy(true);
        updateAccount(account.account_number, { active: !account.active }, source[1])
          .then(() => {
            fetchAccounts(true);
            setBusy(false);
          })
          .catch(error => {
            if (!error.isCanceled) {
              setBusy(false);
            }
          })
        break;
    }
  };

  return (
    <React.Fragment>
      {
        !props.hideList && <React.Fragment>
          {
            !loading && <FancyList<Account>
              // infinite
              divider
              elements={accounts}
              // hasMore={hasMore}
              // fetchElements={fetchAccounts}
              emptyMessage="No se encontraron cuentas asociadas a su usuario, si ya agrego cuentas espere 2 minutos y vuelva a cargar la lista"
              idKey="account_number"
              ListItemIconProps={account => ({
                // className: account.active ? styles.success : styles.error,
                children: <AccountBalanceIcon fontSize="large" />
              })}
              ListProps={{ "aria-label": "Cuentas" }}
              ListItemProps={(account: Account) => ({
                onClick: (!account.active || busy)
                  ? undefined
                  : () => history.push(`/process/banking/dashboard`),
              })}
              ListItemTextProps={account => ({
                primary: <FancyItemText textArray={[
                  account.name,
                  account.active ? 'Cuenta activa' : 'Cuenta inactiva',
                ]} />,
                secondary: <FancyItemText textArray={[
                  account.bank.name,
                  account.account_number
                ]} />,
              })}
              actionButtons={actionButtons}
            />
          }
          {loading && <FancyProgress aria-label="Lista de cuentas" color="primary" size={100} />}
        </React.Fragment>
      }
      {
        !props.hideCreate && <Fab
          color="primary"
          aria-label="Agregar"
          className={styles.button}
          onClick={handleClickOpen}
        >
          <AddIcon />
        </Fab>
      }
      <FancyModal
        open={dialog.openEdit}
        onClose={handleClose}
        aria-label="Formulario de modulo"
      >
        <DialogContent>
          {
            selectedAccount && <BankAccountFormComponent
              onSubmit={handleSubmit}
              data={selectedAccount}
            />
          }
        </DialogContent>
      </FancyModal>
    </React.Fragment>
  );
}
