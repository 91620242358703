import React, { useEffect, useState } from 'react';
// Material UI
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import DialogContent from '@material-ui/core/DialogContent';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
// Services
import { getModules } from '../../../services/API/ModuleServices';
// import { getOrganizations } from '../../../services/API/OrganizationServices';
// import { getPermissions } from '../../../services/API/PermissionServices';
// Types
import ListComponentProps from '../../../types/propTypes/ListComponent';
import { Module } from '../../../types/Module';
// import { Organization } from '../../../types/Organization';
// import { Permission } from '../../../types/Permission';
// Redux
import { useAppDispatch, useAppSelector } from '../../../consts/ReduxHooks';
import { ModuleFilters } from '../../../types/redux/filtersTypes';
import { dismissDialog, dismissDialogEdit, showDialog, showDialogEdit } from '../../../store/reducers/dialogReducer';
// App
import { FancyModal, FancyProgress } from '../../common/FancyComponents';
import FancyList, { ActionButton } from '../../common/FancyList';
// import FancyFilter, { FilterElement } from '../../common/FancyFilter';
import ModuleFormComponent from './ModuleFormComponent';
// Other
import axios, { CancelTokenSource } from 'axios';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      position: 'fixed',
      bottom: '3%',
      right: '3%'
    },
  })
);

const source: CancelTokenSource[] = new Array(2).fill(axios.CancelToken.source());

export default function ModuleListComponent(props: ListComponentProps) {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const dialog = useAppSelector(state => state.dialog);
  const filters = useAppSelector(state => state.filters);
  const [modules, setModules] = useState<Module[]>([]);
  const [selectedModule, setSelectedModule] = useState<Module | null>(null);
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(false);
  const [loading, setLoading] = useState(false);
  // const [organizations, setOrganizations] = useState<Organization[]>([]);
  // const [loadingOrganizations, setLoadingOrganizations] = useState(false);
  // const [permissions, setPermissions] = useState<Permission[]>([]);
  // const [loadingPermissions, setLoadingPermissions] = useState(false);
  const limit = 30;
  const actionButtons: ActionButton<Module>[] = [
    {
      text: 'Editar',
      icon: <EditIcon />,
      onClick: (event, module) => handleOptions('edit', module, event),
      show: !props.hideEdit,
    },
  ];
  // const filterElements: FilterElement[] = [
  //   {
  //     type: 'Autocomplete',
  //     key: 'organization',
  //     name: 'Organización',
  //     autocompleteOptions: organizations.map(el => ({ value: el.id, name: el.organization })),
  //     loading: loadingOrganizations,
  //   },
  //   {
  //     type: 'Autocomplete',
  //     key: 'permission',
  //     name: 'Permiso',
  //     autocompleteOptions: permissions.map(el => ({ value: el.id, name: el.name })),
  //     loading: loadingPermissions,
  //   },
  // ];

  async function fetchModules(reset: boolean = false) {
    if (!props.hideList && filters && filters.id === new ModuleFilters().id) {
      if (reset) {
        setLoading(true);
      }
      const newPage = reset ? 0 : page;
      const params = {
        ...filters,
        limit: limit,
        offset: newPage * limit,
      }
      source[0] = axios.CancelToken.source();
      getModules(source[0], params)
        .then(response => {
          setModules(response);
          // setModules(prev => reset ? response.results : prev.concat(response.results));
          setPage(prev => reset ? 1 : prev + 1);
          // setHasMore(!!response.next);
          setLoading(false);
        })
        .catch(error => {
          if (!error.isCanceled) {
            setModules([]);
            setPage(0);
            setHasMore(false);
            setLoading(false);
          }
        });
    }
  };

  // async function fetchOrganizations() {
  //   setLoadingOrganizations(true);
  //   const params = {
  //     paginate: false as false,
  //   }
  //   getOrganizations(source[1], params)
  //     .then(response => {
  //       setOrganizations(response);
  //       setLoadingOrganizations(false);
  //     })
  //     .catch((error) => {
  //       if (!error.isCanceled) {
  //         setLoadingOrganizations(false);
  //       }
  //     });
  // };

  // async function fetchPermissions() {
  //   setLoadingPermissions(true);
  //   const params = {
  //     paginate: false as false,
  //   }
  //   getPermissions(source[1], params)
  //     .then(response => {
  //       setPermissions(response);
  //       setLoadingPermissions(false);
  //     })
  //     .catch((error) => {
  //       if (!error.isCanceled) {
  //         setLoadingPermissions(false);
  //       }
  //     });
  // };

  useEffect(() => {
    source[1] = axios.CancelToken.source();
    // if (!props.hideList) {
    //   fetchOrganizations();
    //   fetchPermissions();
    // }
    return () => {
      source.forEach(s => s.cancel());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchModules(true);
    return () => {
      source[0].cancel();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const handleClickOpen = () => {
    dispatch(showDialog());
  };

  const handleClose = () => {
    dispatch(dismissDialog());
    dispatch(dismissDialogEdit());
  };

  const handleSubmit = () => {
    fetchModules(true);
    handleClose();
  };

  const handleOptions = (option: 'edit', mod: Module, event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation();
    switch (option) {
      case 'edit':
        setSelectedModule(mod);
        dispatch(showDialogEdit());
        break;
    }
  };

  const ExpandContent = (props: { module: Module }) => (
    <Grid container spacing={1}>
      <Grid item xs={12} sm={6}>
        <Typography variant="subtitle2"><strong>Permisos</strong></Typography>
        <Typography variant="body2">
          {props.module.permissions.map(p => p.name).join(', ')}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Typography variant="subtitle2"><strong>Organizaciones</strong></Typography>
        <Typography variant="body2">
          {props.module.organizations.map(p => p.name).join(', ')}
        </Typography>
      </Grid>
    </Grid>
  );

  return (
    <React.Fragment>
      {
        !props.hideList && <React.Fragment>
          {/* {
            filters && <FancyFilter
              filterButtonVariant="noColor"
              submitFilterButtonProps={{ variant: 'outlined' }}
              defaultValues={new ModuleFilters()}
              elements={filterElements}
              formProps={{ "aria-label": 'Filtros lista de modulos' }}
            />
          } */}
          {
            !loading && <FancyList<Module>
              infinite
              expand
              elements={modules}
              hasMore={hasMore}
              fetchElements={fetchModules}
              ListProps={{ "aria-label": "Modulos" }}
              ListItemTextProps={mod => ({
                primary: mod.name,
                secondary: mod.description
              })}
              actionButtons={actionButtons}
              expandContent={mod => <ExpandContent module={mod} />}
            />
          }
          {loading && <FancyProgress aria-label="Lista de modulos" color="primary" size={100} />}
        </React.Fragment>
      }
      {
        !props.hideCreate && <Fab
          color="primary"
          aria-label="Agregar"
          className={classes.button}
          onClick={handleClickOpen}
        >
          <AddIcon />
        </Fab>
      }
      <FancyModal
        open={dialog.open || dialog.openEdit}
        onClose={handleClose}
        aria-label="Formulario de modulo"
      >
        <DialogContent>
          <ModuleFormComponent
            onSubmit={handleSubmit}
            data={dialog.openEdit ? selectedModule : null}
          />
        </DialogContent>
      </FancyModal>
    </React.Fragment>
  );
}
