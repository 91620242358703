import React, { useState, useEffect } from 'react';
// Material UI
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
// Forms
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers';
// App
import { FancyTextInput, FancyButton, PasswordTextInput } from '../../common/FancyComponents';
import { ORGANIZATION_ID } from '../../../consts/App';
import { CLIENT_GROUP } from '../../../consts/Groups';
// Services
import { updateUser, registerUser, registerUserGroup } from '../../../services/API/UserServices';
// Types
import { GeneralFormProps } from '../../../types/propTypes/misc';
import { UserForm, User } from '../../../types/User';
// Others
import axios, { CancelTokenSource } from 'axios';

const schema = yup.object().shape({
  fullName: yup.string().lettersOnly(),
  lastName: yup.string().lettersOnly(),
  mail: yup.string().required().email(),
  password: yup.string().required(),
  passwordConfirm: yup.string().oneOf([yup.ref('password')], 'Las contraseñas deben coincidir'),
});

let source: CancelTokenSource = axios.CancelToken.source();

export default function UserFormComponent(props: GeneralFormProps<User> & { isClient?: boolean }) {
  const matchesXXS = useMediaQuery('(max-width: 336px)');
  const methods = useForm<UserForm>({
    resolver: yupResolver(schema),
    defaultValues: new UserForm(),
  });
  const { handleSubmit, control, errors, reset } = methods;
  const [busy, setBusy] = useState(false);
  const isEdit = !!props.data;

  useEffect(() => {
    source = axios.CancelToken.source();
    if (isEdit && props.data) {
      reset({
        mail: props.data.mail,
        fullName: props.data.fullName,
        lastName: props.data.lastName,
        password: '',
        passwordConfirm: '',
      });
    }
    return () => {
      source.cancel();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSuccessSubmit = (response: User) => {
    setBusy(false);
    if (props.onSubmit) {
      props.onSubmit(response);
    }
  }

  const onSubmit = (data: UserForm) => {
    setBusy(true);
    const dataBody = {
      mail: data.mail,
      fullName: data.fullName,
      lastName: data.lastName,
      password: data.password,
    };
    const service = isEdit ? updateUser(props.data?.mail || '', dataBody, source)
      : registerUser(dataBody, source);
    service
      .then(response => {
        // Add client group to user
        if (props.isClient && !isEdit) {
          const groupData = {
            user: response.mail,
            organization: ORGANIZATION_ID,
            groups: [CLIENT_GROUP],
          };
          registerUserGroup(groupData, source)
          .then(res => handleSuccessSubmit(response))
          .catch(error => {
            if (!error.isCanceled) {
              setBusy(false);
            }
          });
        } else {
          handleSuccessSubmit(response);
        }
      })
      .catch(error => {
        if (!error.isCanceled) {
          setBusy(false);
        }
      });
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} aria-label="Usuario">
      <Grid container direction="row" justify="center" spacing={1}>
        <Grid item xs={12}>
          <Typography variant="h6" align="center">Información Personal</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            render={props => <FancyTextInput
              {...props}
              error={!!errors.fullName}
              helperText={errors.fullName && errors.fullName.message}
              label="Nombre"
              id="userForm_fullName"
            />}
            control={control}
            name="fullName"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            render={props => <FancyTextInput
              {...props}
              error={!!errors.lastName}
              helperText={errors.lastName && errors.lastName.message}
              label="Apellido"
              id="userForm_lastName"
            />}
            control={control}
            name="lastName"
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            render={props => <FancyTextInput
              {...props}
              error={!!errors.mail}
              helperText={errors.mail && errors.mail.message}
              type="email"
              label="Correo"
              id="userForm_email"
            />}
            control={control}
            name="mail"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            render={props => <PasswordTextInput
              {...props}
              error={!!errors.password}
              helperText={errors.password && errors.password.message}
              autoComplete="current-password"
              label="Contraseña"
              id="userForm_password"
              withoutIcon
            />}
            control={control}
            name="password"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            render={props => <PasswordTextInput
              {...props}
              InputLabelProps={{ shrink: matchesXXS ? true : undefined }}
              error={!!errors.passwordConfirm}
              helperText={errors.passwordConfirm && errors.passwordConfirm.message}
              label="Confirmar contraseña"
              placeholder="Repita su contraseña"
              id="userForm_passwordConfirm"
              withoutIcon
            />}
            control={control}
            name="passwordConfirm"
          />
        </Grid>
        <Grid item xs={12} container direction="row" justify="center">
          <FancyButton
            variant="contained"
            color='primary'
            type="submit"
            disabled={busy}
            loading={busy}
          >
            {isEdit ? 'Guardar' : 'Crear'}
          </FancyButton>
        </Grid>
      </Grid>
    </form>
  );
}
