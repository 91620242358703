import React, { useEffect, useState } from 'react';
// Material UI
import Typography from '@material-ui/core/Typography';
// Services
import { getFitnanceReader, getWidget } from '../../../services/API/BankingServices';
// App
import { FancyProgress } from '../../common/FancyComponents';
import IframeComponent from '../../common/IframeComponent';
// Redux
import { useAppDispatch, useAppSelector } from '../../../consts/ReduxHooks';
import { showSnackbar } from '../../../store/reducers/alertReducer';
// Other
import axios, { CancelTokenSource } from 'axios';
import { useHistory } from 'react-router-dom';

let source: CancelTokenSource = axios.CancelToken.source();

export default function RegisterAccountComponent() {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const user = useAppSelector(state => state.auth.user)
  const [loading, setLoading] = useState(false);
  const [url, setUrl] = useState<null | string>(null);
  const [messages, setMessages] = useState<string[]>([]);
  const messagesRef = React.useRef(messages);

  async function fetchWidget() {
    setMessages([]);
    setLoading(true);
    getWidget(user.idx, source)
      .then(response => {
        setUrl(response.url_widget);
        setLoading(false);
      })
      .catch(error => {
        if (!error.isCanceled) {
          setLoading(false);
        }
      });
  };

  useEffect(() => {
    source = axios.CancelToken.source();
    window.addEventListener('message', onMessageReceived, false);
    fetchWidget();
    return () => {
      source.cancel();
      window.removeEventListener('message', onMessageReceived, false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    messagesRef.current = messages;
  }, [messages]);

  const onMessageReceived = (event: MessageEvent) => {
    if (typeof event.data === 'string' && event.data.includes('unnax')) {
      console.log(event.data);
      console.log(messagesRef.current);
      setMessages(prev => prev.concat([event.data]));
      switch (event.data) {
        case 'unnax:aggregation:done':
          if (messagesRef.current.includes('unnax:bank_login:done')) {
            dispatch(showSnackbar({
              message: 'En breve sera redireccionado, por favor espere',
              type: 'success',
            }))
            getFitnanceReader(user.idx, source).then(() => {
              history.push('/process/banking/list');
            })
          } else {
            dispatch(showSnackbar({
              message: 'El proceso del widget finalizó correctamente, sin embargo no tiene ninguna cuenta agregada, debe iniciar el proceso nuevamente, se procederá a recargar el widget, por favor espere',
              type: 'error',
            }));
            fetchWidget();
          }
          break;
        case 'unnax:aggregation:fail':
          dispatch(showSnackbar({
            message: 'Error inesperado en el widget, se recargará uno nuevo, por favor espere',
            type: 'error',
          }))
          fetchWidget();
          break
        case 'unnax:bank_login:fail':
          dispatch(showSnackbar({
            message: 'Existe un problema con las credenciales de su banco, por favor comuníquese con su entidad bancaria',
            type: 'error',
          }))
          break
      }
    }
  }

  return (<React.Fragment>
    {
      !loading && (
        url
          ? <IframeComponent hasTitle title="Registro de cuenta" src={url} />
          : <Typography>Error al descargar el widget</Typography>
      )
    }
    {loading && <FancyProgress aria-label="Registro de cuenta" color="primary" size={100} />}
  </React.Fragment>
  );
}
