import React from 'react';
// App
import DashboardComponent from '../../../layouts/DashboardComponent';
import PowerBIComponent from '../../../views/PowerBI/PowerBIComponent';
// Other
import { Switch, Route, RouteComponentProps, Redirect } from 'react-router-dom';
import hasModule from '../../../../services/hasModule';
import MODULES from '../../../../consts/Modules';

export default function PowerBIRouting(props: RouteComponentProps) {
  return (
    <DashboardComponent
      children={<Switch>
        {
          hasModule(MODULES.bi_geofolia) && <Route
            path={props.match.path + '/geofolia'}
            render={() => <PowerBIComponent path="geofolia" />}
          />
        }
        {
          hasModule(MODULES.bi_nickel) && <Route
            path={props.match.path + '/nickel'}
            render={() => <PowerBIComponent path="nickel" />}
          />
        }
        {
          hasModule(MODULES.bi_alfalite) && <Route
            path={props.match.path + '/alfalite'}
            render={() => <PowerBIComponent path="alfalite" />}
          />
        }
        <Redirect to={'/'} />
      </Switch>}
    />
  );
}
