import faker from 'faker';

export interface Organization {
  id: number;
  name: string;
}
export class RandomOrganization implements Organization {
  id = faker.unique(faker.datatype.number);
  name = faker.company.companyName(1);

  constructor(init?: Partial<Organization>) {
    Object.assign(this, init);
  }
}

export class OrganizationBody {
  name: string = '';
}
export class RandomOrganizationBody implements OrganizationBody {
  name = faker.company.companyName(1);

  constructor(init?: Partial<OrganizationBody>) {
    Object.assign(this, init);
  }
}