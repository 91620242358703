import { combineReducers, createStore } from '@reduxjs/toolkit';
// Reducers
import authReducer from "./reducers/authReducer";
import alertReducer from "./reducers/alertReducer";
import dialogReducer from "./reducers/dialogReducer";
import sidebarReducer from "./reducers/sidebarReducer";
import diagramsReducer from "./reducers/diagramsReducer";
import filtersReducer from "./reducers/filtersReducer";

export const rootReducers = combineReducers({
  auth: authReducer,
  alert: alertReducer,
  dialog: dialogReducer,
  sidebar: sidebarReducer,
  diagram: diagramsReducer,
  filters: filtersReducer,
});
const store = createStore(rootReducers);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export default store;
