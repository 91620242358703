import React, { useEffect } from "react";
// Material Ui
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
// Redux
import { useAppDispatch, useAppSelector } from "../../consts/ReduxHooks";
import { setAuthenticatedLoading, setAuthUser } from "../../store/reducers/authReducer";
import { showSnackbar } from "../../store/reducers/alertReducer";
// Services
import { authenticated, logout } from "../../services/API/AuthServices";
import { connectSocket, disconnectSocket } from "../../services/API/SocketServices";
// App
import { FancyButton, FancyProgress } from "../common/FancyComponents";
// Others
import { Route, RouteProps, Redirect, useHistory } from "react-router-dom";
import axios, { CancelTokenSource } from "axios";

const useStyles = makeStyles(theme =>
  createStyles({
    progress: {
      height: '100vh',
    },
  })
);

let source: CancelTokenSource = axios.CancelToken.source();

export const ProtectedRoute = (props: RouteProps) => {
  const styles = useStyles();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const user = useAppSelector(state => state.auth.user);
  const loading = useAppSelector(state => state.auth.authenticatedLoading);

  useEffect(() => {
    source = axios.CancelToken.source();
    if (localStorage.getItem('token')) {
      authenticated(source)
      .then(data => {
        connectSocket(data.idx);
        setTimeout(() => {
          dispatch(setAuthUser(data));
          dispatch(setAuthenticatedLoading(false));
          if (data.old_tasks) {
            setTimeout(() => {
              dispatch(showSnackbar({ message: 'Tiene tareas pendientes', type: 'warning' }));
            }, 2000);
          }
        }, 2000);
      })
    }
    return () => {
      source.cancel();
      disconnectSocket();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLogout = () => {
    logout()
      .then(() => {
        dispatch(showSnackbar({ message: 'Ha cerrado sesión exitosamente', type: 'success' }));
        history.push('/');
      });
  }

  const ErrorMessage = <Grid container direction="column" alignItems="center">
    <Grid item>
      <Typography variant="h3" align="center">
        Hubo un error al tratar de autenticar al usuario
      </Typography>
    </Grid>
    <Grid item>
      <FancyButton
        variant="contained"
        color="primary"
        onClick={handleLogout}
      >
        Cerrar Sesión
      </FancyButton>
    </Grid>
  </Grid>;

  return <React.Fragment>
    {
      localStorage.getItem('token')
        ? (
          // check if there is an authenticated user
          user.id >= 0
            ? <Route {...props} />
            : (
              // check if authenticated request is loading
              loading
                ? <FancyProgress size={150} containerClass={styles.progress} />
                : ErrorMessage
            )
        )
        : <Redirect to="/" />
    }
  </React.Fragment >
};
