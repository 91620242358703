import axios, { CancelTokenSource } from 'axios';
// Redux
import store from '../../store';
import { showSnackbar } from '../../store/reducers/alertReducer';
// Types
// import { ListResponse } from '../../types/Api';
import { APIGroup, Group, RandomGroup, GroupBody, GroupParams, ListModule } from '../../types/Group';
// App
import { MAKE_API_REQUEST, USERMANAGEMENT_SERVICE } from '../../consts/App';
import getListData from '../getListData';

const { dispatch } = store;
const defaultConfig = {
  baseURL: USERMANAGEMENT_SERVICE,
}

const parseGroups = (apiGroups: APIGroup[]): Group[] => {
  const groups: Group[] = [];
  for (const apiGroup of apiGroups) {
    if (!groups.find(group => group.group === apiGroup.group)) {
      const modules: ListModule[] = [];
      for (const group of apiGroups.filter(g => g.group === apiGroup.group)) {
        const index = modules.findIndex(module => module.id === group.module.id)
        if (index > -1) {
          modules[index].permissions.push(group.permission);
        } else {
          modules.push({
            ...group.module,
            permissions: [group.permission],
          });
        }
      }
      groups.push({
        group: apiGroup.group,
        organization: apiGroup.organization,
        modules: modules,
      })
    }
  }
  return groups;
}

// export async function getGroups(source: CancelTokenSource, params?: { paginate?: true } & GroupParams): Promise<ListResponse<Group>>;
// export async function getGroups(source: CancelTokenSource, params: { paginate: false } & GroupParams): Promise<Group[]>;
// export async function getGroups(source: CancelTokenSource, params: GroupParams): Promise<Group[] | ListResponse<Group>>;
// export async function getGroups(source: CancelTokenSource, params?: GroupParams) {
//   if (MAKE_API_REQUEST) {
//     return axios.get<APIGroup[]>('groups/', { ...defaultConfig, params: params, cancelToken: source.token })
//       .then(response => parseGroups(response.data));
//   } else {
//     const count = 10;
//     // const count = params?.limit || 10;
//     const response = getListData<Group>(count, () => new RandomGroup(), true);
//     // const response = getListData<Group>(count, () => new RandomGroup(), params?.paginate === false);
//     return Promise.resolve(response);
//   }
// }
export async function getGroups(source: CancelTokenSource, params: GroupParams) {
  if (MAKE_API_REQUEST) {
    const endpoint = ('uid' in params && params.uid)
      ? 'groupsforuid'
      : (
        ('groups' in params && params.groups)
          ? 'groupsforgroups'
          : 'groups'
      );
    return axios.get<APIGroup[]>(`${endpoint}/`, { ...defaultConfig, params: params, cancelToken: source.token })
      .then(response => parseGroups(response.data));
  } else {
    const count = 10;
    const response = getListData<Group>(count, () => new RandomGroup(), true);
    return Promise.resolve(response);
  }
}

export async function registerGroup(data: GroupBody, source: CancelTokenSource) {
  if (MAKE_API_REQUEST) {
    return axios.post<APIGroup[]>('groups/', data, { ...defaultConfig, cancelToken: source.token })
      .then(response => {
        dispatch(showSnackbar({ message: 'Registro exitoso', type: 'success' }));
        return parseGroups(response.data)[0];
      });
  } else {
    dispatch(showSnackbar({ message: 'Registro exitoso', type: 'success' }));
    return Promise.resolve(new RandomGroup() as Group);
  }
};

export async function updateGroup(name: string, data: GroupBody, source: CancelTokenSource) {
  if (MAKE_API_REQUEST) {
    return axios.put<APIGroup[]>(`groups/${name}/`, data, { ...defaultConfig, cancelToken: source.token })
      .then(response => {
        dispatch(showSnackbar({ message: 'Se actualizó la información con éxito', type: 'success' }));
        return parseGroups(response.data)[0];
      });
  } else {
    dispatch(showSnackbar({ message: 'Se actualizó la información con éxito', type: 'success' }));
    return Promise.resolve(new RandomGroup() as Group);
  }
};