import faker from 'faker';

export interface Permission {
  id: number;
  name: string;
  description: string;
}
export class RandomPermission implements Permission {
  id = faker.unique(faker.datatype.number);
  name = faker.name.title();
  description = faker.lorem.sentences(2);

  constructor(init?: Partial<Permission>) {
    Object.assign(this, init);
  }
}

export class PermissionBody {
  name = '';
  description = '';
}
export class RandomPermissionBody {
  name = faker.name.title();
  description = faker.lorem.sentences(2);

  constructor(init?: Partial<PermissionBody>) {
    Object.assign(this, init);
  }
}