import React from 'react';
// App
import DashboardComponent from '../../layouts/DashboardComponent';
import BusinessListComponent from '../../views/Business/BusinessListComponent';
import MODULES from '../../../consts/Modules';
import PERMISSIONS from '../../../consts/Permissions';
// Services
import hasModule from '../../../services/hasModule';
// Other
import { Switch, Route, Redirect, RouteComponentProps } from 'react-router-dom';

export default function BusinessRouting(props: RouteComponentProps) {
  return (
    <DashboardComponent
      title="Empresas"
      children={<Switch>
        <Route exact path={props.match.path + '/list'} render={props => <BusinessListComponent
          hideList={!hasModule(MODULES.business, PERMISSIONS.list)}
          hideCreate={!hasModule(MODULES.business, PERMISSIONS.create)}
          hideEdit={!hasModule(MODULES.business, PERMISSIONS.update)}
        />} />

        <Redirect to={props.match.path + '/list'} />
      </Switch>}
    />
  );
}