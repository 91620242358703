import faker from 'faker';

export interface Catalog {
  name: string;
  shortName: string;
  fields: string[];
}
export class RandomCatalog implements Catalog {
  name = faker.unique(faker.name.title);
  shortName = faker.name.prefix();
  fields: string[] = new Array(4).fill(true).map(() => faker.hacker.verb());

  constructor(init?: Partial<Catalog>) {
    Object.assign(this, init);
  }
}