import faker from 'faker';

export interface User {
  fullName: string;
  lastName: string;
  mail: string;
}
export class RandomUser implements User {
  fullName = faker.name.firstName();
  lastName = faker.name.lastName();
  mail = faker.unique(faker.internet.email);

  constructor(init?: Partial<User>) {
    Object.assign(this, init);
  }
}

export class UserForm {
  fullName: string = '';
  lastName: string = '';
  mail: string = '';
  password: string = '';
  passwordConfirm: string = '';
}

export interface UserBody {
  fullName: string;
  lastName: string;
  mail?: string;
  password: string;
}
export class RandomUserBody implements UserBody {
  fullName = faker.name.firstName();
  lastName = faker.name.lastName();
  mail = faker.internet.email();
  password = faker.internet.password(8);

  constructor(init?: Partial<UserBody>) {
    Object.assign(this, init);
  }
}

export interface UserGroupBody {
  user: string;
  organization: number;
  groups: string[];
}
