import faker from 'faker';

export class DiagramsBpmn {
  name: string = '';
  id: string = '';
  mimeType: string = '';
  originalFilename: string = '';
}
export class RandomDiagramsBpmn extends DiagramsBpmn {
  id = faker.unique(faker.datatype.string);
  name = faker.datatype.number(10).toFixed(1) + ' ' + faker.system.fileName();
  mimeType = faker.system.mimeType();
  originalFilename = faker.system.commonFileName();

  constructor(init?: Partial<DiagramsBpmn>) {
    super();
    Object.assign(this, init);
  }
}
export interface DiagramsBpmnList {
  name: string;
  id: string;
  mimeType: string;
  originalFilename: string;
  level: number;
  number: string;
  children?: DiagramsBpmnList[];
}

export interface AuthenticationResponse {
  url: string;
}
export class RandomAuthenticationResponse {
  url = faker.internet.url();
}

export interface FilesResponse {
  files: DiagramsBpmn[];
}

const getNumbers = (number: number) => {
  let n = '';
  for (let i = 0; i < number; i++) {
    n = n + (i + 1) + '.';
  }
  return n;
}

export class RandomFilesResponse implements FilesResponse {
  files = new Array(faker.datatype.number(5)).fill(true).map((el, index) => new RandomDiagramsBpmn({ name: getNumbers(index + 1) + ' archivo' }));
}

export interface DiagramsBpmnParams {
  file?: string;
  mime?: string;
  filename?: string;
  parent?: string
}
