import React, { useRef } from 'react';
// Material UI
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
// App
import { MENU } from '../../consts/Menu';
import { MenuItem as MenuItemType } from '../../types/Menu';
import { useFancyStyles } from './FancyComponents';
import NestedMenuItem from './NestedMenuItem';
// Services
import filterByModules from '../../services/filterByModules';
// Other
import { Link, useHistory } from "react-router-dom";
import clsx from "clsx"

const useStyles = makeStyles(theme => ({
  button: {
    borderRadius: 0,
    borderBottom: `2px solid transparent`,
    // '&:hover': {
    //   borderBottom: `2px solid ${theme.palette.primary.main}`,
    // }
  },
  items: {
    textTransform: 'none'
  },
  list: {
    width: '12rem',
  },
  nestedMenu: {
    whiteSpace: 'normal',
  },
}));

export default function HeaderTabs() {
  const classes = useStyles();
  const history = useHistory();
  const styles = useFancyStyles();
  const menuIsChanging = useRef(false);
  const menuItems = filterByModules(MENU);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [selectedItem, setSelectedItem] = React.useState<null | MenuItemType>(null);

  const handleClose = () => {
    setAnchorEl(null);
    setTimeout(() => {
      setSelectedItem(null);
    }, 500);
  };

  const handleOnButtonEnter = (item: MenuItemType) => (event: React.MouseEvent<HTMLButtonElement>) => {
    if (item.expand) {
      setSelectedItem(item);
      if (!anchorEl || anchorEl !== event.currentTarget) {
        if (anchorEl) {
          menuIsChanging.current = true;
        }
        setAnchorEl(event.currentTarget);
      }
    } else {
      history.push(item.to || '/');
    }
  };

  const renderChildren = (items: MenuItemType[]) => items.map((item, index) => <div key={index}>
    {
      item.expand
        ? <NestedMenuItem
          className={clsx(classes.list, classes.nestedMenu)}
          label={item.name}
          parentMenuOpen={!!anchorEl}
          onClick={handleClose}
        >
          {renderChildren(item.children)}
        </NestedMenuItem>
        : <MenuItem
          className={classes.list}
          onClick={handleClose}
          component={Link}
          to={item.to || '/'}
        >
          {item.name}
        </MenuItem>
    }
  </div>);

  return <React.Fragment>
    <Grid container>
      {
        menuItems.map((item, index) => {
          return (
            <Grid item key={index} className={styles.px_1}>
              <Button
                aria-controls="header_navigation_menu"
                aria-haspopup="true"
                color="inherit"
                className={clsx(classes.button, classes.items)}
                onClick={handleOnButtonEnter(item)}
                disableRipple
              >
                <Typography variant="h4">
                  {item.name}
                </Typography>
                {item.expand ? <ExpandMoreIcon fontSize={'small'} /> : null}
              </Button>
            </Grid>
          )

        })
      }
    </Grid>
    <Menu
      id="header_navigation_menu"
      anchorEl={anchorEl}
      open={!!anchorEl}
      onClose={handleClose}
      getContentAnchorEl={null}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      transformOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      {
        selectedItem && (
          selectedItem.expand
            ? renderChildren(selectedItem.children)
            : <MenuItem
              onClick={handleClose}
              component={Link}
              to={selectedItem.to || '/'}
            >
              {selectedItem.name}
            </MenuItem>
        )
      }
    </Menu>
  </React.Fragment>
}