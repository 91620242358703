import React from 'react';
// Material UI
import { createStyles, makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Hidden from '@material-ui/core/Hidden';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import DialogContent from '@material-ui/core/DialogContent';
import Fab from '@material-ui/core/Fab';
import SearchIcon from '@material-ui/icons/Search';
import WarningIcon from '@material-ui/icons/Warning';
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined';
// App
import { FancyModal, FancyProgress, useFancyStyles } from '../../common/FancyComponents';
import InvoiceFormComponent from './InvoiceFormComponent';
// Types
import { Invoice } from '../../../types/Invoice';
// Services
import { getInvoice } from '../../../services/API/InvoiceServices';
// Redux
import { useAppDispatch, useAppSelector } from '../../../consts/ReduxHooks';
import { dismissDialog, showDialog } from '../../../store/reducers/dialogReducer';
// Other
import { useParams } from 'react-router-dom';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import axios from 'axios';

const useStyles = makeStyles(theme =>
  createStyles({
    container: {
      height: '100vh',
      background: theme.palette.background.default,
    },
    backgroundOpacity: {
      backgroundColor: "rgba(0, 0, 0, 0.5)"
    },
    pageBackground: {
      backgroundColor: theme.palette.grey[800],
    },
  })
);

let source = axios.CancelToken.source();

export default function InvoiceComponent() {
  const { id } = useParams<{ id: string }>();
  const styles = useStyles();
  const classes = useFancyStyles();
  const dispatch = useAppDispatch();
  const dialog = useAppSelector(state => state.dialog);
  const theme = useTheme();
  const matchesDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const [invoice, setInvoice] = React.useState<Invoice | null>(null);
  const [loading, setLoading] = React.useState(false);
  const [numPages, setNumPages] = React.useState<number | null>(null);
  const [innerWidth, setInnerWidth] = React.useState<number>(window.innerWidth);
  const [successSubmit, setSuccessSubmit] = React.useState(false);

  async function fetchInvoice() {
    setLoading(true);
    getInvoice(id, source)
      .then(response => {
        setInvoice(response);
        setLoading(false);
      })
      .catch(error => {
        if (!error.isCanceled) {
          setLoading(false);
        }
      });
  };

  React.useEffect(() => {
    source = axios.CancelToken.source();
    fetchInvoice();
    return () => {
      source.cancel();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    window.addEventListener('resize', handleResize);

    return (() => {
      window.removeEventListener('resize', handleResize);
    })
  }, [innerWidth]);

  const handleResize = () => {
    setInnerWidth(window.innerWidth);
  };

  const handleClickOpen = () => {
    dispatch(showDialog());
  };

  const handleClose = () => {
    dispatch(dismissDialog());
  };

  const handleSubmit = () => {
    setSuccessSubmit(true);
  };

  const ErrorComponent = (props: { text: string }) => <Grid
    container
    direction="column"
    justify="center"
    alignItems="center"
    className={styles.container}
  >
    <Grid item>
      <WarningIcon color="error" style={{ fontSize: 72 }} />
    </Grid>
    <Grid item className={classes.mt_2}>
      <Typography variant="h3" align="center">
        {props.text}
      </Typography>
    </Grid>
  </Grid>;

  const SuccessComponent = () => <Grid
    container
    direction="column"
    justify="center"
    alignItems="center"
    className={styles.container}
  >
    <Grid item>
      <CheckCircleOutlineOutlinedIcon color="secondary" style={{ fontSize: 72 }} />
    </Grid>
    <Grid item className={classes.mt_2}>
      <Typography variant="h3" align="center">
        Se guardó la información de la factura con éxito
      </Typography>
    </Grid>
  </Grid>;

  const PdfComponent = <Document
    file={`data:application/pdf;base64,${invoice?.invoicePdf}`}
    loading={<FancyProgress aria-label="Factura" color="primary" size={50} />}
    error={<ErrorComponent text="Hubo un error al cargar el documento de la factura" />}
    noData={<ErrorComponent text="No se encontró información en el documento" />}
    onLoadSuccess={pdf => setNumPages(pdf.numPages)}
    className={[styles.container, classes.scroll_auto]}
  >
    {Array.from(new Array(numPages), (el, index) => (
      <Page
        key={`page_${index + 1}`}
        pageNumber={index + 1}
        width={matchesDesktop ? ((innerWidth / 2) - 18 - 32) : (innerWidth - 48 - 32)}
        className={[classes.p_2, styles.pageBackground]}
      />
    ))}
  </Document>

  if (successSubmit) {
    return <SuccessComponent />
  }

  return <React.Fragment>
    {
      loading
        ? <FancyProgress
          aria-label="Formulario de Factura"
          color="primary"
          size={100}
          containerClass={styles.container}
        />
        : !invoice
          ? <ErrorComponent text="Hubo un error al tratar de cargar la factura" />
          : <React.Fragment>
            <Grid container>
              <Hidden smDown>
                <Grid item xs={12} md={6}>
                  {PdfComponent}
                </Grid>
              </Hidden>
              <Grid item xs={12} md={6} className={[styles.container, classes.scroll_auto].join(' ')}>
                <InvoiceFormComponent data={invoice} onSubmit={handleSubmit} />
              </Grid>
            </Grid>
            <Hidden mdUp>
              <Fab
                color="secondary"
                aria-label="Agregar"
                className={classes.floatButton}
                onClick={handleClickOpen}
              >
                <SearchIcon />
              </Fab>
            </Hidden>
            <FancyModal
              fullScreen
              open={dialog.open}
              onClose={handleClose}
              aria-label="Formulario de usuario"
              PaperProps={{ className: styles.backgroundOpacity }}
            >
              <DialogContent>
                {PdfComponent}
              </DialogContent>
            </FancyModal>
          </React.Fragment>
    }
  </React.Fragment>
}