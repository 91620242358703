import React, { useEffect, useState } from 'react';
// Material UI
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import DialogContent from '@material-ui/core/DialogContent';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
// Services
import { deleteRegisters, getCatalogRegisters, getCatalogs } from '../../../services/API/CatalogServices';
// Types
import ListComponentProps from '../../../types/propTypes/ListComponent';
import { Catalog } from '../../../types/Catalog';
// Redux
import { useAppDispatch, useAppSelector } from '../../../consts/ReduxHooks';
import { showSnackbar } from '../../../store/reducers/alertReducer';
import { dismissDialog, dismissDialogEdit, showDialog, showDialogEdit } from '../../../store/reducers/dialogReducer';
// App
import { FancyProgress, FancyButton, FancyTextInput, FancyModal } from '../../common/FancyComponents';
import FancyList, { ActionButton, FancyItemText } from '../../common/FancyList';
import VirtualizedAutocompleteComponent from '../../common/VirtualizedAutocompleteComponent';
import CatalogRegisterFormComponent from './CatalogRegisterFormComponent';
// Other
import axios, { CancelTokenSource } from 'axios';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    masteCheckContainer: {
      background: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      margin: theme.spacing(1, 0),
      padding: theme.spacing(0, 1)
    },
    button: {
      position: 'fixed',
      bottom: '3%',
      right: '3%'
    },
  })
);

const source: CancelTokenSource[] = new Array(2).fill(axios.CancelToken.source());

export default function CatalogsListComponent(props: ListComponentProps) {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const dialog = useAppSelector(state => state.dialog);
  const [registers, setRegisters] = useState<any[]>([]);
  const [selectedRegister, setSelectedRegister] = useState<any | null>(null);
  // const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(false);
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState<string[]>([]);
  const [catalogs, setCatalogs] = useState<Catalog[]>([]);
  const [loadingCatalogs, setLoadingCatalogs] = useState(false);
  const [catalog, setCatalog] = useState<Catalog | null>(null);
  const [busy, setBusy] = useState(false);
  // const limit = 30;
  const actionButtons: ActionButton<any>[] = [
    {
      text: 'Editar',
      icon: <EditIcon />,
      onClick: (event, register) => handleOptions('edit', register, event),
      show: !props.hideEdit,
    },
  ];

  async function fetchRegisters(catalog: Catalog, reset: boolean = false) {
    if (reset) {
      setLoading(true);
    }
    // const newPage = reset ? 0 : page;
    // const params = {
    //   limit: limit,
    //   offset: newPage * limit,
    // }
    source[0] = axios.CancelToken.source();
    getCatalogRegisters(catalog.name, source[0])
      .then(response => {
        setRegisters(response);
        // setRegisters(prev => reset ? response.results : prev.concat(response.results));
        // setPage(prev => reset ? 1 : prev + 1);
        // setHasMore(!!response.next);
        setLoading(false);
      })
      .catch(error => {
        if (!error.isCanceled) {
          setRegisters([]);
          // setPage(0);
          setHasMore(false);
          setLoading(false);
        }
      });
  };

  async function fetchCatalogs() {
    setLoadingCatalogs(true);
    // const params = {
    //   paginate: false as false,
    // }
    getCatalogs(source[1])
      .then(response => {
        setCatalogs(response);
        setLoadingCatalogs(false);
      })
      .catch((error) => {
        if (!error.isCanceled) {
          setLoadingCatalogs(false);
        }
      });
  };

  useEffect(() => {
    source[1] = axios.CancelToken.source();
    if (!props.hideList) {
      fetchCatalogs();
    }
    return () => {
      source.forEach(s => s.cancel());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClickOpen = () => {
    dispatch(showDialog());
  };

  const handleClose = () => {
    dispatch(dismissDialog());
    dispatch(dismissDialogEdit());
  };

  const handleSubmit = () => {
    if (catalog) {
      fetchRegisters(catalog, true);
    }
    handleClose();
  };

  function handleCheckboxToggle(value: string) {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleCheckSubmit = () => {
    if (!catalog) {
      dispatch(showSnackbar({ message: 'Debe seleccionar un catálogo', type: 'error' }));
      return;
    }
    setBusy(true);
    deleteRegisters(catalog.name, checked, source[1])
      .then(response => {
        dispatch(showSnackbar({ message: 'Se han eliminados los registros correctamente', type: 'success' }));
        setBusy(false);
      })
      .catch(error => {
        if (!error.isCanceled) {
          setBusy(false);
        }
      });
  };

  const handleOptions = (option: 'edit', register: any, event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation();
    switch (option) {
      case 'edit':
        setSelectedRegister(register);
        dispatch(showDialogEdit());
        break;
    }
  };

  return (
    <React.Fragment>
      {
        !props.hideList && <React.Fragment>
          <Grid container>
            <Grid item xs={12} sm={6} md={4}>
              <VirtualizedAutocompleteComponent<Catalog>
                value={catalog}
                options={catalogs}
                onChange={(event, newValue) => {
                  setCatalog(newValue);
                  if (newValue) {
                    fetchRegisters(newValue, true);
                  }
                }}
                getOptionSelected={(option, selected) => option.name === selected.name}
                getOptionLabel={option => option.shortName}
                renderOption={option => <Typography noWrap>{option.shortName}</Typography>}
                loading={loadingCatalogs}
                aria-label="Catálogo"
                renderInput={params => <FancyTextInput
                  {...params}
                  label="Catálogo"
                  id="grupoForm_catalog"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {loadingCatalogs ? <CircularProgress aria-label="Catálogo" color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />}
              />
            </Grid>
          </Grid>
          {
            catalog && !loading && <React.Fragment>
              {
                checked.length > 0 && registers.length > 0 && (
                  <Paper
                    square
                    className={classes.masteCheckContainer}
                    role="region"
                    aria-label="Opciones Checkbox"
                  >
                    <ListItem disableGutters ContainerComponent="div">
                      <ListItemText
                        disableTypography
                        primary={(
                          <Grid container justify="space-between" alignItems="center">
                            <Grid item>
                              <Typography>
                                {`${checked.length} seleccionado${checked.length > 1 ? 's' : ''}`}
                              </Typography>
                            </Grid>
                            <Grid item>
                              <FancyButton
                                type="button"
                                color="inherit"
                                variant="outlined"
                                onClick={handleCheckSubmit}
                                disabled={busy}
                                loading={busy}
                              >
                                Eliminar
                              </FancyButton>
                            </Grid>
                          </Grid>
                        )}
                      />
                    </ListItem>
                  </Paper>
                )
              }
              <FancyList<any>
                infinite
                divider
                check
                checked={checked}
                checkboxOnChange={register => handleCheckboxToggle(register.group)}
                idKey="index"
                elements={registers}
                hasMore={hasMore}
                fetchElements={() => fetchRegisters(catalog, false)}
                ListProps={{ "aria-label": "Registros del Catálogo" }}
                ListItemTextProps={register => ({
                  primary: <FancyItemText textArray={Object.values(register)} />,
                })}
                actionButtons={actionButtons}
              />
            </React.Fragment>
          }
          {loading && <FancyProgress aria-label="Lista de registros del catálogo" color="primary" size={100} />}
        </React.Fragment>
      }
      {
        !props.hideCreate && catalog && <Fab
          color="primary"
          aria-label="Agregar"
          className={classes.button}
          onClick={handleClickOpen}
        >
          <AddIcon />
        </Fab>
      }
      {
        catalog && <FancyModal
          open={dialog.open || dialog.openEdit}
          onClose={handleClose}
          aria-label="Formulario de registro de catálogo"
        >
          <DialogContent>
            <CatalogRegisterFormComponent
              onSubmit={handleSubmit}
              data={dialog.openEdit ? selectedRegister : null}
              catalog={catalog}
            />
          </DialogContent>
        </FancyModal>
      }
    </React.Fragment>
  );
}
