import React, { useState } from 'react';
// Material ui
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
// App
import { FancyButton } from '../../common/FancyComponents';
import Logo from '../../../assets/images/logo.svg';
// Redux
import { useAppDispatch } from '../../../consts/ReduxHooks';
import { showSnackbar } from '../../../store/reducers/alertReducer';
// Services
// import { login } from '../../../services/API/AuthServices';
// Others
import axios, { CancelTokenSource } from 'axios';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    logo: {
      height: 150,
      width: 'auto'
    },
    paper: {
      width: '350px',
      height: '100%',
      padding: theme.spacing(2, 3),
      [theme.breakpoints.down('xs')]: {
        width: 'auto',
        padding: theme.spacing(1, 2),
      }
    },
    groupButton: {
      width: '100%'
    }
  }),
);

let source: CancelTokenSource = axios.CancelToken.source();

export default function GroupSelectionComponent() {
  const styles = useStyles();
  const dispatch = useAppDispatch();
  const [busy, setBusy] = useState(false);
  const groups = [{ name: 'Grupo 1', id: 1 }, { name: 'Grupo 2', id: 2 }];
  const [selectedGroup, setSelectedGroup] = useState<null | number>(null);

  React.useEffect(() => {
    source = axios.CancelToken.source();
    return () => {
      source.cancel();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmitClick = () => {
    if (selectedGroup === null) {
      dispatch(showSnackbar({ message: 'Debe seleccionar un grupo para iniciar sesión', type: 'error' }));
      return;
    }
    setBusy(true);
    // login(data, source)
    //   .catch(error => {
    //     if (!error.isCanceled) {
    //       setBusy(false);
    //     }
    //   });
  }

  const handleButtonClick = (id: number) => () => {
    if (selectedGroup === id) {
      setSelectedGroup(null);
    } else {
      setSelectedGroup(id);
    }
  }

  return (
    <Paper className={styles.paper}>
      <Grid container direction="column" alignItems="center" spacing={1}>
        <Grid item>
          <img src={Logo} alt="logo" className={styles.logo} />
        </Grid>
        <Grid item>
          <Typography align="center" variant="h6">Seleccione el grupo con el que desea iniciar sesión</Typography>
        </Grid>
        <Grid item style={{ margin: 12 }}>
          <Grid container direction="row" spacing={3}>
            {
              groups.map(group => <Grid item xs={12} key={group.id}>
                <FancyButton
                  square
                  variant="contained"
                  size="large"
                  color={selectedGroup === group.id ? 'secondary' : undefined}
                  className={styles.groupButton}
                  onClick={handleButtonClick(group.id)}
                >
                  {group.name}
                </FancyButton>
              </Grid>)
            }
          </Grid>
        </Grid>
        <Grid item>
          <FancyButton
            variant="contained"
            color="primary"
            disabled={busy}
            loading={busy}
            onClick={handleSubmitClick}
          >
            Ingresar
          </FancyButton>
        </Grid>
      </Grid>
    </Paper>
  );
}
