import faker from "faker";
import { NestedValue } from "react-hook-form";
import { Params } from "./Api";

export interface Account {
  account_number: string;
  name: string;
  customer: Entity;
  bank: Entity;
  business: Business[];
  currency: string;
  active: boolean;
  owner: boolean;
}
export class RandomAccount implements Account {
  account_number = faker.unique(faker.datatype.uuid);
  name = faker.finance.accountName();
  customer = new RandomEntity();
  bank = new RandomEntity();
  business = new Array(4).fill(true).map(() => ({ ...new RandomBusiness() }));
  currency = faker.finance.currencyCode();
  active = faker.datatype.boolean();
  owner = faker.datatype.boolean();

  constructor(init?: Partial<Account>) {
    Object.assign(this, init);
  }
}

export interface AccountForm {
  business: NestedValue<Business[]>;
}
export class AccountFormDefault {
  business: Business[] = [];
}

export interface AccountBody {
  active?: boolean;
  business?: number[];
}

export interface Entity {
  email: string;
  name: string;
  document: string;
}
export class RandomEntity implements Entity {
  email = faker.unique(faker.internet.email);
  name = faker.name.firstName();
  document = faker.system.fileName();

  constructor(init?: Partial<Entity>) {
    Object.assign(this, init);
  }
}

export interface AccountParams extends Params {
  customer?: string;
}

export interface Widget {
  url_widget: string;
}
export class RandomWidget implements Widget {
  url_widget = faker.system.filePath();

  constructor(init?: Partial<Widget>) {
    Object.assign(this, init);
  }
}

export interface Business {
  id: number;
  name: string;
  cif: string;
}
export class RandomBusiness implements Business {
  id = faker.unique(faker.datatype.number);
  name = faker.name.firstName();
  cif = faker.system.fileName();

  constructor(init?: Partial<Entity>) {
    Object.assign(this, init);
  }
}