import faker from 'faker';
// Types
// import { Params } from './Api';
import { Organization, RandomOrganization } from './Organization';
import { Permission, RandomPermission } from './Permission';
import { Module, RandomModule } from './Module';
import { NestedValue } from 'react-hook-form';

export interface APIGroup {
  group: string;
  organization: Pick<Organization, 'id' | 'name'>;
  permission: Pick<Permission, 'id' | 'name'>;
  module: Pick<Module, 'id' | 'name'>;
}

export interface ListModule extends Pick<Module, 'id' | 'name'> {
  permissions: Pick<Permission, 'id' | 'name'>[];
};
export class RandomListModule implements ListModule {
  id: number;
  name: string;
  permissions = new Array(4).fill(true).map(() => ({ ...new RandomPermission() }));

  constructor() {
    const module = new RandomModule();
    this.id = module.id;
    this.name = module.name
  }
}

export interface Group {
  group: string;
  organization: Pick<Organization, 'id' | 'name'>;
  modules: ListModule[];
}
export class RandomGroup implements Group {
  group = faker.unique(faker.name.jobTitle);
  organization: Pick<Organization, 'id' | 'name'> = new RandomOrganization();
  modules = new Array(4).fill(true).map(() => ({ ...new RandomListModule() }));

  constructor(init?: Partial<Group>) {
    Object.assign(this, init);
  }
}

export type GroupParams = {
  id?: string
  organization: null | number;
} & ({ uid?: string, groups?: undefined } | { uid?: undefined, groups?: string })

export interface GroupForm {
  group: string;
  organization: Organization | null;
  module: Module | null;
  permissions: NestedValue<Permission[]>;
}
export class GroupFormDefault {
  group = '';
  organization: Organization | null = null;
  module: Module | null = null;
  permissions: Permission[] = [];
}

export interface GroupBody {
  group?: string;
  organization: number;
  modules: { id: number, permissions: number[] }[];
}
export class RandomGroupBody implements GroupBody {
  group = faker.commerce.department();
  organization = 0;
  modules: { id: number, permissions: number[] }[] = [];
}