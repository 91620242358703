import faker from 'faker';
// Types
import { NestedValue } from 'react-hook-form';
import { Params } from './Api';
import { Organization, RandomOrganization } from './Organization';
import { Permission, RandomPermission } from './Permission';

export interface Module {
  id: number;
  name: string;
  description: string;
  organizations: Organization[];
  permissions: Permission[];
}
export class RandomModule implements Module {
  id = faker.unique(faker.datatype.number);
  name = faker.commerce.department();
  description = faker.lorem.sentences(2);
  organizations: Organization[] = new Array(4).fill(true).map(() => ({ ...new RandomOrganization() }));
  permissions = new Array(4).fill(true).map(() => ({ ...new RandomPermission() }));

  constructor(init?: Partial<Module>) {
    Object.assign(this, init);
  }
}

export interface ModuleParams extends Params {
  organization?: null | number;
  permission?: null | number;
}

export interface ModuleForm {
  name: string;
  description: string;
  // Used NestedValue when field uses Autocomplete multiple
  organizations: NestedValue<Organization[]>;
  permissions: NestedValue<Permission[]>;
}
export class ModuleFormDefault {
  name = '';
  description = '';
  organizations: Organization[] = [];
  permissions: Permission[] = [];
}

export interface ModuleBody {
  name?: string;
  description?: string;
  permissions: number[];
  organizations: number[];
}
export class RandomModuleBody implements ModuleBody {
  name = faker.commerce.department();
  description = faker.lorem.sentences(2);
  organizations: number[] = [];
  permissions: number[] = [];
}