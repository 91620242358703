import React, { useState } from "react";
// Material ui
import { createStyles, makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import DialogContent from "@material-ui/core/DialogContent";
import CircularProgress from "@material-ui/core/CircularProgress";
import EditIcon from "@material-ui/icons/Edit";
// Forms
import {
  useForm,
  Controller,
} from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers";
// App
import {
  FancyTextInput,
  FancyButton,
  FancyDatePicker,
  FancyModal,
  useFancyStyles,
} from "../../common/FancyComponents";
import FancyList, { ActionButton } from "../../common/FancyList";
import SplitFormComponent from "./SplitFormComponents";
import VirtualizedAutocompleteComponent from "../../common/VirtualizedAutocompleteComponent";
// Types
import { RequiredGeneralFormProps } from "../../../types/propTypes/misc";
import { Elements, Import, Invoice, InvoiceForm, Split, InvoiceClassification, Provider, PaymentType } from "../../../types/Invoice";
// Services
import {
  getInvoiceClassification,
  // getPaymentNature,
  getPaymentType,
  getProvider,
  parseInvoice,
  updateInvoice,
} from "../../../services/API/InvoiceServices";
// Redux
import { useAppDispatch, useAppSelector } from "../../../consts/ReduxHooks";
import {
  dismissDialog,
  dismissDialogEdit,
  showDialogEdit,
} from "../../../store/reducers/dialogReducer";
// Others
import axios, { CancelTokenSource } from "axios";
import moment from "moment";

const useStyles = makeStyles((theme) =>
  createStyles({
    formContainer: {
      padding: theme.spacing(1, 0),
      [theme.breakpoints.up("sm")]: {
        padding: theme.spacing(2),
      },
    },
    colorList: {
      marginTop: '25px',
      backgroundColor: '#004d2b',
      padding: theme.spacing(1, 0),
      borderTopLeftRadius: '4px',
      borderTopRightRadius: '4px',
      [theme.breakpoints.down("md")]: {
        display: 'none',
      },
    },
    colorWhite: {
      color: 'white',
    }
  })
);

const schema = yup.object().shape({
  invoiceNumber: yup.string().required(),
  invoiceContableDate: yup.mixed().required(),
  provider: yup.mixed().required(),
  societyName: yup.string().required(),
  societyCif: yup.string().required(),
  invoiceVigency: yup.mixed().required(),
  paymentType: yup.mixed().required(),
  campain: yup.mixed().required(),
  invoiceClassification: yup.mixed().required(),
  // paymentNature: yup.mixed().required(),
  // split: yup.mixed().required(),
});

let source: CancelTokenSource = axios.CancelToken.source();

export default function InvoiceFormComponent(
  props: RequiredGeneralFormProps<Invoice>
) {
  const styles = useStyles();
  const classes = useFancyStyles();
  const dispatch = useAppDispatch();
  const dialog = useAppSelector((state) => state.dialog);
  const methods = useForm<InvoiceForm>({
    resolver: yupResolver(schema),
    defaultValues: new InvoiceForm(),
  });
  const { handleSubmit, control, errors, reset, watch } = methods;
  const [busy, setBusy] = useState(false);
  const [loadingProvider, setLoadingProvider] = useState(false);
  const [loadingPaymentType, setLoadingPaymentType] = useState(false);
  const [loadingInvoiceClassification, setLoadingInvoiceClassification] = useState(false);
  // const [loadingPaymentNature, setLoadingPaymentNature] = useState(false);
  const [imports, setImports] = useState(props.data.imports);
  const [providers, setProviders] = useState<Provider[]>([]);
  const [paymentType, setPaymentType] = useState<PaymentType[]>([]);
  const [invoiceClassification, setInvoiceClassification] = useState<InvoiceClassification[]>([]);
  const [paymentNature, setPaymentNature] = useState<Elements[]>([]);
  const [selectedSplit, setSelectedSplit] = useState<Split | null>(null);
  const [splitList, setSplitList] = useState<Split[]>(props.data.splits);
  const watchProvider = watch('provider');
  const actionButtons: ActionButton<any>[] = [
    {
      text: "Editar",
      icon: <EditIcon />,
      onClick: (event, register) => handleOptions("edit", register, event),
      show: true,
    },
  ];

  const arrayPercentage = ["0%", "6%", "13%", "23%"];
  const handleClose = () => {
    dispatch(dismissDialog());
    dispatch(dismissDialogEdit());
  };

  const handleEditSubmit = (data: Split) => {
    setSplitList((prev) =>
      prev.map((l) => {
        if (l.id === data.id) {
          return data;
        }
        return l;
      })
    );
    handleClose();
  };

  const handleOptions = (
    option: "edit" | "delete",
    split: Split,
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.stopPropagation();
    switch (option) {
      case "edit":
        setSelectedSplit(split);
        dispatch(showDialogEdit());
        break;
    }
  };

  const onSubmit = (data: InvoiceForm) => {
    setBusy(true);

    const newData = parseInvoice({
      ...props.data,
      invoiceNumber: data.invoiceNumber,
      invoiceContableDate: data.invoiceContableDate?.format('YYYY-MM-DD HH:mm:ss') || '',
      provider: {
        name: data.provider?.name || '',
        cif: data.provider?.nif || '',
      },
      societyName: data.societyName,
      societyCif: data.societyCif,
      invoiceVigency: data.invoiceVigency?.format('YYYY-MM-DD HH:mm:ss') || '',
      paymentType: data.paymentType?.paymentType || '',
      campain: data.campain?.format('YYYY') || '',
      invoiceClassification: data.invoiceClassification?.type || '',
      paymentNature: data.paymentNature?.name || '',
      splits: splitList,
    });

    console.log(newData);
    updateInvoice(newData, source)
      .then((response) => {
        setBusy(false);
        window.close();
        if (props.onSubmit) {
          props.onSubmit();
        }
      })
  };

  async function fetchProvider() {
    setLoadingProvider(true);
    getProvider(source)
      .then((response) => {
        setProviders(response);
        setLoadingProvider(false);
      })
      .catch((error) => {
        if (!error.isCanceled) {
          setLoadingProvider(false);
        }
      });
  }

  async function fetchPaymentType() {
    setLoadingPaymentType(true);
    getPaymentType(source)
      .then((response) => {
        setPaymentType(response);
        setLoadingPaymentType(false);
      })
      .catch((error) => {
        if (!error.isCanceled) {
          setLoadingPaymentType(false);
        }
      });
  }

  async function fetchInvoiceC() {
    setLoadingInvoiceClassification(true);
    getInvoiceClassification(source)
      .then((response) => {
        setInvoiceClassification(response);
        setLoadingInvoiceClassification(false);
      })
      .catch((error) => {
        if (!error.isCanceled) {
          setLoadingInvoiceClassification(false);
        }
      });
  }

  async function fetchPaymentNature() {
    setPaymentNature([]);
    // setLoadingPaymentNature(true);
    // getPaymentNature(source)
    //   .then((response) => {
    //     setPaymentNature(response);
    //     setLoadingPaymentNature(false);
    //   })
    //   .catch((error) => {
    //     if (!error.isCanceled) {
    //       setLoadingPaymentNature(false);
    //     }
    //   });
  }

  React.useEffect(() => {
    source = axios.CancelToken.source();
    // setSplitList(props.data.split);
    setImports(props.data.imports);
    fetchProvider();
    fetchPaymentType();
    fetchInvoiceC();
    fetchPaymentNature();
    console.log(splitList);
    reset({
      invoiceNumber: props.data.invoiceNumber,
      invoiceContableDate: moment.utc(props.data.invoiceContableDate),
      provider: {
        name: props.data.provider.name,
        nif: props.data.provider.cif,
      },
      // providerCif:props.data.providerCif,
      societyName: props.data.societyName,
      societyCif: props.data.societyCif,
      invoiceVigency: moment.utc(props.data.invoiceVigency),
      paymentType: { /*id: props.data.paymentType,*/  paymentType: props.data.paymentType },
      campain: props.data.campain ? moment().set('year', +props.data.campain) : null,
      invoiceClassification: {
        type: props.data.invoiceClassification,
      },
      imports: props.data.imports,
      total: props.data.total,
      paymentNature: {
        id: props.data.paymentNature,
        name: props.data.paymentNature,
      },
      splits: props.data.splits,
    });

    return () => {
      source.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleCancelClick() {
    window.close()
  }

  return <React.Fragment>
    <form
      onSubmit={handleSubmit(onSubmit)}
      aria-label="Factura"
      className={classes.p_1}
    >
      <Grid
        container
        direction="row"
        justify="center"
        spacing={1}
        className={styles.formContainer}
      >
        <Grid item xs={12}>
          <Typography variant="h4">Información de la Factura</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            render={(props) => (
              <FancyTextInput
                {...props}
                error={!!errors.invoiceNumber}
                helperText={
                  errors.invoiceNumber && errors.invoiceNumber.message
                }
                label="Número de Factura"
                id="invoice_invoiceNumber"
              />
            )}
            control={control}
            name="invoiceNumber"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            render={(props) => (
              <FancyDatePicker
                {...props}
                renderInput={(props) => (
                  <FancyTextInput
                    {...props}
                    error={!!errors.invoiceContableDate}
                    helperText={
                      errors.invoiceContableDate && errors.invoiceContableDate?.message
                    }
                    id={"filterForm_date"}
                    label="Fecha"
                  />
                )}
              />
            )}
            control={control}
            name="invoiceContableDate"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            render={(renderProps) => (
              <VirtualizedAutocompleteComponent<Provider>
                {...renderProps}
                options={providers}
                onChange={(event, newValue) => renderProps.onChange(newValue)}
                getOptionSelected={(option, selected) =>
                  option.nif === selected.nif
                }
                getOptionLabel={(option) => option.name}
                renderOption={(option) => (
                  <Typography noWrap>{option.name}</Typography>
                )}
                loading={loadingProvider}
                aria-label="Nombre del Proveedor"
                renderInput={(params) => (
                  <FancyTextInput
                    {...params}
                    label="Nombre del Proveedor"
                    id="invoice_provider"
                    error={!!errors.provider}
                    helperText={
                      errors.provider && errors.provider.message
                    }
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {loadingProvider ? (
                            <CircularProgress
                              aria-label="provider"
                              color="inherit"
                              size={20}
                            />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
            )}
            control={control}
            name="provider"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FancyTextInput
            name="provider"
            label="Cif del Proveedor"
            disabled
            value={watchProvider?.nif || ''}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            render={(props) => (
              <FancyTextInput
                {...props}
                error={!!errors.societyName}
                helperText={errors.societyName && errors.societyName.message}
                label="Nombre de la Sociedad"
                id="invoice_companyName"
                disabled
              />
            )}
            control={control}
            name="societyName"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            render={(props) => (
              <FancyTextInput
                {...props}
                error={!!errors.societyCif}
                helperText={errors.societyCif && errors.societyCif.message}
                label="CIF de la Sociedad"
                id="invoice_companyCIF"
                disabled
              />
            )}
            control={control}
            name="societyCif"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            control={control}
            name="invoiceVigency"
            render={(props) => (
              <FancyDatePicker
                {...props}
                renderInput={(props) => (
                  <FancyTextInput
                    {...props}
                    error={!!errors.invoiceVigency}
                    helperText={
                      errors.invoiceVigency && errors.invoiceVigency?.message
                    }
                    id={"filterForm_date"}
                    label="Fecha de Vencimiento"
                  />
                )}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            render={(renderProps) => (
              <VirtualizedAutocompleteComponent<PaymentType>
                {...renderProps}
                options={paymentType}
                onChange={(event, newValue) => renderProps.onChange(newValue)}
                getOptionSelected={(option, selected) =>
                  option.id === selected.id
                }
                getOptionLabel={(option) => option.paymentType}
                renderOption={(option) => (
                  <Typography noWrap>{option.paymentType}</Typography>
                )}
                loading={loadingPaymentType}
                aria-label="Tipo de Pago"
                renderInput={(params) => (
                  <FancyTextInput
                    {...params}
                    label="Tipo de Pago"
                    id="invoice_PaymentType"
                    error={!!errors.paymentType}
                    helperText={
                      errors.paymentType && errors.paymentType?.message
                    }
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {loadingPaymentType ? (
                            <CircularProgress
                              aria-label="PaymentType"
                              color="inherit"
                              size={20}
                            />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
            )}
            control={control}
            name="paymentType"
          />
        </Grid>
        <Grid item xs={12} sm={6} className={classes.mb_2}>
          <Controller
            control={control}
            name="campain"
            render={(props) => (
              <FancyDatePicker
                {...props}
                reduceAnimations
                views={['year']}
                renderInput={(props) => (
                  <FancyTextInput
                    {...props}
                    error={!!errors.campain}
                    helperText={
                      errors.campain && errors.campain?.message
                    }
                    inputProps={{
                      ...props.inputProps,
                      readOnly: true,
                    }}
                    id={"invoice_Campain"}
                    label="Campaña"
                  />
                )}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            render={(renderProps) => (
              <VirtualizedAutocompleteComponent<InvoiceClassification>
                {...renderProps}
                options={invoiceClassification}
                onChange={(event, newValue) => renderProps.onChange(newValue)}
                getOptionSelected={(option, selected) =>
                  option.id === selected.id
                }
                getOptionLabel={(option) => option.type}
                renderOption={(option) => (
                  <Typography noWrap>{option.type}</Typography>
                )}
                loading={loadingInvoiceClassification}
                aria-label="Tipo de Factura"
                renderInput={(params) => (
                  <FancyTextInput
                    {...params}
                    label="Tipo de Factura"
                    id="invoice_invoiceClassification"
                    error={!!errors.invoiceClassification}
                    helperText={
                      errors.invoiceClassification &&
                      errors.invoiceClassification?.message
                    }
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {loadingInvoiceClassification ? (
                            <CircularProgress
                              aria-label="paymentMethod"
                              color="inherit"
                              size={20}
                            />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
            )}
            control={control}
            name="invoiceClassification"
          />
        </Grid>
        <Grid item xs={2} sm={1}>
          <Typography variant="subtitle2" align="center">
            %
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="subtitle2">Base Imponible</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="subtitle2">IVA</Typography>
        </Grid>
        <Grid item xs={3} sm={4}>
          <Typography variant="subtitle2">Retención</Typography>
        </Grid>
        {imports.map((item: Import, index) => {
          return (
            <React.Fragment key={index}>
              <Grid
                item
                xs={2}
                sm={1}
                container
                alignItems="center"
                justify="center"
              >
                <Typography>{arrayPercentage[index]}</Typography>
              </Grid>
              <Grid item xs={4}>
                <Controller
                  render={(props) => (
                    <FancyTextInput
                      {...props}
                      // error={!!errors.taxBase0}
                      // helperText={errors.taxBase0 && errors.taxBase0.message}
                      type="number"
                      id={`base${index}`}
                      disabled
                    />
                  )}
                  control={control}
                  name={`imports[${index}].base`}
                />
              </Grid>
              <Grid item xs={3}>
                <Controller
                  render={(props) => (
                    <FancyTextInput
                      {...props}
                      // error={!!errors.VAT0}
                      // helperText={errors.VAT0 && errors.VAT0.message}
                      type="number"
                      id={`iva${index}`}
                      disabled
                    />
                  )}
                  control={control}
                  // name={`iva[${index}]`}
                  name={`imports[${index}].iva`}
                />
              </Grid>
              <Grid item xs={3} sm={4}>
                <Controller
                  render={(props) => (
                    <FancyTextInput
                      {...props}
                      // error={!!errors.hold0}
                      // helperText={errors.hold0 && errors.hold0.message}
                      type="number"
                      id={`retention${index}`}
                      disabled
                    />
                  )}
                  control={control}
                  name={`imports[${index}].retention`}
                />
              </Grid>
            </React.Fragment>
          );
        })}

        <Grid item xs={12} className={classes.my_2}>
          <Divider />
        </Grid>
        <Grid item xs={10} sm={5}>
          <Controller
            render={(renderProps) => (
              <FancyTextInput
                {...renderProps}
                // error={!!errors.total}
                // helperText={errors.total && errors.total?.message}
                type="number"
                id="invoice-total"
                label="Total"
                disabled
              />
            )}
            control={control}
            name="total"
          />
        </Grid>
        <Grid item container xs={2} sm={1} alignItems="center" justify="center">
          <Typography>($/€)</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            render={(renderProps) => (
              <VirtualizedAutocompleteComponent
                {...renderProps}
                disabled
                options={paymentNature}
                onChange={(event, newValue) => renderProps.onChange(newValue)}
                getOptionSelected={(option, selected) =>
                  option.id === selected.id
                }
                getOptionLabel={(option) => option.name}
                renderOption={(option) => (
                  <Typography noWrap>{option.name}</Typography>
                )}
                // loading={loadingPaymentNature}
                aria-label="Naturaleza de Pago"
                renderInput={(params) => (
                  <FancyTextInput
                    {...params}
                    label="Naturaleza de Pago"
                    id="invoice_naturePayment"
                    error={!!errors.paymentNature}
                    helperText={
                      errors.paymentNature && errors.paymentNature.message
                    }
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {/* {loadingPaymentNature ? (
                            <CircularProgress
                              aria-label="Naturaleza de pago"
                              color="inherit"
                              size={20}
                            />
                          ) : null}
                          {params.InputProps.endAdornment}*/}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
            )}
            control={control}
            name="paymentNature"
          />
        </Grid>
        <Grid item xs={12}>
          <Grid container className={styles.colorList}>
            <Grid item xs={3}><Typography align="center" className={styles.colorWhite}>Tipos de Cultivos</Typography> </Grid>
            <Grid item xs={3}><Typography align="center" className={styles.colorWhite}>Códigos de Visado</Typography> </Grid>
            <Grid item xs={3}><Typography align="center" className={styles.colorWhite}>Concepto de Visado</Typography> </Grid>
            <Grid item xs={3}><Typography className={styles.colorWhite}>Base Imponible</Typography></Grid>
          </Grid>
          <FancyList<Split>
            divider
            disablePadding
            elements={splitList}
            ListProps={{ "aria-label": "split" }}
            ListItemTextProps={(split) => ({
              primary: <Grid container>
                <Grid item xs={3}>
                  <Typography align="center">{split.crop}</Typography>
                </Grid>
                <Grid item xs={3} ><Typography align="center">{split.code}</Typography></Grid>
                <Grid item xs={3}>
                  <Typography align="center">{split.category}</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography align="center">{split.base}</Typography>
                </Grid>
              </Grid>,
            })}
            idKey="id"
            actionButtons={actionButtons}
          />
        </Grid>
      </Grid>
      <Divider />
      <Grid container justify="flex-end" spacing={2} className={classes.mt_1}>
        <Grid item>
          <FancyButton variant="outlined" color="primary" disabled={busy} onClick={handleCancelClick}>
            Cancelar
          </FancyButton>
        </Grid>
        <Grid item>
          <FancyButton
            variant="contained"
            color="primary"
            type="submit"
            disabled={busy}
            loading={busy}
          >
            Actualizar Datos
          </FancyButton>
        </Grid>
      </Grid>
    </form>
    <FancyModal
      open={dialog.openEdit}
      onClose={handleClose}
      aria-label="Formulario de Split"
    >
      <DialogContent>
        <SplitFormComponent
          onSubmit={handleEditSubmit}
          data={dialog.openEdit ? selectedSplit : null}
          invoice={props.data}
        />
      </DialogContent>
    </FancyModal>
  </React.Fragment>
}
