import React from 'react';
// App
import DashboardComponent from '../../layouts/DashboardComponent';
import Bpmn from '../../views/Bpmn/Bpmn';
import BpmnList from '../../views/Bpmn/BpmnList';
// Others
import { Switch, Route, RouteComponentProps, Redirect } from 'react-router-dom';

export default function BpmnRouting(props: RouteComponentProps) {
  return (
    <DashboardComponent
      title="Modelos"
      children={<Switch>
        <Route exact path={props.match.path + '/files'} component={Bpmn} />
        <Route exact path={props.match.path + '/list'} component={BpmnList} />
        <Redirect to={props.match.path + '/list'} />
      </Switch>}
    />
  );
}
