import React from 'react';
// Material ui
import GroupRoundedIcon from '@material-ui/icons/GroupRounded';
import EventRoundedIcon from '@material-ui/icons/EventRounded';
// import TimelineIcon from '@material-ui/icons/Timeline';
import SvgIcon from '@material-ui/core/SvgIcon';

import PieChartIcon from '@material-ui/icons/PieChart';
// import EqualizerIcon from '@material-ui/icons/Equalizer';
import LinearScaleIcon from '@material-ui/icons/LinearScale';
import AppsIcon from '@material-ui/icons/Apps';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import DomainIcon from '@material-ui/icons/Domain';
import GroupIcon from '@material-ui/icons/Group';
import AssignmentIcon from '@material-ui/icons/Assignment';
import AssignmentLateIcon from '@material-ui/icons/AssignmentLate';
import BusinessIcon from '@material-ui/icons/Business';
// App
import MODULES from './Modules';
import { ReactComponent as ComercialIcon } from '../assets/icons/comercial.svg'
// Types
import { MenuItem } from '../types/Menu';

export const MENU: MenuItem[] = [
  {
    name: 'Comercial',
    icon: <SvgIcon component={ComercialIcon} color="inherit" viewBox="0 0 31.507 29.257" />,
    to: null,
    expand: true,
    modules: [MODULES.bi_nickel, MODULES.bi_geofolia],
    children: [
      {
        name: 'BI',
        icon: <PieChartIcon />,
        to: null,
        expand: true,
        modules: [MODULES.bi_nickel, MODULES.bi_geofolia],
        children: [
          {
            name: 'Nickel',
            icon: <PieChartIcon />,
            to: '/comercial/bi/nickel',
            expand: false,
            modules: [MODULES.bi_nickel],
          },
          {
            name: 'Geofolia',
            icon: <PieChartIcon />,
            to: '/comercial/bi/geofolia',
            expand: false,
            modules: [MODULES.bi_geofolia],
          },
          // {
          //   name: 'Alfalite',
          //   to: '/marketing/bi/alfalite',
          //   expand: false,
          //   modules: [],
          // },
        ],
      },
    ],
  },
  {
    name: 'Procesos',
    to: null,
    icon: <AppsIcon />,
    expand: true,
    modules: [
      MODULES.process_ary,
      MODULES.process_erp,
      MODULES.billing_dashboard,
      MODULES.diagrams_bpmn,
      MODULES.clients_banking,
      MODULES.bam,
      MODULES.accounting,
      MODULES.catalog,
    ],
    children: [
      // {
      //   name: 'OCR',
      //   icon: <EqualizerIcon />,
      //   to: '/app/ocr',
      //   expand: true,
      //   modules: [],
      //   children: [
      //     {
      //       name: 'OCR',
      //       to: '/app/ocr/ocr',
      //       expand: false,
      //       modules: [],
      //     },
      //   ],
      // },
      {
        name: 'Tareas',
        icon: <EventRoundedIcon />,
        to: null,
        expand: true,
        modules: [MODULES.process_ary/*, MODULES.process_erp*/],
        children: [
          {
            name: 'Desarrollo',
            icon: <EventRoundedIcon />,
            to: '/process/task',
            expand: false,
            modules: [MODULES.process_ary],
          },
        ],
      },
      {
        name: 'Facturación',
        icon: <EventRoundedIcon />,
        to: null,
        expand: true,
        modules: [MODULES.billing_dashboard, MODULES.process_erp],
        children: [
          {
            name: 'Sub Procesos',
            icon: <EventRoundedIcon />,
            to: '/process/erp',
            expand: false,
            modules: [MODULES.process_erp],
          },
          {
            name: 'Dashboard',
            icon: <EventRoundedIcon />,
            to: '/process/billing',
            expand: false,
            modules: [MODULES.billing_dashboard],
          },
        ],
      },
      {
        name: 'Diagramas',
        icon: <LinearScaleIcon />,
        to: null,
        expand: true,
        modules: [MODULES.diagrams_bpmn],
        children: [
          {
            name: 'Modelos',
            icon: <AttachFileIcon />,
            to: '/process/models/list',
            expand: false,
            modules: [MODULES.diagrams_bpmn],
          },
          // {
          //   name: 'Diagramas',
          //   icon: <EqualizerIcon />,
          //   to: '/app/bpmn/files',
          //   expand: false,
          //   modules: [],
          // },
        ],
      },
      {
        name: 'Contabilidad',
        icon: <EventRoundedIcon />,
        to: null,
        expand: true,
        modules: [MODULES.accounting],
        children: [
          {
            name: 'Sub Procesos',
            icon: <EventRoundedIcon />,
            to: '/process/accounting',
            expand: false,
            modules: [MODULES.accounting],
          },
        ],
      },
      {
        name: 'Bancos',
        icon: <AttachFileIcon />,
        to: '/process/banking/list',
        expand: false,
        modules: [MODULES.clients_banking],
      },
      {
        name: 'Monitor de Procesos',
        icon: <EventRoundedIcon />,
        to: '/process/bam',
        expand: false,
        modules: [MODULES.bam],
      },
    ],
  },
  {
    name: 'Estado de procesos',
    to: null,
    icon: <AppsIcon />,
    expand: true,
    modules: [MODULES.expense_process, MODULES.income_process],
    children: [
      {
        name: 'Proceso de Gastos',
        icon: <GroupRoundedIcon />,
        to: '/expense-process',
        expand: false,
        modules: [MODULES.expense_process],
      },
      {
        name: 'Proceso de Ingresos',
        icon: <GroupRoundedIcon />,
        to: '/income-process',
        expand: false,
        modules: [MODULES.income_process],
      },
    ],
  },
  {
    name: 'Analítica presupuestaria',
    to: null,
    icon: <AppsIcon />,
    expand: true,
    modules: [MODULES.income_analytics, MODULES.expense_analytics, MODULES.results_analytics],
    children: [
      {
        name: 'Analítica de Gastos',
        icon: <GroupRoundedIcon />,
        to: '/expense-analytics',
        expand: false,
        modules: [MODULES.income_analytics],
      },
      {
        name: 'Analítica de Ingresos',
        icon: <GroupRoundedIcon />,
        to: '/income-analytics',
        expand: false,
        modules: [MODULES.expense_analytics],
      },
      {
        name: 'Cuenta de Resultados',
        icon: <GroupRoundedIcon />,
        to: '/results-analytics',
        expand: false,
        modules: [MODULES.results_analytics],
      },
    ],
  },
  {
    name: 'Gestión',
    icon: <GroupRoundedIcon />,
    to: null,
    expand: true,
    modules: [
      MODULES.users,
      MODULES.clients,
      MODULES.organizations,
      MODULES.modules,
      MODULES.permissions,
      MODULES.groups
    ],
    children: [
      {
        name: 'Usuarios',
        icon: <GroupRoundedIcon />,
        to: '/management/users/list',
        expand: false,
        modules: [MODULES.users],
      },
      {
        name: 'Clientes',
        icon: <GroupRoundedIcon />,
        to: '/management/clients/list',
        expand: false,
        modules: [MODULES.clients],
      },
      {
        name: 'Organizaciones',
        icon: <DomainIcon />,
        to: '/management/organizations/list',
        expand: false,
        modules: [MODULES.organizations],
      },
      {
        name: 'Empresas',
        icon: <BusinessIcon />,
        to: '/management/business/list',
        expand: false,
        modules: [MODULES.business],
      },
      {
        name: 'Módulos',
        icon: <AssignmentIcon />,
        to: '/management/modules/list',
        expand: false,
        modules: [MODULES.modules],
      },
      {
        name: 'Permisos',
        icon: <AssignmentLateIcon />,
        to: '/management/permissions/list',
        expand: false,
        modules: [MODULES.permissions],
      },
      {
        name: 'Grupos',
        icon: <GroupIcon />,
        to: '/management/groups/list',
        expand: false,
        modules: [MODULES.groups],
      },
      {
        name: 'Catálogos',
        icon: <AttachFileIcon />,
        to: '/management/catalog/list',
        expand: false,
        modules: [MODULES.catalog],
      },
    ],
  },
];
