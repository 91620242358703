import axios, { AxiosRequestConfig, CancelTokenSource } from 'axios';
// Redux
import store from '../../store';
import { showSnackbar } from '../../store/reducers/alertReducer';
// Types
import {/* MessageResponse,*/ ListResponse, Params/*, ListResponse*/ } from '../../types/Api';
import { Business, RandomBusiness/*,  RandomCustomer*/, BusinessBody } from '../../types/Business';
// App
import { MAKE_API_REQUEST, BANKING_SERVICE } from '../../consts/App';
import getListData from '../getListData';

const { dispatch } = store;

const defaultConfig: AxiosRequestConfig = {
  baseURL: BANKING_SERVICE,
  headers: { 'NO-HEADERS': true },
}

export async function getBusinesses(source: CancelTokenSource, params?: { paginate?: true } & Params): Promise<ListResponse<Business>>;
export async function getBusinesses(source: CancelTokenSource, params: { paginate: false } & Params): Promise<Business[]>;
export async function getBusinesses(source: CancelTokenSource, params: Params): Promise<Business[] | ListResponse<Business>>;
export async function getBusinesses(source: CancelTokenSource, params?: Params) {
  if (MAKE_API_REQUEST) {
    return axios.get<Business[]>('businesses/', { ...defaultConfig, params: params, cancelToken: source.token })
      .then(response => response.data);
  } else {
    const count = params?.limit || 10;
    // const response = getListData<Business>(count, () => new RandomBusiness(), true);
    const response = getListData<Business>(count, () => new RandomBusiness(), params?.paginate === false);
    return Promise.resolve(response);
  }
}

export async function registerBusiness(data: BusinessBody, source: CancelTokenSource) {
  if (MAKE_API_REQUEST) {
    return axios.post<Business>('businesses/', data, { ...defaultConfig, cancelToken: source.token })
      .then(response => {
        dispatch(showSnackbar({ message: 'Registro exitoso', type: 'success' }));
        return response.data;
      });
  } else {
    dispatch(showSnackbar({ message: 'Registro exitoso', type: 'success' }));
    return Promise.resolve(new RandomBusiness() as Business);
  }
};

export async function updateBusiness(id: number| null, data: Partial<BusinessBody>, source: CancelTokenSource) {
  if (MAKE_API_REQUEST) {
    return axios.put<Business>(`businesses/${id}/`, data, { ...defaultConfig, cancelToken: source.token })
      .then(response => {
        dispatch(showSnackbar({ message: 'Se actualizó la información con éxito', type: 'success' }));
        return response.data;
      });
  } else {
    dispatch(showSnackbar({ message: 'Se actualizó la información con éxito', type: 'success' }));
    return Promise.resolve(new RandomBusiness() as Business);
  }
}

// export async function registerUserGroup(data: UserGroupBody, source: CancelTokenSource) {
//   if (MAKE_API_REQUEST) {
//     const { user, organization, ...others } = data;
//     return axios.put<MessageResponse<'groups'>>(`users/${user}/organizations/${organization}/groups`, others, { ...defaultConfig, cancelToken: source.token })
//       .then(response => {
//         dispatch(showSnackbar({ message: 'Registro exitoso', type: 'success' }));
//         return response.data;
//       });
//   } else {
//     dispatch(showSnackbar({ message: 'Registro exitoso', type: 'success' }));
//     return Promise.resolve({ user: 'Correcto' });
//   }
// };
