import React, { useEffect, useState } from 'react';
// Material UI
import { makeStyles, createStyles } from '@material-ui/core/styles';
// import Grid from '@material-ui/core/Grid';
// import Typography from '@material-ui/core/Typography';
import DialogContent from '@material-ui/core/DialogContent';
import Fab from '@material-ui/core/Fab';
// import SvgIcon from '@material-ui/core/SvgIcon';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
// import DeleteIcon from '@material-ui/icons/Delete';
import GroupWorkIcon from '@material-ui/icons/GroupWork';
// Services
// import { iconAccessibilityProps } from '../../../services/test';
import { getUsers/*, deleteUser*/ } from '../../../services/API/UserServices';
import { getOrganizations } from '../../../services/API/OrganizationServices';
import { getGroups } from '../../../services/API/GroupServices';
// Types
import { User } from '../../../types/User';
import { Organization } from '../../../types/Organization';
import { Group } from '../../../types/Group';
import UserListComponentProps from '../../../types/propTypes/UserListComponent';
// Redux
import { useAppDispatch, useAppSelector } from '../../../consts/ReduxHooks';
import { UserFilters } from '../../../types/redux/filtersTypes';
import { showDialogEdit, dismissDialogEdit, dismissDialog, showDialog } from '../../../store/reducers/dialogReducer';
// App
import { FancyModal, FancyProgress } from '../../common/FancyComponents';
import FancyList, { ActionButton } from '../../common/FancyList';
import FancyFilter, { FilterElement } from '../../common/FancyFilter';
import UserFormComponent from './UserFormComponent';
// import { ReactComponent as SuperuserIcon } from '../../../assets/icons/superuser.svg';
// import { ReactComponent as UserIcon } from '../../../assets/icons/user.svg';
// Other
import axios, { CancelTokenSource } from 'axios';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(theme =>
  createStyles({
    button: {
      position: 'fixed',
      bottom: '3%',
      right: '3%'
    },
    // error: {
    //   color: theme.palette.error.contrastText,
    //   backgroundColor: theme.palette.error.main,
    // },
    // primary: {
    //   color: theme.palette.primary.contrastText,
    //   backgroundColor: theme.palette.primary.main,
    // },
  })
);

const source: CancelTokenSource[] = new Array(2).fill(axios.CancelToken.source());

export default function UserListComponent(props: UserListComponentProps) {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const dialog = useAppSelector(state => state.dialog);
  const filters = useAppSelector(state => state.filters);
  const [users, setUsers] = useState<User[]>([]);
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(false);
  const [loading, setLoading] = useState(false);
  // const [busy, setBusy] = useState(false);
  const [organizations, setOrganizations] = React.useState<Organization[]>([]);
  const [loadingOrganizations, setLoadingOrganizations] = useState(false);
  const [groups, setGroups] = React.useState<Group[]>([]);
  const [loadingGroups, setLoadingGroups] = useState(false);
  const limit = 30;
  const actionButtons: ActionButton<User>[] = [
    {
      text: 'Asignar grupos',
      icon: <GroupWorkIcon />,
      onClick: event => event.stopPropagation(),
      IconButtonProps: (user: User) => ({ component: Link, to: `/management/users/${user.mail}` }),
      MenuItemProps: (user: User) => ({ component: Link, to: `/management/users/${user.mail}` }),
      show: !props.hideGroups,
      // disabled: busy,
    },
    {
      text: 'Editar',
      icon: <EditIcon />,
      onClick: (event, user) => handleOptions('edit', user, event),
      show: !props.hideEdit,
      // disabled: busy,
    },
    // {
    //   text: 'Eliminar',
    //   icon: <DeleteIcon />,
    //   onClick: (event, user) => handleOptions('delete', user, event),
    //   show: !props.hideDelete,
    //   disabled: busy,
    // },
  ];
  const filterElements: FilterElement[] = [
    {
      type: 'Autocomplete',
      key: 'organization',
      name: 'Organización',
      loading: loadingOrganizations,
      autocompleteOptions: organizations.map(el => ({ value: el.id, name: el.name })),
      onChange: (organization) => {
        if (organization && typeof organization !== 'string') {
          fetchGroups(+organization.value);
        } else {
          setGroups([]);
        }
      },
    },
    {
      type: 'Autocomplete',
      key: 'group',
      name: 'Grupo',
      loading: loadingGroups,
      autocompleteOptions: groups.map(el => ({ value: el.group, name: el.group })),
    },
  ];

  async function fetchUsers(reset: boolean = false) {
    if (!props.hideList && filters && filters.id === new UserFilters().id) {
      if (reset) {
        setLoading(true);
      }
      const newPage = reset ? 0 : page;
      const params = {
        ...filters,
        limit: limit,
        offset: newPage * limit,
      }
      source[0] = axios.CancelToken.source();
      getUsers(source[0], params)
        .then(response => {
          setUsers(response);
          // setUsers(prev => reset ? response.results : prev.concat(response.results));
          setPage(prev => reset ? 1 : prev + 1);
          // setHasMore(!!response.next);
          setLoading(false);
        })
        .catch(error => {
          if (!error.isCanceled) {
            setUsers([]);
            setPage(0);
            setHasMore(false);
            setLoading(false);
          }
        });
    }
  };

  async function fetchOrganizations() {
    setLoadingOrganizations(true);
    const params = {
      paginate: false as false
    }
    getOrganizations(source[1], params)
      .then(response => {
        setOrganizations(response);
        setLoadingOrganizations(false);
      })
      .catch(error => {
        if (!error.isCanceled) {
          setLoadingOrganizations(false);
        }
      });
  };

  async function fetchGroups(organizationId: number) {
    setLoadingGroups(true);
    const params = {
      // paginate: false as false,
      organization: organizationId,
    }
    getGroups(source[1], params)
      .then(response => {
        setGroups(response);
        setLoadingGroups(false);
      })
      .catch(error => {
        if (!error.isCanceled) {
          setGroups([]);
          setLoadingGroups(false);
        }
      });
  };

  useEffect(() => {
    source[1] = axios.CancelToken.source();
    if (!props.hideList) {
      fetchOrganizations();
    }
    return () => {
      source.forEach(s => s.cancel());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchUsers(true);
    return () => {
      source[0].cancel();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const handleClickOpen = () => {
    dispatch(showDialog());
  };

  const handleClose = () => {
    dispatch(dismissDialog());
    dispatch(dismissDialogEdit());
  };

  const handleSubmit = () => {
    fetchUsers(true);
    handleClose();
  };

  const handleOptions = (option: 'edit' | 'delete', user: User, event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation();
    switch (option) {
      case 'edit':
        setSelectedUser(user);
        dispatch(showDialogEdit());
        break;
      // case 'delete':
      //   setBusy(true);
      //   deleteUser(user.userId, source[1])
      //     .then(() => {
      //       fetchUsers(true);
      //       setBusy(false);
      //     })
      //     .catch(error => {
      //       if (!error.isCanceled) {
      //         setBusy(false);
      //       }
      //     })
      //   break;
    }
  };

  // const ExpandContent = ({ user }: { user: User }) => (
  //   <Grid container spacing={1}>
  //     <Grid item xs={12} sm={6}>
  //       <Typography variant="subtitle2"><strong>Organización</strong></Typography>
  //       <Typography variant="body2">
  //         {user.organizationName}
  //       </Typography>
  //     </Grid>
  //     {
  //       user.group && <Grid item xs={12} sm={6}>
  //         <Typography variant="subtitle2"><strong>Grupo</strong></Typography>
  //         <Typography variant="body2">
  //           {user.group}
  //         </Typography>
  //       </Grid>
  //     }
  //     {
  //       user.description && <Grid item xs={12} sm={6}>
  //         <Typography variant="subtitle2"><strong>Descripción</strong></Typography>
  //         <Typography variant="body2">
  //           {user.description}
  //         </Typography>
  //       </Grid>
  //     }
  //   </Grid>
  // );

  return (
    <React.Fragment>
      {
        !props.hideList && <React.Fragment>
          {
            filters && filters.id === new UserFilters().id && <FancyFilter
              noSearch
              // busy={busy}
              defaultValues={new UserFilters()}
              formProps={{ "aria-label": 'Filtros lista de usuarios' }}
              elements={filterElements}
            />
          }
          {
            !loading && <FancyList<User>
              infinite
              divider
              // expand
              elements={users}
              hasMore={hasMore}
              fetchElements={fetchUsers}
              idKey="mail"
              ListProps={{ "aria-label": "Usuarios" }}
              // AvatarProps={user => ({
              //   className: user.is_active ? classes.primary : classes.error,
              //   children: user.is_superuser ?
              //     <SvgIcon
              //       fontSize="large"
              //       component={SuperuserIcon}
              //       {...iconAccessibilityProps('superusuario', user.first_name + ' ' + user.last_name)}
              //     /> :
              //     <SvgIcon
              //       fontSize="large"
              //       component={UserIcon}
              //       {...iconAccessibilityProps('usuario', user.first_name + ' ' + user.last_name)}
              //     />
              // })}
              ListItemTextProps={user => ({
                primary: `${user.fullName} ${user.lastName}`,
                secondary: user.mail,
              })}
              actionButtons={actionButtons}
            // expandContent={user => <ExpandContent user={user} />}
            />
          }
          {loading && <FancyProgress aria-label="Lista de usuarios" color="primary" size={100} />}
        </React.Fragment>
      }
      {
        !props.hideCreate && <Fab
          color="primary"
          aria-label="Agregar"
          className={classes.button}
          onClick={handleClickOpen}
        >
          <AddIcon />
        </Fab>
      }
      <FancyModal
        open={dialog.open || dialog.openEdit}
        onClose={handleClose}
        aria-label="Formulario de usuario"
      >
        <DialogContent>
          <UserFormComponent
            onSubmit={handleSubmit}
            data={dialog.openEdit ? selectedUser : null}
          />
        </DialogContent>
      </FancyModal>
    </React.Fragment>
  );
}
