import React from 'react';
// App
import UsersRouting from './UsersRouting';
import OrganizationsRouting from './OrganizationRouting';
import ModulesRouting from './ModulesRouting';
import PermissionsRouting from './PermissionsRouting';
import GroupsRouting from './GroupsRouting';
import ClientsRouting from './ClientsRouting';
import BusinessRouting from './BusinessRouting';
import CatalogRouting from './CatalogRouting';
import MODULES from '../../../consts/Modules';
// Services
import hasModule from '../../../services/hasModule';
// Other
import { Switch, Route, RouteComponentProps, Redirect } from 'react-router-dom';

export default function ManagementRouting(props: RouteComponentProps) {

  // Default redirect
  const redirectTo = () => {
    if (hasModule(MODULES.users)) {
      return props.match.path + '/users';
    } else if (hasModule(MODULES.organizations)) {
      return props.match.path + '/organizations';
    } else if (hasModule(MODULES.modules)) {
      return props.match.path + '/modules';
    } else if (hasModule(MODULES.groups)) {
      return props.match.path + '/groups';
    } else if (hasModule(MODULES.permissions)) {
      return props.match.path + '/permissions';
    } else if (hasModule(MODULES.clients)) {
      return props.match.path + '/clients';
    } else if (hasModule(MODULES.business)) {
      return props.match.path + '/business';
    } else if (hasModule(MODULES.catalog)) {
      return props.match.path + '/catalog';
    }
    return '/';
  }

  return (
    <Switch>
      {
        hasModule(MODULES.organizations) && <Route
          path={props.match.path + '/organizations'}
          component={OrganizationsRouting}
        />
      }
      {
        hasModule(MODULES.modules) && <Route
          path={props.match.path + '/modules'}
          component={ModulesRouting}
        />
      }
      {
        hasModule(MODULES.groups) && <Route
          path={props.match.path + '/groups'}
          component={GroupsRouting}
        />
      }
      {
        hasModule(MODULES.permissions) && <Route
          path={props.match.path + '/permissions'}
          component={PermissionsRouting}
        />
      }
      {
        hasModule(MODULES.users) && <Route
          path={props.match.path + '/users'}
          component={UsersRouting}
        />
      }
      {
        hasModule(MODULES.clients) && <Route
          path={props.match.path + '/clients'}
          component={ClientsRouting}
        />
      }
      {
        hasModule(MODULES.business) && <Route
          path={props.match.path + '/business'}
          component={BusinessRouting}
        />
      }
      {
        hasModule(MODULES.catalog) && <Route
          path={props.match.path + '/catalog'}
          component={CatalogRouting}
        />
      }

      <Redirect to={redirectTo()} />
    </Switch>
  );
}
