import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AuthReducer } from '../../types/redux/authTypes';
import { AuthUser } from '../../types/Auth';

// Can´t instantiate new classes with new Class() as initalState,
// use spread operator to convert to object instead or don´t use Classes
const initialState: AuthReducer = { ...new AuthReducer() };

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login: state => {
      state.loggeIn = true
    },
    logout: state => initialState,
    setAuthenticatedLoading: (state, action: PayloadAction<boolean>) => {
      state.authenticatedLoading = action.payload
    },
    setAuthUser: (state, action: PayloadAction<AuthUser>) => {
      state.user = action.payload
    }
  }
});

export const { login, logout, setAuthenticatedLoading, setAuthUser } = authSlice.actions;

export default authSlice.reducer;
