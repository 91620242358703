import axios, { AxiosRequestConfig, CancelTokenSource } from "axios";
// App
import { HISTORY } from "../../consts/Router";
import { DRIVE_SERVICE, MAKE_API_REQUEST } from "../../consts/App";
// Types
import { AuthenticationResponse, RandomAuthenticationResponse } from "../../types/BPMN";
// Redux
import store from "../../store/store";
import { handlerListDiagramBpmn } from "../../store/reducers/diagramsReducer";

const { dispatch } = store;

const defaultConfig: AxiosRequestConfig = {
  baseURL: DRIVE_SERVICE,
  headers: { 'NO-HEADERS': true },
}

export async function googleAuthentication(source: CancelTokenSource) {
  if (MAKE_API_REQUEST) {
    const host = window.location.origin;
    const config: AxiosRequestConfig = {
      ...defaultConfig,
      cancelToken: source.token,
      params: { callback: `${host}/google-account`},
    }
    return axios.get<AuthenticationResponse>('/authentication', config)
      .then(response => {
        console.log("Drive")
        // window.open(response.data.url);
        // window.close()
        window.location.replace(response.data.url)
        dispatch(handlerListDiagramBpmn(true));
        return response.data;
      });
  } else {
    const response = new RandomAuthenticationResponse();
    HISTORY.push('/google-account');
    dispatch(handlerListDiagramBpmn(true));
    return Promise.resolve(response);
  }
};

export async function googleCallback(params: string | null, source: CancelTokenSource) {
  if (MAKE_API_REQUEST) {
    const config: AxiosRequestConfig = {
      ...defaultConfig,
      cancelToken: source.token,
      params: { code: params },
    }
    return axios.get<AuthenticationResponse>('/callback', config)
      .then(response => {
        // window.open(response.data.url);
        return response.data;
      });
  } else {
    const response = new RandomAuthenticationResponse();
    return Promise.resolve(response);
  }
};