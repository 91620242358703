import { FilterType } from "../../types/redux/filtersTypes";
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = null as FilterType;

export const filtersSlice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    setFilters: (state, action: PayloadAction<Exclude<FilterType, null>>) => action.payload,
    resetFilters: state => initialState,
  }
});

export const { setFilters, resetFilters } = filtersSlice.actions;

export default filtersSlice.reducer;
