// Reducer
export class SnackbarReducer {
  open: boolean = false;
  message: string = '';
  type?: 'error' | 'info' | 'success' | 'warning';
}

export interface SnackbarAction {
  message: string;
  type?: 'error' | 'info' | 'success' | 'warning';
}