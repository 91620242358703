// v1.0
import React from 'react';
// Material UI
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MoreVertIcon from '@material-ui/icons/MoreVert';
// App
import { FancyTooltip, useFancyStyles } from '../FancyComponents';
import { spreadProps } from './utils';
// Types
import { FancyActionButtonProps } from './types';

const FancyActionButton = <T extends any>(props: FancyActionButtonProps<T>) => {
  const classes = useFancyStyles();
  const theme = useTheme();
  const matchesDesktop = useMediaQuery(theme.breakpoints.up('sm'));
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const actions = props.actions.filter(button =>
    button.show === undefined || (typeof button.show === 'boolean' ? button.show : button.show(props.element))
  );
  const actionsButtons = actions.filter(button => !button.menuOnly);

  const menuClickHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    if (props.onClick) {
      props.onClick(event);
    }
  };

  const menuCloseHandler = () => {
    setAnchorEl(null);
    if (props.onClose) {
      props.onClose();
    }
  };

  return <React.Fragment>
    {/* Desktop or if there is only 1 action */}
    <Hidden xsDown={actions.length > 1}>
      {
        actionsButtons.map((button, index) => {
          return <FancyTooltip
            key={index}
            title={typeof button.text === 'string' ? button.text : button.text(props.element)}
          >
            <span>
              <IconButton
                color="primary"
                size={matchesDesktop ? 'medium' : 'small'}
                aria-label={typeof button.text === 'string' ? button.text : button.text(props.element)}
                {...spreadProps(props.element, button.IconButtonProps)}
                onClick={(event: any) => button.onClick(event, props.element)}
                disabled={button.disabled && (typeof button.disabled === 'boolean' ? button.disabled : button.disabled(props.element))}
                className={[(index !== actionsButtons.length - 1) ? classes.mr_1 : '', spreadProps(props.element, button.IconButtonProps)?.className].join(' ')}
              >
                {typeof button.icon === 'function' ? button.icon(props.element) : button.icon}
              </IconButton>
            </span>
          </FancyTooltip>
        })
      }
    </Hidden>

    {/* Mobile */}
    <Hidden xsDown={actions.length <= 1} smUp>
      <IconButton
        color="primary"
        onClick={(event) => menuClickHandler(event)}
        disabled={props.disabled}
        size="small"
      >
        {props.icon ? props.icon : <MoreVertIcon />}
      </IconButton>
    </Hidden>
    <Menu
      anchorEl={anchorEl}
      open={!!anchorEl}
      onClose={menuCloseHandler}
    >
      {
        actions.map((action, index) => {
          return <MenuItem
            {...spreadProps(props.element, action.MenuItemProps)}
            key={index}
            button={true}
            disabled={action.disabled && (typeof action.disabled === 'boolean' ? action.disabled : action.disabled(props.element))}
            onClick={(event: any) => {
              action.onClick(event, props.element)
              menuCloseHandler();
            }}
          >
            <ListItemIcon>{typeof action.icon === 'function' ? action.icon(props.element) : action.icon}</ListItemIcon>
            <ListItemText primary={typeof action.text === 'string' ? action.text : action.text(props.element)} />
          </MenuItem>
        })
      }
    </Menu>
  </React.Fragment>;
};

export default FancyActionButton;