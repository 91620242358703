import React from 'react';
// App
import DashboardComponent from '../../layouts/DashboardComponent';
import BankAccountListComponent from '../../views/Banking/BankAccountListComponent';
import RegisterAccountComponent from '../../views/Banking/RegisterAccountComponent';
import IframeComponent from '../../common/IframeComponent';
import { SUPERSET_SERVICE } from '../../../consts/App';
// Redux
import { useAppSelector } from '../../../consts/ReduxHooks';
// Others
import { Switch, Route, RouteComponentProps, Redirect } from 'react-router-dom';

export default function BankingRouting(props: RouteComponentProps) {
  const user = useAppSelector(state => state.auth.user);

  return (
    <DashboardComponent
      title="Banking"
      children={<Switch>
        <Route exact path={props.match.path + '/list'} component={BankAccountListComponent} />
        <Route exact path={props.match.path + '/register'} component={RegisterAccountComponent} />
        <Route exact path={props.match.path + '/dashboard'} render={() => <IframeComponent
          src={`${SUPERSET_SERVICE}login?username=${user.idx}&redirect=/superset/dashboard/banking/?standalone=true`}
          title="Dashboard"
        />} />
        <Redirect to={props.match.path + '/list'} />
      </Switch>}
    />
  );
}
