import React from 'react';
// Redux
import { useAppDispatch } from '../../../consts/ReduxHooks';
import { UserFilters } from '../../../types/redux/filtersTypes';
import { setFilters, resetFilters } from '../../../store/reducers/filtersReducer';
// App
import DashboardComponent from '../../layouts/DashboardComponent';
import UserListComponent from '../../views/Users/UsersListComponent';
import GroupListComponent from '../../views/Users/GroupsListComponent';
import MODULES from '../../../consts/Modules';
import PERMISSIONS from '../../../consts/Permissions';
// Services
import hasModule from '../../../services/hasModule';
// Other
import { Switch, Route, Redirect, RouteComponentProps } from 'react-router-dom';

export default function UsersRouting(props: RouteComponentProps) {
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    dispatch(setFilters({ ...new UserFilters() }));
    return () => {
      dispatch(resetFilters());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DashboardComponent
      title="Usuarios"
      children={<Switch>
        <Route exact path={props.match.path + '/list'} render={props => <UserListComponent
          hideList={!hasModule(MODULES.users, PERMISSIONS.list)}
          hideCreate={!hasModule(MODULES.users, PERMISSIONS.create)}
          hideEdit={!hasModule(MODULES.users, PERMISSIONS.update)}
          hideGroups={!hasModule(MODULES.users_groups, PERMISSIONS.update)}
        />} />
        {
          hasModule(MODULES.users_groups, PERMISSIONS.update) && <Route
            exact
            path={props.match.path + '/:id'}
            component={GroupListComponent}
          />
        }

        <Redirect to={props.match.path + '/list'} />
      </Switch>}
    />
  );
}
