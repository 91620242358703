import { createMuiTheme } from '@material-ui/core/styles';

const THEME_CONFIG = createMuiTheme({
  typography: {
    fontFamily: ['Segoe UI'].join(','),
    h1: {
      fontSize: '2.5rem',
    },
    h2: {
      fontSize: '1.875rem',
    },
    h3: {
      fontSize: '1.5625rem',
    },
    h4: {
      fontSize: '1rem',
    },
    h5: {
      fontSize: '0.875rem',
    },
    h6: {
      fontSize: '0.875rem',
    },
  },


  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: '#c29321',
      // dark: will be calculated from palette.primary.main,
      contrastText: '#ffffff',
    },
    secondary: {
      light: '#387a54',
      main: '#004d2b ',
      dark: '#002500',
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    success: {
      // light: will be calculated from palette.primary.main,
      main: '#97BB89',
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    error: {
      // light: will be calculated from palette.primary.main,
      main: '#C45B5B',
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    // Used by `getContrastText()` to maximize the contrast between
    // the background and the text.
    contrastThreshold: 3,
    // Used by the functions below to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2,
  },
});

export const THEME = createMuiTheme({
  ...THEME_CONFIG,
  overrides: {
    MuiPickersDesktopDateRangeCalendar: {
      rangeCalendarContainer: {
        borderRight: 'none !important'
      }
    },
    MuiPickersCalendarHeader: {
      root: {
        backgroundColor: THEME_CONFIG.palette.primary.main,
        color: THEME_CONFIG.palette.primary.contrastText,
        marginTop: 0,
        marginBottom: 0,
        paddingTop: THEME_CONFIG.spacing(2),
        paddingBottom: THEME_CONFIG.spacing(1),
      }
    },
    MuiPickersArrowSwitcher: {
      root: {
        backgroundColor: THEME_CONFIG.palette.primary.main,
        color: THEME_CONFIG.palette.primary.contrastText
      }
    },
    MuiPickersCalendar: {
      daysHeader: {
        backgroundColor: THEME_CONFIG.palette.primary.main,
      },
      weekDayLabel: {
        color: THEME_CONFIG.palette.primary.contrastText
      }
    },
    MuiMenuItem: {
      root: {
        fontSize: THEME_CONFIG.typography.button.fontSize,
        '&:hover': {
          color: THEME_CONFIG.palette.primary.main,
        }
      }
    }
  },
});
