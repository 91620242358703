import React, { useState, useEffect } from 'react';
// Material UI
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
// Forms
import { useForm, Controller } from 'react-hook-form';
// App
import { FancyTextInput, FancyButton } from '../../common/FancyComponents';
// Services
import { registerCatalogRegister, updateCatalogRegister } from '../../../services/API/CatalogServices';
// Types
import { GeneralFormProps } from '../../../types/propTypes/misc';
import { Catalog } from '../../../types/Catalog';
// Others
import axios, { CancelTokenSource } from 'axios';

let source: CancelTokenSource = axios.CancelToken.source();

export default function CatalogRegisterFormComponent(props: GeneralFormProps<any> & { catalog: Catalog }) {
  const methods = useForm<any>();
  const { handleSubmit, control, errors, reset } = methods;
  const [busy, setBusy] = useState(false);
  const isEdit = !!props.data;

  useEffect(() => {
    source = axios.CancelToken.source();
    if (props.data) {
      reset(props.data);
    }
    return () => {
      source.cancel();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = (data: any) => {
    setBusy(true);
    let service;
    if (props.data) {
      service = updateCatalogRegister(props.catalog.name, props.data.id, data, source);
    } else {
      service = registerCatalogRegister(props.catalog.name, data, source);
    }
    service
      .then(response => {
        setBusy(false);
        if (props.onSubmit) {
          props.onSubmit(response);
        }
      })
      .catch(error => {
        if (!error.isCanceled) {
          setBusy(false);
        }
      });
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} aria-label="Registro de Catálogo">
      <Grid container direction="row" justify="center" spacing={1}>
        <Grid item xs={12}>
          <Typography variant="h6" align="center">
            Información del registro de {props.catalog.name}
          </Typography>
        </Grid>
        {
          props.catalog.fields.map(field => <Grid item xs={12} key={field}>
            <Controller
              name={field}
              control={control}
              defaultValue=""
              render={props => <FancyTextInput
                {...props}
                error={!!errors[field]}
                helperText={errors[field] && errors[field].message}
                label={field}
                id={`registerForm_${field}`}
              />}
            />
          </Grid>)
        }
        <Grid item xs={12} container direction="row" justify="center">
          <FancyButton
            variant="contained"
            color='primary'
            type="submit"
            disabled={busy}
            loading={busy}
          >
            {isEdit ? 'Editar' : 'Crear'}
          </FancyButton>
        </Grid>
      </Grid>
    </form>
  );
}
