import React from 'react'
// Material UI
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
// Types
import FloatingButtonProps from '../../types/propTypes/FloatingButton';

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      position: 'fixed',
      bottom: theme.spacing(1),
      right: theme.spacing(1),
    }
  }),
);

function FloatingButton(props: FloatingButtonProps) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Fab color={props.color} variant={props.variant} size={props.size} onClick={props.onClick}>
        {props.icon}
        {props.text}
      </Fab>
    </div>
  )
}

export default FloatingButton
