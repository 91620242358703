import React, { useState, useEffect } from 'react';
// Material UI
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
// Forms
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers';
// App
import { FancyTextInput, FancyButton } from '../../common/FancyComponents';
import VirtualizedAutocompleteComponent from '../../common/VirtualizedAutocompleteComponent';
// Services
import { registerModule, updateModule } from '../../../services/API/ModuleServices';
import { getPermissions } from '../../../services/API/PermissionServices';
import { getOrganizations } from '../../../services/API/OrganizationServices';
// Types
import { GeneralFormProps } from '../../../types/propTypes/misc';
import { Module, ModuleForm, ModuleBody, ModuleFormDefault } from '../../../types/Module';
import { Permission } from '../../../types/Permission';
import { Organization } from '../../../types/Organization';
// Others
import axios, { CancelTokenSource } from 'axios';

let source: CancelTokenSource = axios.CancelToken.source();

export default function ModuleFormComponent(props: GeneralFormProps<Module>) {
  const isEdit = !!props.data;
  const schema = yup.object().shape({
    name: isEdit ? yup.mixed() : yup.string().required(),
    description: isEdit ? yup.mixed() : yup.string(),
    permissions: yup.array().required(),
    organizations: yup.array().required(),
  });
  const methods = useForm<ModuleForm>({
    resolver: yupResolver(schema),
    defaultValues: new ModuleFormDefault(),
  });
  const { handleSubmit, control, errors, reset } = methods;
  const [busy, setBusy] = useState(false);
  const [permissions, setPermissions] = useState<Permission[]>([]);
  const [loadingPermissions, setLoadingPermissions] = useState(false);
  const [organizations, setOrganizations] = useState<Organization[]>([]);
  const [loadingOrganizations, setLoadingOrganizations] = useState(false);

  async function fetchPermissions() {
    setLoadingPermissions(true);
    const params = {
      paginate: false as false
    }
    getPermissions(source, params)
      .then(response => {
        setPermissions(response);
        setLoadingPermissions(false);
      })
      .catch(error => {
        if (!error.isCanceled) {
          setLoadingPermissions(false);
        }
      });
  };

  async function fetchOrganizations() {
    setLoadingOrganizations(true);
    const params = {
      paginate: false as false
    }
    getOrganizations(source, params)
      .then(response => {
        setOrganizations(response);
        setLoadingOrganizations(false);
      })
      .catch(error => {
        if (!error.isCanceled) {
          setLoadingOrganizations(false);
        }
      });
  };

  useEffect(() => {
    source = axios.CancelToken.source();
    fetchPermissions();
    fetchOrganizations();
    if (isEdit && props.data) {
      reset({
        organizations: props.data.organizations,
        permissions: props.data.permissions,
      });
    }
    return () => {
      source.cancel();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = (data: ModuleForm) => {
    setBusy(true);
    const dataBody: ModuleBody = {
      permissions: data.permissions.map(p => p.id),
      organizations: data.organizations.map(p => p.id),
    }
    if (!isEdit) {
      dataBody.name = data.name;
      dataBody.description = data.description;
    }
    const service = isEdit ? updateModule(props.data?.id || 0, dataBody, source)
      : registerModule(dataBody, source);
    service
      .then(response => {
        setBusy(false);
        if (props.onSubmit) {
          props.onSubmit(response);
        }
      })
      .catch(error => {
        if (!error.isCanceled) {
          setBusy(false);
        }
      });
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} aria-label="Modulo">
      <Grid container direction="row" justify="center" spacing={1}>
        <Grid item xs={12}>
          <Typography variant="h6" align="center">Información del Módulo</Typography>
        </Grid>
        {
          !isEdit && <React.Fragment>
            <Grid item xs={12}>
              <Controller
                render={props => <FancyTextInput
                  {...props}
                  error={!!errors.name}
                  helperText={errors.name && errors.name.message}
                  label="Nombre"
                  id="moduleForm_name"
                />}
                control={control}
                name="name"
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                render={props => <FancyTextInput
                  {...props}
                  error={!!errors.description}
                  helperText={errors.description && errors.description.message}
                  label="Descripción"
                  id="moduleForm_description"
                  multiline
                  rows={2}
                />}
                control={control}
                name="description"
              />
            </Grid>
          </React.Fragment>
        }
        <Grid item xs={12}>
          <Controller
            render={props => <VirtualizedAutocompleteComponent<Permission, true>
              {...props}
              multiple
              disableCloseOnSelect
              options={permissions}
              onChange={(event, newValue) => props.onChange(newValue)}
              getOptionSelected={(option, selected) => option.id === selected.id}
              getOptionLabel={option => option.name}
              renderOption={option => <Typography noWrap>{option.name}</Typography>}
              loading={loadingPermissions}
              aria-label="Permisos"
              renderInput={params => <FancyTextInput
                {...params}
                label="Permisos"
                id="moduleForm_permissions"
                error={!!errors.permissions}
                helperText={errors.permissions && errors.permissions.message}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {loadingPermissions ? <CircularProgress aria-label="Permisos" color="inherit" size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
              />}
            />}
            control={control}
            name="permissions"
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            render={props => <VirtualizedAutocompleteComponent<Organization, true>
              {...props}
              multiple
              disableCloseOnSelect
              options={organizations}
              onChange={(event, newValue) => props.onChange(newValue)}
              getOptionSelected={(option, selected) => option.id === selected.id}
              getOptionLabel={option => option.name}
              renderOption={option => <Typography noWrap>{option.name}</Typography>}
              loading={loadingOrganizations}
              aria-label="Organizaciones"
              renderInput={params => <FancyTextInput
                {...params}
                label="Organizaciones"
                id="moduleForm_organization"
                error={!!errors.organizations}
                helperText={errors.organizations && errors.organizations.message}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {loadingOrganizations ? <CircularProgress aria-label="Organizaciones" color="inherit" size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
              />}
            />}
            control={control}
            name="organizations"
          />
        </Grid>
        <Grid item xs={12} container direction="row" justify="center">
          <FancyButton
            variant="contained"
            color='primary'
            type="submit"
            disabled={busy}
            loading={busy}
          >
            {isEdit ? 'Guardar' : 'Crear'}
          </FancyButton>
        </Grid>
      </Grid>
    </form>
  );
}