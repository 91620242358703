import axios, { CancelTokenSource } from 'axios';
// Redux
import store from '../../store';
import { showSnackbar } from '../../store/reducers/alertReducer';
// Types
// import { ListResponse } from '../../types/Api';
import { Module, ModuleParams, RandomModule, ModuleBody } from '../../types/Module';
// App
import { MAKE_API_REQUEST, USERMANAGEMENT_SERVICE } from '../../consts/App';
import getListData from '../getListData';

const { dispatch } = store;
const defaultConfig = {
  baseURL: USERMANAGEMENT_SERVICE,
}

// export async function getModules(source: CancelTokenSource, params?: { paginate?: true } & ModuleParams): Promise<ListResponse<Module>>;
// export async function getModules(source: CancelTokenSource, params: { paginate: false } & ModuleParams): Promise<Module[]>;
// export async function getModules(source: CancelTokenSource, params: ModuleParams): Promise<Module[] | ListResponse<Module>>;
export async function getModules(source: CancelTokenSource, params?: ModuleParams) {
  if (MAKE_API_REQUEST) {
    return axios.get<Module[]>('modules/', { ...defaultConfig, params: params, cancelToken: source.token })
      .then(response => response.data);
  } else {
    const count = params?.limit || 10;
    // const response = getListData<Module>(count, () => new RandomModule(), params?.paginate === false);
    const response = getListData<Module>(count, () => new RandomModule(), true);
    return Promise.resolve(response);
  }
}

export async function registerModule(data: ModuleBody, source: CancelTokenSource) {
  if (MAKE_API_REQUEST) {
    return axios.post<Module>('modules/', data, { ...defaultConfig, cancelToken: source.token })
      .then(response => {
        dispatch(showSnackbar({ message: 'Registro exitoso', type: 'success' }));
        return response.data;
      });
  } else {
    dispatch(showSnackbar({ message: 'Registro exitoso', type: 'success' }));
    return Promise.resolve(new RandomModule() as Module);
  }
};

export async function updateModule(id: number, data: ModuleBody, source: CancelTokenSource) {
  if (MAKE_API_REQUEST) {
    return axios.put<Module>(`modules/${id}/`, data, { ...defaultConfig, cancelToken: source.token })
      .then(response => {
        dispatch(showSnackbar({ message: 'Se actualizó la información con éxito', type: 'success' }));
        return response.data;
      });
  } else {
    dispatch(showSnackbar({ message: 'Se actualizó la información con éxito', type: 'success' }));
    return Promise.resolve(new RandomModule() as Module);
  }
};