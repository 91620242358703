//v1.0
import React from 'react';
// Material UI
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import IconButton from '@material-ui/core/IconButton';
import HomeIcon from '@material-ui/icons/Home';
// App
import { BREADCRUMBS } from '../../consts/Breadcrumbs';
// Other
import { Route } from 'react-router';
import { Link as RouterLink, matchPath } from 'react-router-dom';

export default function RouterBreadcrumbs() {
  return (
    <Route>
      {
        ({ location }) => {
          const pathnames = location.pathname.split('/').filter((x) => x).filter((x) => x !== 'list');
          return (
            <Breadcrumbs aria-label="breadcrumb" color="inherit">
              <IconButton color="inherit" component={RouterLink} to="/" size="small">
                <HomeIcon fontSize="small" />
              </IconButton>
              {
                pathnames.map((value, index) => {
                  const last = index === pathnames.length - 1;
                  const to = `/${pathnames.slice(0, index + 1).join('/')}`;
                  const selectedKey = Object.keys(BREADCRUMBS).find(key => {
                    const match = matchPath(to, { path: key, exact: true, strict: true });
                    return !!match && match.isExact;
                  }) || '';

                  return last ? (
                    <Typography color="primary" key={to}>
                      {BREADCRUMBS[selectedKey]}
                    </Typography>
                  ) : (
                      <Link component={RouterLink} color="inherit" to={to} key={to}>
                        {BREADCRUMBS[selectedKey]}
                      </Link>
                    );
                })
              }
            </Breadcrumbs>
          );
        }
      }
    </Route>
  );
}
