import React from 'react';
// Material ui
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
// import ZoomInIcon from '@material-ui/icons/ZoomIn';
import InsertDriveFileOutlinedIcon from '@material-ui/icons/InsertDriveFileOutlined';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
// Redux
import { useAppSelector, useAppDispatch } from '../../../consts/ReduxHooks';
import { selectDiagramBpmn } from '../../../store/reducers/diagramsReducer';
// App
import FancyList/*, { ActionButton }*/ from '../../common/FancyList';
// Services
import { driveFiles } from '../../../services/API/BpmnServices';
// Types
import { DiagramsBpmn, DiagramsBpmnList } from '../../../types/BPMN';
// Other
import axios, { CancelTokenSource } from 'axios';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fileSelected: {
      fontWeight: 'bold'
    },
    icon: {
      [theme.breakpoints.down('xs')]: {
        minWidth: 40
      }
    }
  })
);

let source: CancelTokenSource = axios.CancelToken.source();

export default function BpmnList() {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const classes = useStyles();
  const fileList = useAppSelector(state => convertFileList(state.diagram.fileList.filter(file => file.name)));
  const fileSelected = useAppSelector(state => state.diagram.fileSelected);
  const handlerList = useAppSelector(state => state.diagram.handlerList);
  const [expanded, setExpanded] = React.useState<string[]>([]);
  // const actionButtons: ActionButton<DiagramsBpmnList>[] = [
  //   {
  //     text: 'Ver',
  //     icon: <ZoomInIcon />,
  //     onClick: (event, file) => handleViewFile(file),
  //   },
  // ];

  React.useEffect(() => {
    source = axios.CancelToken.source();
    driveFiles({ parent: '1tjTM2mY2YCXDT1Jx3gg3Iwf8GLIJ4D35' }, source);
    return () => {
      source.cancel();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handlerList]);

  function handleViewFile(file: DiagramsBpmn) {
    dispatch(selectDiagramBpmn(file))
    history.push('/process/models/files')
  }

  function findFileList(files: DiagramsBpmnList[], levels: string[], file: DiagramsBpmnList, arrayLevel: number) {
    for (const f of files) {
      if (f.number === levels[arrayLevel - 1]) {
        f.children = f.children || [];
        if (f.level === file.level - 1) {
          f.children?.push(file);
        } else {
          findFileList(f.children || [], levels, file, arrayLevel + 1);
        }
      }
    }
  }

  function convertFileList(files: DiagramsBpmn[]): DiagramsBpmnList[] {
    const filesList: DiagramsBpmnList[] = [];
    for (const file of files) {
      const levels = file.name.split(' ')[0].split('.').filter(level => level);
      const level = levels.length;
      const fileList: DiagramsBpmnList = {
        ...file,
        level: level,
        number: levels[levels.length - 1],
      }
      if (level > 1) {
        findFileList(filesList, levels, fileList, 1);
      } else {
        filesList.push(fileList);
      }
    }
    return filesList;
  }

  const handleExpandedClick = (event: React.ChangeEvent<{}>, file: DiagramsBpmnList) => {
    event.stopPropagation();
    const isExpanded = expanded.includes(file.id);
    setExpanded(prev => isExpanded ? prev.filter(id => id !== file.id) : prev.concat(file.id));
  }

  return (
    <FancyList<DiagramsBpmnList>
      disablePadding
      recursive
      elements={fileList}
      ListItemProps={(file: DiagramsBpmnList) => ({ onClick: () => handleViewFile(file) })}
      ListItemIconProps={file => ({
        children: (file.id === fileSelected.id) ? <InsertDriveFileIcon /> : <InsertDriveFileOutlinedIcon />,
        className: classes.icon
      })}
      ListItemTextProps={file => ({
        primary: file.name,
        primaryTypographyProps: { className: (file.id === fileSelected.id) ? classes.fileSelected : undefined },
      })}
      // actionButtons={actionButtons}
      expandedElements={expanded}
      hasChildren={file => !!file.children && file.children.length > 0}
      onRecursiveClick={handleExpandedClick}
      recursiveChildren={(file, ListItem, ListItemProps) => <List component="div" disablePadding>
        {
          file.children && file.children.map(child =>
            <ListItem {...ListItemProps} element={child} key={child.id} />
          )
        }
      </List>}
    />
  )
}
