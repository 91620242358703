import React, { useState, useEffect } from 'react';
// Material UI
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
// Forms
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers';
// App
import { FancyTextInput, FancyButton } from '../../common/FancyComponents';
// Services
import { registerOrganization } from '../../../services/API/OrganizationServices';
// Types
import { GeneralFormProps } from '../../../types/propTypes/misc';
import { Organization, OrganizationBody } from '../../../types/Organization';
// Others
import axios, { CancelTokenSource } from 'axios';

const schema = yup.object().shape({
  name: yup.string().required(),
});

let source: CancelTokenSource = axios.CancelToken.source();

export default function OrganizationFormComponent(props: GeneralFormProps<Organization>) {
  const methods = useForm<OrganizationBody>({
    resolver: yupResolver(schema),
    defaultValues: new OrganizationBody(),
  });
  const { handleSubmit, control, errors } = methods;
  const [busy, setBusy] = useState(false);

  useEffect(() => {
    source = axios.CancelToken.source();
    return () => {
      source.cancel();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = (data: OrganizationBody) => {
    setBusy(true);
    registerOrganization(data, source)
      .then(response => {
        setBusy(false);
        if (props.onSubmit) {
          props.onSubmit(response);
        }
      })
      .catch(error => {
        if (!error.isCanceled) {
          setBusy(false);
        }
      });
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} aria-label="Organizacion">
      <Grid container direction="row" justify="center" spacing={1}>
        <Grid item xs={12}>
          <Typography variant="h6" align="center">Información de la Organización</Typography>
        </Grid>
        <Grid item xs={12}>
          <Controller
            render={props => <FancyTextInput
              {...props}
              error={!!errors.name}
              helperText={errors.name && errors.name.message}
              label="Nombre"
              id="organizationForm_name"
            />}
            control={control}
            name="name"
          />
        </Grid>
        <Grid item xs={12} container direction="row" justify="center">
          <FancyButton
            variant="contained"
            color='primary'
            type="submit"
            disabled={busy}
            loading={busy}
          >
            Crear
          </FancyButton>
        </Grid>
      </Grid>
    </form>
  );
}
