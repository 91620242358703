import { AuthUser } from '../Auth';

// Reducer
export class AuthReducer {
  user: AuthUser = { ...new AuthUser() };
  loggeIn: boolean = false;
  authenticatedLoading: boolean = true;

  constructor(init?: Partial<AuthReducer>) {
    Object.assign(this, init);
  }
}
