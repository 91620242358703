import React from 'react'
// Material ui
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
// Other
import { Link } from 'react-router-dom';
// App
import { useFancyStyles } from '../../common/FancyComponents';
import Logo from '../../../assets/images/logo.svg';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    logo: {
      height: 150,
      width: 'auto'
    },
    link: {
      textAlign: 'center',
      textDecoration: 'none',
    },
    paper: {
      width: '350px',
      height: '100%',
      padding: theme.spacing(2, 3),
      [theme.breakpoints.down('xs')]: {
        width: 'auto',
        padding: theme.spacing(1, 2),
      }
    }
  }),
);

export default function ForgetPasswordSuccessComponent() {
  const styles = useFancyStyles();
  const classes = useStyles();

  return (
    <Paper className={classes.paper}>
      <Grid container direction="row" justify="center" spacing={2}>
        <Grid item xs={12} container justify="center">
          <img src={Logo} alt="logo" className={classes.logo} />
        </Grid>
        <Grid item xs={12}>
          <Typography align="center" variant="h6" color="textSecondary">
            Revisa el correo que acabas de suministrar
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography align="center" variant="body2" color="textSecondary">
            Te hemos enviado al correo un enlace para que puedas restablecer la contraseña.
          </Typography>
        </Grid>
        <Grid item xs={12} container direction="row" justify="center">
          <Typography component={Link} to='/login' className={[classes.link, styles.primaryHover].join(' ')}>
            Volver al inicio de sesión
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  )
};
