import axios, { CancelTokenSource } from 'axios';
// Redux
import store from '../../store';
import { showSnackbar } from '../../store/reducers/alertReducer';
// Types
import { Params/*, ListResponse*/ } from '../../types/Api';
import { Catalog, RandomCatalog } from '../../types/Catalog';
// App
import { MAKE_API_REQUEST, CATALOG_SERVICE } from '../../consts/App';
import getListData from '../getListData';

const { dispatch } = store;
const defaultConfig = {
  baseURL: CATALOG_SERVICE,
}

// export async function getCatalogs(source: CancelTokenSource, params?: { paginate?: true } & Params): Promise<ListResponse<Catalog>>;
// export async function getCatalogs(source: CancelTokenSource, params: { paginate: false } & Params): Promise<Catalog[]>;
// export async function getCatalogs(source: CancelTokenSource, params: Params): Promise<Catalog[] | ListResponse<Catalog>>;
export async function getCatalogs(source: CancelTokenSource, params?: Params) {
  if (MAKE_API_REQUEST) {
    return axios.get<Catalog[]>('catalogs/catalogs/', { ...defaultConfig, params: params, cancelToken: source.token })
      .then(response => response.data);
  } else {
    const count = params?.limit || 10;
    // const response = getListData<Catalog>(count, () => new RandomCatalog(), params?.paginate === false);
    const response = getListData<Catalog>(count, () => new RandomCatalog(), true);
    return Promise.resolve(response);
  }
}

export async function getCatalogRegisters(catalog: string, source: CancelTokenSource, params?: Params) {
  if (MAKE_API_REQUEST) {
    return axios.get<any[]>(`catalogs/catalogs/${catalog}/`, { ...defaultConfig, params: params, cancelToken: source.token })
      .then(response => response.data);
  } else {
    const count = params?.limit || 10;
    const response = getListData<any>(count, () => new RandomCatalog(), true);
    return Promise.resolve(response);
  }
}

export async function registerCatalogRegister(catalog: string, data: any, source: CancelTokenSource) {
  if (MAKE_API_REQUEST) {
    return axios.post<any>(`catalogs/catalogs/${catalog}/`, data, { ...defaultConfig, cancelToken: source.token })
      .then(response => {
        dispatch(showSnackbar({ message: 'Registro exitoso', type: 'success' }));
        return response.data;
      });
  } else {
    dispatch(showSnackbar({ message: 'Registro exitoso', type: 'success' }));
    return Promise.resolve(new RandomCatalog() as any);
  }
};

export async function updateCatalogRegister(catalog: string, id: number, data: any, source: CancelTokenSource) {
  if (MAKE_API_REQUEST) {
    return axios.put<any>(`catalogs/catalogs/${catalog}/${id}/`, data, { ...defaultConfig, cancelToken: source.token })
      .then(response => {
        dispatch(showSnackbar({ message: 'Registro actualizado correctamente', type: 'success' }));
        return response.data;
      });
  } else {
    dispatch(showSnackbar({ message: 'Registro actualizado correctamente', type: 'success' }));
    return Promise.resolve(new RandomCatalog() as any);
  }
};

export async function deleteRegisters(catalog: string, data: (number | string)[], source: CancelTokenSource) {
  if (MAKE_API_REQUEST) {
    return axios.delete<null>(`catalogs/catalogs/${catalog}/`, { ...defaultConfig, cancelToken: source.token, data: data })
      .then(response => response.data);
  } else {
    return Promise.resolve(null);
  }
};
