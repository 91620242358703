// v1.0
import React from 'react';
import Hidden from '@material-ui/core/Hidden';
import Typography from '@material-ui/core/Typography';
import { FancyItemTextProps } from './types';

const FancyItemText = (props: FancyItemTextProps) => {
  // Clean undefined, null and empty values
  const textArray = props.textArray.filter(text => text);

  return (
    <React.Fragment>
      {/* Desktop */}
      <Hidden xsDown>
        <Typography component="span" display="block" {...props.TypographyProps}>
          {textArray.map((text, index) => <React.Fragment key={index}>
            {text}{index !== textArray.length - 1 && ' | '}
          </React.Fragment>)}
        </Typography>
      </Hidden>

      {/* Mobile */}
      <Hidden smUp>
        {
          textArray.map((text, index) => (
            <Typography component="span" display="block" key={index} {...props.TypographyProps}>
              {text}
            </Typography>
          ))
        }
      </Hidden>
    </React.Fragment>
  )
};
export default FancyItemText;