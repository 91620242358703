//v1.7
import React, { useState, ReactElement } from 'react';
// Material UI
import Grid, { GridProps } from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Fab from '@material-ui/core/Fab';
import Collapse from '@material-ui/core/Collapse';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import MenuItem from '@material-ui/core/MenuItem';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Chip from '@material-ui/core/Chip';
import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import TuneIcon from '@material-ui/icons/Tune';
import { ButtonProps } from '@material-ui/core/Button';
import { DateRange, DatePicker, DatePickerProps } from '@material-ui/pickers';
// Redux
import { useAppDispatch, useAppSelector } from '../../consts/ReduxHooks';
import { FilterType } from '../../types/redux/filtersTypes';
import { setFilters } from '../../store/reducers/filtersReducer';
// Forms
import { useForm, Controller, ControllerRenderProps } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';
// App
import { FancyTextInput, FancyButton, useFancyStyles } from './FancyComponents';
import DateRangePickerPopover from './DateRangePickerPopover';
import VirtualizedAutocompleteComponent from './VirtualizedAutocompleteComponent';
// Others
import { Moment } from 'moment';

type KeysOfUnion<T> = T extends any ? keyof T : never;

const isFilterType = (key: KeysOfUnion<Exclude<FilterType, null>>, filters: FilterType): key is keyof Exclude<FilterType, null> => {
  if (filters && key in filters) {
    return true;
  } else {
    return false;
  }
}

export const getFilterValue = (key: KeysOfUnion<Exclude<FilterType, null>>, filters: FilterType): any => {
  if (filters && isFilterType(key, filters)) {
    return filters[key];
  }
  return '';
}

export interface ArrayElement {
  value: string | number,
  name: string,
}

export type FilterElement = (
  FilterElementAutocomplete
  | FilterElementAutocompleteMultiple
  | FilterElementDate
  | FilterElementTime
  | FilterElementDateRange
  | FilterElementSelect
  | FilterElementCheckbox
) & {
  key: KeysOfUnion<Exclude<FilterType, null>>;
  name: string;
  gridProps?: GridProps;
  hide?: boolean;
};

interface FilterElementAutocomplete {
  type: 'Autocomplete';
  autocompleteOptions: string[] | ArrayElement[];
  loading?: boolean;
  onChange?: (newValue: null | string | ArrayElement) => void;
}

interface FilterElementAutocompleteMultiple {
  type: 'AutocompleteMultiple';
  autocompleteOptions: ArrayElement[];
  loading?: boolean;
}

interface FilterElementDateRange {
  type: 'DateRange';
  ButtonProps?: Omit<ButtonProps, 'onClick'>;
};

interface FilterElementDate {
  type: 'Date';
  DatePickerProps?: Omit<DatePickerProps, 'value' | 'onChange' | 'renderInput'>;
};

interface FilterElementTime {
  type: 'Time';
};

interface FilterElementSelect {
  type: 'Select';
  selectItems: { value: string, name: string }[];
};

interface FilterElementCheckbox {
  type: 'Checkbox';
  label: string;
};

interface FilterProps {
  elements?: FilterElement[]; // elements to be used inside Expansion
  busy?: boolean; // if true buttons will be disabled
  noSearch?: boolean; // if true there won´t be a Search Input
  beforeSearchContent?: ReactElement; // Content to be added beside Search Input
  schema?: yup.ObjectSchema<object | undefined>; // Schema validation used in Form
  onReset?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  filterButtonVariant?: 'PrimarySecondary' | 'noColor';
  submitFilterButtonProps?: ButtonProps;
  formProps?: React.HTMLProps<HTMLFormElement>
};
type TabFilterProps = { useTabs?: false } | {
  useTabs: true; // if true Tabs would be added
  tabKey: KeysOfUnion<Exclude<FilterType, null>>; // unique key to be used in Tabs
  tabElements: { value: string, name: string }[]; // element to be showed in Tab
  onTabChange?: (event: React.ChangeEvent<{}>) => void; // callback called when Tab value changes
};
type ManualFilterProps = {
  manual?: false;
  defaultValues?: Exclude<FilterType, null>; // default values used when reset button is clicked
  onSubmit?: (filters: Exclude<FilterType, null>) => void; // calback called after submit
} | {
  manual: true; // if true redux will not be used to save data
  defaultValues: Exclude<FilterType, null>; // default values used in manual mode
  onSubmit: (filters: Exclude<FilterType, null>) => void; // calback called after submit
};

type FancyFilterProps = FilterProps & TabFilterProps & ManualFilterProps

export default function FancyFilter(props: FancyFilterProps) {
  const classes = useFancyStyles();
  const dispatch = useAppDispatch();
  const filters = useAppSelector(state => state.filters);
  const [openedFilters, setOpenedFilters] = useState(false);
  const methods = useForm({
    resolver: yupResolver(props.schema || yup.object()),
  });
  const { handleSubmit, control, errors, reset, watch } = methods;
  const watchFilters = watch();
  const filterButtonsStyles = {
    noColor: {
      variant: openedFilters ? 'contained' : 'text',
      color: openedFilters ? 'default' : 'inherit',
    },
    PrimarySecondary: {
      variant: 'contained',
      color: openedFilters ? 'primary' : 'secondary',
    }
  }

  React.useEffect(() => {
    if (props.manual) {
      reset(props.defaultValues);
    } else if (filters) {
      const newFilters: FilterType = { ...filters };
      if (props.useTabs && newFilters && isFilterType(props.tabKey, newFilters)) {
        newFilters[props.tabKey] = getTabDefaultValue();
      }
      reset(newFilters);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const onSubmit = (data: any) => {
    data.id = props.manual ? props.defaultValues.id : filters?.id;
    if (props.elements) {
      const autocompleteElements = props.elements.filter(el => el.type === 'Autocomplete');
      if (autocompleteElements.length > 0) {
        autocompleteElements.forEach(el => {
          if (data[el.key]) {
            data[el.key] = typeof data[el.key] === 'object' ? data[el.key].value : data[el.key];
          }
        })
      }
    }
    if (!props.manual) {
      dispatch(setFilters({ ...data }));
    }
    if (props.onSubmit) {
      props.onSubmit(data);
    }
  }

  const getTabDefaultValue = () => {
    if (props.useTabs) {
      const isEmpty = Object.keys(watchFilters).length === 0;
      const filterValue = !isEmpty ? watchFilters[props.tabKey] : getFilterValue(props.tabKey, filters);
      const value = props.tabElements.find(el => el.value === filterValue);
      if (value) {
        return value.value;
      }
      if (props.defaultValues) {
        return getFilterValue(props.tabKey, props.defaultValues) || '';
      }
    }
    return '';
  };

  const handleReset = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (props.defaultValues) {
      reset(props.defaultValues);
      if (!props.manual && props.defaultValues) {
        dispatch(setFilters({ ...props.defaultValues }));
      }
      if (props.onReset) {
        props.onReset(event);
      }
    }
  }

  const handleTabChange = (onChange: any) => (event: React.ChangeEvent<{}>, newValue: any) => {
    if (props.useTabs) {
      onChange(newValue);
      handleSubmit(onSubmit)();
      if (props.onTabChange) {
        props.onTabChange(event);
      }
    }
  };

  const handleAutocompleteOptionSelected = (option: ArrayElement | string, selected: ArrayElement | string): boolean => {
    const optionValue = typeof option === 'object' ? option.value : option;
    const selectedValue = typeof selected === 'object' ? selected.value : selected;
    return optionValue === selectedValue;
  };

  const handleAutocompleteOptionLabel = (options: (string | ArrayElement)[]) =>
    (option: any): string => {
      switch (typeof options[0]) {
        case 'string':
          return option;
        case 'object':
          if (typeof option === 'object') {
            return option.name;
          } else {
            const selected: any = options.find((op: any) => 'value' in op && op.value === option);
            if (selected && 'name' in selected) {
              return selected.name;
            } else {
              return option;
            }
          }
        default:
          return '';
      }
    };

  const handleAutocompleteMultipleChange = (onChange: any, values: ArrayElement[]) =>
    (event: React.ChangeEvent<{}>, newValue: ArrayElement | null) => {
      if (newValue && !values.find(val => val.value === newValue.value)) {
        onChange(values.concat(newValue));
      }
    };

  const handleAutocompleteMultipleChipDelete = (onChange: any, values: ArrayElement[], elToDelete: ArrayElement) =>
    () => onChange(values.filter(el => el.value !== elToDelete.value));

  const getComponent = (element: FilterElement) => (props: ControllerRenderProps) => {
    const error = errors[element.key];
    const errorProps = {
      error: !!error,
      helperText: error && error.message,
    }
    switch (element.type) {
      case 'Autocomplete':
        return (
          <VirtualizedAutocompleteComponent<ArrayElement | string>
            value={props.value}
            onChange={(event, newValue) => {
              props.onChange(newValue);
              if (element.onChange) {
                element.onChange(newValue);
              }
            }}
            options={element.autocompleteOptions || []}
            loading={element.loading}
            disabled={element.loading}
            getOptionSelected={handleAutocompleteOptionSelected}
            getOptionLabel={handleAutocompleteOptionLabel(element.autocompleteOptions || [])}
            noOptionsText="Sin resultados"
            aria-label={element.name}
            renderInput={params => <FancyTextInput
              {...params}
              {...errorProps}
              label={element.name}
              placeholder="Todas"
              hiddenLabel
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {element.loading ? <CircularProgress aria-label={element.name} color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />}
          />
        );
      case 'AutocompleteMultiple':
        return (
          <Grid container spacing={1} alignItems="center">
            <Grid item xs={12} sm={6} md={4}>
              <VirtualizedAutocompleteComponent<ArrayElement>
                onChange={handleAutocompleteMultipleChange(props.onChange, props.value)}
                options={element.autocompleteOptions || []}
                loading={element.loading}
                disabled={element.loading}
                getOptionLabel={option => option.name}
                noOptionsText="Sin resultados"
                aria-label={element.name}
                renderInput={params => <FancyTextInput
                  {...params}
                  {...errorProps}
                  label={element.name}
                  placeholder="Todas"
                  hiddenLabel
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {element.loading ? <CircularProgress aria-label={element.name} color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={8}>
              {
                props.value && props.value.map((el: ArrayElement) => (
                  <Chip
                    key={el.value}
                    label={el.name}
                    onDelete={handleAutocompleteMultipleChipDelete(props.onChange, props.value, el)}
                    className={classes.m_05}
                  />
                ))
              }
            </Grid>
          </Grid>
        );
      case 'DateRange':
        return (
          <DateRangePickerPopover
            initialValue={props.value}
            onSubmit={(date: DateRange<Moment>) => props.onChange(date)}
          />
        );
      case 'Date':
        return (
          <DatePicker
            toolbarTitle="Seleccione una fecha"
            clearText="Limpiar"
            cancelText="Cancelar"
            todayText="Hoy"
            {...element.DatePickerProps}
            {...props}
            renderInput={(props) => <FancyTextInput
              {...props}
              {...errorProps}
              id={'filterForm_' + element.name}
            />}
          />
        );
      case 'Time':
        return <FancyTextInput {...props} {...errorProps} type="time" />
      case 'Select':
        return (
          <FancyTextInput
            {...props}
            {...errorProps}
            select
            fullWidth
            SelectProps={{ displayEmpty: true }}
            id={'filterForm_' + element.name}
          >
            {(element.selectItems || []).map(item => (
              <MenuItem value={item.value} key={item.value}>{item.name}</MenuItem>
            ))}
          </FancyTextInput>
        );
      case 'Checkbox':
        return (
          <FormControlLabel
            {...errorProps}
            id={'filterForm_' + element.name}
            control={<Checkbox
              {...props}
              color="primary"
              onChange={e => props.onChange(e.target.checked)}
              checked={props.value}
            />}
            label={element.label}
          />
        );
    }
  }

  return (
    (props.manual || filters) && <form
      {...props.formProps}
      onSubmit={handleSubmit(onSubmit)}
      className={[props.formProps?.className, classes.mb_1].join(' ')}
      role="search"
    >
      <Grid container alignItems="center" spacing={1}>
        {
          props.useTabs && <Grid item xs={12}>
            <Controller
              render={renderProps => <Tabs
                value={renderProps.value}
                onChange={handleTabChange(renderProps.onChange)}
                className={classes.mb_1}
                variant="scrollable"
                scrollButtons="auto"
              >
                {props.tabElements.map(tab => <Tab key={tab.value} value={tab.value} label={tab.name} />)}
              </Tabs>}
              name={props.tabKey}
              defaultValue={getTabDefaultValue()}
              control={control}
            />
          </Grid>
        }
        <Grid item xs={12}>
          <Grid
            container
            justify={props.beforeSearchContent ? 'space-between' : 'flex-end'}
            alignItems="center"
            spacing={1}
          >
            {
              props.beforeSearchContent && <Grid item>
                {props.beforeSearchContent}
              </Grid>
            }
            {
              !props.noSearch && <Grid item xs={props.elements && props.elements.length > 0 ? 10 : 12} sm={6}>
                <Controller
                  as={FancyTextInput}
                  InputProps={{
                    endAdornment:
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="Filtrar"
                          color="inherit"
                          type="submit"
                          disabled={props.busy}
                        >
                          <SearchIcon />
                        </IconButton>
                      </InputAdornment>
                  }}
                  control={control}
                  error={!!errors.search}
                  helperText={errors.search && errors.search.message}
                  defaultValue={props.manual ? getFilterValue('search', props.defaultValues) : getFilterValue('search', filters)}
                  name="search"
                  type="search"
                  label="Buscar"
                  id="filter_search"
                />
              </Grid>
            }
            {
              props.elements && props.elements.length > 0 && <Grid item xs={2} sm="auto">
                <Hidden xsDown={props.filterButtonVariant !== 'noColor'}>
                  <FancyButton
                    {...(props.filterButtonVariant ? filterButtonsStyles[props.filterButtonVariant] : filterButtonsStyles.PrimarySecondary)}
                    startIcon={<TuneIcon />}
                    type="button"
                    disabled={props.busy}
                    onClick={() => setOpenedFilters(prev => !prev)}
                  >
                    Filtros
                  </FancyButton>
                </Hidden>
                {
                  props.filterButtonVariant !== 'noColor' && <Hidden smUp>
                    <Fab
                      color={openedFilters ? 'primary' : 'secondary'}
                      onClick={() => setOpenedFilters(prev => !prev)}
                      disabled={props.busy}
                      size="small"
                      type="button"
                    >
                      <TuneIcon />
                    </Fab>
                  </Hidden>
                }
              </Grid>
            }
            {
              props.elements && props.elements.length > 0 && <Grid item xs={12}>
                <Collapse
                  aria-hidden={!openedFilters}
                  in={openedFilters}
                  timeout="auto"
                  component={Paper}
                  className={classes.p_1}
                >
                  <Grid container spacing={1} aria-label="Filtros expandidos" role="region">
                    {
                      props.elements.filter(element => !element.hide).map((element, index) => {
                        const md = (element.type === 'AutocompleteMultiple') ? 12 : 4;
                        const lg = (element.type === 'AutocompleteMultiple') ? 12 : 3;
                        return (
                          <Grid item xs={12} md={md} lg={lg} {...element.gridProps} key={index}>
                            <Grid container justify="center">
                              <Grid item xs={12}>
                                <Typography>{element.name}</Typography>
                              </Grid>
                              <Grid item xs={12}>
                                <Controller
                                  render={getComponent(element)}
                                  control={control}
                                  name={element.key}
                                  defaultValue={props.manual ? getFilterValue(element.key, props.defaultValues) : getFilterValue(element.key, filters)}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                        )
                      })
                    }
                    <Grid item xs={12} container justify="flex-end" spacing={1}>
                      {
                        props.defaultValues && <Grid item>
                          <FancyButton
                            variant="text"
                            disabled={props.busy}
                            type="button"
                            onClick={handleReset}
                          >
                            Limpiar Filtros
                          </FancyButton>
                        </Grid>
                      }
                      <Grid item>
                        <FancyButton
                          variant="text"
                          color="primary"
                          {...props.submitFilterButtonProps}
                          type="submit"
                          disabled={props.busy}
                          loading={props.busy}
                        >
                          Filtrar
                        </FancyButton>
                      </Grid>
                    </Grid>
                  </Grid>
                </Collapse>
              </Grid>
            }
          </Grid>
        </Grid>
      </Grid>
    </form>
  )
}
