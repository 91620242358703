import React, { useState } from 'react'
// Material ui
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import InputAdornment from '@material-ui/core/InputAdornment';
import EmailIcon from '@material-ui/icons/Email';
// Forms
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers';
// Services
import { requestChangePassword } from '../../../services/API/AuthServices';
// Types
import { RecoveryBody } from '../../../types/Auth';
// App
import { FancyTextInput, FancyButton, useFancyStyles } from '../../common/FancyComponents';
import Logo from '../../../assets/images/logo.svg';
// Other
import { Link } from 'react-router-dom';
import axios, { CancelTokenSource } from 'axios';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    logo: {
      height: 150,
      width: 'auto'
    },
    link: {
      textAlign: 'center',
      textDecoration: 'none',
    },
    paper: {
      width: '350px',
      height: '100%',
      padding: theme.spacing(2, 3),
      [theme.breakpoints.down('xs')]: {
        width: 'auto',
        padding: theme.spacing(1, 2),
      }
    }
  }),
);

const schema = yup.object().shape({
  email: yup.string().required().email(),
});

let source: CancelTokenSource = axios.CancelToken.source();

export default function ForgetPasswordComponent() {
  const styles = useFancyStyles();
  const classes = useStyles();
  const methods = useForm<RecoveryBody>({
    resolver: yupResolver(schema),
    defaultValues: new RecoveryBody(),
  });
  const { handleSubmit, control, errors } = methods;
  const [busy, setBusy] = useState(false);

  React.useEffect(() => {
    source = axios.CancelToken.source();
    return () => {
      source.cancel();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = (data: RecoveryBody) => {
    setBusy(true);
    requestChangePassword(data, source)
      .catch(error => {
        if (!error.isCanceled) {
          setBusy(false);
        }
      });
  }

  return (
    <Paper
      className={classes.paper}
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      aria-label="Recuperacion de contraseña"
    >
      <Grid container direction="row" justify="center" spacing={2}>
        <Grid item xs={12} container justify="center">
          <img src={Logo} alt="logo" className={classes.logo} />
        </Grid>
        <Grid item xs={12}>
          <Typography align="center" variant="h6" color="textSecondary">
            Recuperación de Contraseña
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography align="center" variant="body2" color="textSecondary">
            Escribe tu correo y estaremos enviando un enlace donde podrás restablecer tu contraseña.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Controller
            as={FancyTextInput}
            InputProps={{
              startAdornment: <InputAdornment position="start"><EmailIcon color="primary" /></InputAdornment>,
            }}
            control={control}
            error={!!errors.email}
            helperText={errors.email && errors.email.message}
            label="Correo"
            type="email"
            name="email"
            placeholder="Ingrese su correo"
            id="forget_password_email"
          />
        </Grid>
        <Grid item xs={12} container direction="row" justify="center">
          <FancyButton
            variant="contained"
            color='primary'
            type="submit"
            disabled={busy}
            loading={busy}
          >
            Restablecer contraseña
          </FancyButton>
        </Grid>
        <Grid item xs={12} container direction="row" justify="center">
          <Typography align="center" component={Link} to='/login' className={[classes.link, styles.primaryHover].join(' ')}>
            Volver al inicio de sesión
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  )
};
