import React from 'react';
// Material UI
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
// App
import Background from '../../assets/images/login.jpg';

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: '100vh',
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(1),
    }
  },
  img: {
    width: '100%',
    height: '100%',
  },
  h_100: {
    height: '100%',
    [theme.breakpoints.up('sm')]: {
      height: '100vh',
    }
  }
}));

const PublicViews: React.FunctionComponent = props => {
  const classes = useStyles();

  return (
    <Grid
      container
      direction="row"
      justify="space-between"
      alignItems="center"
      className={classes.root}
    >
      <Hidden xsDown>
        <Grid item xs sm={6} container justify="center" alignItems="center" className={classes.h_100}>
          <img src={Background} alt="login" className={classes.img} />
        </Grid>
      </Hidden>
      <Grid item xs={12} sm={6} container justify="center" alignItems="center">
        {props.children}
      </Grid>
    </Grid>
  );
}
export default PublicViews;
