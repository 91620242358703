import React from 'react';
// Material UI
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import IconButton from "@material-ui/core/IconButton";
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
// Redux
import { useAppDispatch, useAppSelector } from '../../consts/ReduxHooks';
import { dismissSnackbar } from '../../store/reducers/alertReducer';

export interface SnackbarMessage {
  message: string;
  type?: 'error' | 'info' | 'success' | 'warning',
  key: number;
}

const FancySnackbar = () => {
  const dispatch = useAppDispatch();
  const snackbar = useAppSelector(state => state.alert)
  const [open, setOpen] = React.useState(snackbar.open);
  const [snackPack, setSnackPack] = React.useState<SnackbarMessage[]>([]);
  const [messageInfo, setMessageInfo] = React.useState<SnackbarMessage | undefined>(undefined);

  React.useEffect(() => {
    if (snackPack.length && !messageInfo) {
      // Set a new snack when we don't have an active one
      setMessageInfo({ ...snackPack[0] });
      setSnackPack((prev) => prev.slice(1));
      setOpen(true);
    } else if (snackPack.length && messageInfo && open) {
      // Close an active snack when a new one is added
      setOpen(false);
    }
  }, [snackPack, messageInfo, open]);

  React.useEffect(() => {
    setOpen(snackbar.open);
    if (snackbar.message) {
      setSnackPack((prev) => [...prev, {
        message: snackbar.message,
        type: snackbar.type,
        key: new Date().getTime()
      }]);
    }
  }, [snackbar]);

  function handleClose() {
    setOpen(false);
    dispatch(dismissSnackbar());
  }

  const handleExited = () => {
    setMessageInfo(undefined);
  };

  return (
    <Snackbar
      key={messageInfo ? messageInfo.key : undefined}
      autoHideDuration={5000}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      open={open}
      onClose={handleClose}
      onExited={handleExited}
    >
      {
        messageInfo && <Alert
          elevation={3}
          variant="standard"
          severity={messageInfo.type}
          action={[
            <IconButton
              key="close"
              aria-label="cerrar"
              color="inherit"
              size="small"
              onClick={handleClose}
            >
              <CloseRoundedIcon />
            </IconButton>
          ]}
        >
          {messageInfo.message}
        </Alert>
      }
    </Snackbar>
  );
}

export default FancySnackbar;
