//v1.3
import axios, { AxiosError } from "axios";
// Services
import { logout, refreshToken } from "../services/API/AuthServices";
import { errorHandler } from "../services/errorHandler";
// App
import { HISTORY } from '../consts/Router';
// Types
import { ErrorAxios } from "../types/Axios";
// Redux
import store from "../store";
import { showSnackbar } from "../store/reducers/alertReducer";

const { dispatch } = store;

// Defaults
// axios.defaults.baseURL = process.env.REACT_APP_API;
// axios.defaults.headers.common['App-Key'] = process.env.REACT_APP_KEY;
// axios.defaults.headers.common['Secret-Key'] = process.env.REACT_APP_SECRET_KEY;

// intercept request before is sent
axios.interceptors.request.use(
  (request) => {
    if (request.headers['NO-HEADERS']) {
      delete request.headers['NO-HEADERS'];
      //Add authorization header if user is authenticated
    } else if (localStorage.getItem('token')) {
      request.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('token');
    }
    // Remove ID param added in filters if exists
    if (request.params?.id) {
      delete request.params.id;
    }
    return request;
  },
  (error: Error | AxiosError) => Promise.reject(error)
);

// intercept request response after is received
axios.interceptors.response.use(
  (response) => {
    // Return a successful response back to the calling service
    return response;
  },
  (error: Error | AxiosError) => {
    if (axios.isAxiosError(error)) {
      const canceledError: ErrorAxios = { ...error, isCanceled: axios.isCancel(error) };
      if (!canceledError.isCanceled && canceledError.response) {
        // Logout user if token refresh didn't work
        if (canceledError.config.url === 'authentication/token/refresh/') {
          logout()
            .then(() => {
              dispatch(showSnackbar({
                message: 'Su token ha vencido, por favor vuelva a iniciar sesión',
                type: 'error'
              }));
              HISTORY.push('/');
            });
          return Promise.reject(canceledError);
        }

        // Return any error which is not due to authentication back to the calling service
        if (canceledError.response.status !== 401) {
          errorHandler(error);
          return Promise.reject(canceledError);
        }

        // Try request again with new token
        if (localStorage.getItem('token')) {
          return refreshToken()
            .then((response) => {
              // New request with new token
              const config = canceledError.config;
              config.headers['Authorization'] = `Bearer ${response.access}`;
              return axios.request(config);
            })
        }
      }
      if (!canceledError.isCanceled) {
        errorHandler(error);
      }
      return Promise.reject(canceledError);
    }
    return Promise.reject(error);
  }
);
