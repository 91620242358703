import React from 'react';
// App
import PowerBIRouting from './PowerBISection/PowerBIRouting';
// Other
import { Switch, Redirect, RouteComponentProps, Route } from "react-router-dom";

export default function MarketingRouting(props: RouteComponentProps) {
  return (
    <Switch>
      <Route path={props.match.path + '/bi'} component={PowerBIRouting} />
      <Redirect to={props.match.path + '/bi'} />
    </Switch>
  );
}
