import React from 'react';
// Redux
import { useAppDispatch } from '../../../consts/ReduxHooks';
import { PermissionFilters } from '../../../types/redux/filtersTypes';
import { setFilters, resetFilters } from '../../../store/reducers/filtersReducer';
// App
import DashboardComponent from '../../layouts/DashboardComponent';
import PermissionListComponent from '../../views/Permissions/PermissionsListComponent';
import MODULES from '../../../consts/Modules';
import PERMISSIONS from '../../../consts/Permissions';
// Services
import hasModule from '../../../services/hasModule';
// Other
import { Switch, Route, Redirect, RouteComponentProps } from 'react-router-dom';

export default function PermissionsRouting(props: RouteComponentProps) {
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    dispatch(setFilters({ ...new PermissionFilters() }));
    return () => {
      dispatch(resetFilters());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DashboardComponent
      title="Permisos"
      children={<Switch>
        <Route exact path={props.match.path + '/list'} render={props => <PermissionListComponent
          hideList={!hasModule(MODULES.permissions, PERMISSIONS.list)}
          hideCreate={!hasModule(MODULES.permissions, PERMISSIONS.create)}
        />} />
        <Redirect to={props.match.path + '/list'} />
      </Switch>}
    />
  );
}
