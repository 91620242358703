import React, { useState } from 'react';
// Material ui
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
// Forms
import { useForm, Controller } from "react-hook-form";
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers';
// APP
import { FancyButton, PasswordTextInput } from '../../common/FancyComponents'
// Services
import { changePassword } from '../../../services/API/AuthServices';
// Types
import { ChangePasswordBody } from '../../../types/Auth';
// Other
import axios, { CancelTokenSource } from 'axios';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      [theme.breakpoints.up('sm')]: {
        width: 300,
      }
    },
  }),
);

const schema = yup.object().shape({
  old_password: yup.string().required().min(8),
  password: yup.string().required().min(8),
  confirmPassword: yup.string().oneOf([yup.ref('password')], 'Las contraseñas deben coincidir')
});

let source: CancelTokenSource = axios.CancelToken.source();

export default function ChangePasswordComponent() {
  const styles = useStyles();
  const methods = useForm<ChangePasswordBody>({
    resolver: yupResolver(schema),
    defaultValues: new ChangePasswordBody(),
  });
  const { handleSubmit, control, errors } = methods;
  const [busy, setBusy] = useState(false);

  React.useEffect(() => {
    source = axios.CancelToken.source();
    return () => {
      source.cancel();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = (data: ChangePasswordBody) => {
    setBusy(true);
    changePassword(data, source)
      .then(response => setBusy(false))
      .catch(error => {
        if (!error.isCanceled) {
          setBusy(false);
        }
      });
  }

  return (
    <Grid container justify="center" component="form" onSubmit={handleSubmit(onSubmit)}>
      <Grid item container spacing={1} className={styles.container}>
        <Grid item xs={12}>
          <Controller
            as={PasswordTextInput}
            error={!!errors.old_password}
            helperText={errors.old_password && errors.old_password.message}
            control={control}
            name="old_password"
            label="Contraseña actual"
            withoutIcon
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            as={PasswordTextInput}
            error={!!errors.password}
            helperText={errors.password && errors.password.message}
            control={control}
            name="password"
            label="Nueva contraseña"
            withoutIcon
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            as={PasswordTextInput}
            error={!!errors.confirmPassword}
            helperText={errors.confirmPassword && errors.confirmPassword.message}
            control={control}
            name="confirmPassword"
            label="Confirmar contraseña"
            withoutIcon
          />
        </Grid>
        <Grid item xs={12} container direction="row" justify="center">
          <FancyButton
            variant="contained"
            color="primary"
            type="submit"
            disabled={busy}
            loading={busy}
          >
            Cambiar contraseña
          </FancyButton>
        </Grid>

      </Grid>
    </Grid>
  );
}
