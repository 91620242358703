import React, { useState, useEffect } from 'react';
// Material ui
import { makeStyles, Theme, createStyles, useTheme } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Badge from '@material-ui/core/Badge';
import IconButton from '@material-ui/core/IconButton';
import NotificationsIcon from '@material-ui/icons/Notifications';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
// App
import { FancyTooltip } from './FancyComponents';
// Types
import { Notification, StatusType } from '../../types/Notification';
// Services
import { onMessage } from '../../services/API/SocketServices';
// Redux
import { useAppDispatch } from '../../consts/ReduxHooks';
import { showSnackbar } from '../../store/reducers/alertReducer';
// Others
import moment from 'moment';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    listItemIcon: {
      minWidth: 'auto',
      marginRight: theme.spacing(2),
    },
  }),
);

export default function NotificationComponent() {
  const styles = useStyles();
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [newNotifications, setNewNotifications] = useState<Notification[]>([]);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const hasNotifications = notifications.length > 0 || newNotifications.length > 0;

  useEffect(() => {
    const subscription = onMessage<Notification>('notifications')
      .subscribe(data => {
        setNewNotifications(prev => prev.concat(data));
        dispatch(showSnackbar({ message: data.description, type: getColor(data.status) }));
      });
    return () => {
      subscription.unsubscribe();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (hasNotifications) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setNotifications(prev => prev.concat(newNotifications));
    setNewNotifications([]);
    setAnchorEl(null);
  };

  const getColor = (status: StatusType) => {
    switch (status) {
      case 'ERROR':
        return 'error';
      case 'PROCESSING':
        return 'warning';
      case 'SUCCESS':
        return 'success';
      default:
        return undefined;
    }
  }

  const getThemeColor = (color: 'error' | 'warning' | 'success' | undefined) => {
    if (color) {
      return theme.palette[color].main;
    }
    return 'inherit';
  }

  return <React.Fragment>
    <FancyTooltip title={hasNotifications ? '' : 'No tiene ninguna notificación'}>
      <Badge badgeContent={newNotifications.length} color="error" overlap="circle">
        <IconButton size="small" color="inherit" onClick={handleClick}>
          <NotificationsIcon />
        </IconButton>
      </Badge>
    </FancyTooltip>
    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      {
        newNotifications.map((notification, index) => <ListItem key={'new-' + index}>
          <ListItemIcon className={styles.listItemIcon}>
            <AccountBalanceIcon
              fontSize="small"
              style={{ color: getThemeColor(getColor(notification.status)) }}
            />
          </ListItemIcon>
          <ListItemText
            primary={<strong>{notification.description}</strong>}
            secondary={moment.utc(notification.date).fromNow()}
          />
        </ListItem>)
      }
      {
        notifications.map((notification, index) => <ListItem key={'old-' + index}>
          <ListItemIcon className={styles.listItemIcon}>
            <AccountBalanceIcon
              fontSize="small"
              style={{ color: getThemeColor(getColor(notification.status)) }}
            />
          </ListItemIcon>
          <ListItemText
            primary={notification.description}
            secondary={moment.utc(notification.date).fromNow()}
          />
        </ListItem>)
      }
    </Popover>
  </React.Fragment>
}
