import axios, { CancelTokenSource } from 'axios';
// Types
import { PowerBi, PowerBiParams, RandomPowerBi } from '../../types/PowerBi';
// App
import { MAKE_API_REQUEST, ARYA_CORE } from '../../consts/App';

const defaultConfig = {
  baseURL: ARYA_CORE,
}

export async function getPowerBIData(source: CancelTokenSource, params?: PowerBiParams) {
  if (MAKE_API_REQUEST) {
    return axios.get<PowerBi>(`bi/`, { ...defaultConfig, params: params, cancelToken: source.token })
      .then(response => response.data);
  } else {
    return Promise.resolve(new RandomPowerBi() as PowerBi);
  }
}
