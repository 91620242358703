import React, { useState, useEffect } from 'react';
// Material UI
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
// Forms
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers';
// Redux
import { useAppDispatch } from '../../../consts/ReduxHooks';
import { showSnackbar } from '../../../store/reducers/alertReducer';
// App
import { FancyTextInput, FancyButton, useFancyStyles } from '../../common/FancyComponents';
import FancyList, { ActionButton } from '../../common/FancyList';
import VirtualizedAutocompleteComponent from '../../common/VirtualizedAutocompleteComponent';
// Services
import { registerGroup, updateGroup } from '../../../services/API/GroupServices';
import { getOrganizations } from '../../../services/API/OrganizationServices';
import { getModules } from '../../../services/API/ModuleServices';
// Types
import { GeneralFormProps } from '../../../types/propTypes/misc';
import { Group, GroupForm, GroupBody, GroupFormDefault, ListModule } from '../../../types/Group';
import { Permission } from '../../../types/Permission';
import { Module } from '../../../types/Module';
import { Organization } from '../../../types/Organization';
// Others
import axios, { CancelTokenSource } from 'axios';

let source: CancelTokenSource = axios.CancelToken.source();

export default function GroupFormComponent(props: GeneralFormProps<Group>) {
  const isEdit = !!props.data;
  const styles = useFancyStyles();
  const dispatch = useAppDispatch();
  const schema = yup.object().shape({
    group: isEdit ? yup.mixed() : yup.string().required(),
    organization: yup.mixed().required(),
  });
  const methods = useForm<GroupForm>({
    resolver: yupResolver(schema),
    defaultValues: new GroupFormDefault(),
  });
  const { handleSubmit, control, errors, reset, watch, setValue } = methods;
  const [busy, setBusy] = useState(false);
  const [organizations, setOrganizations] = useState<Organization[]>([]);
  const [loadingOrganizations, setLoadingOrganizations] = useState(false);
  const [modules, setModules] = useState<Module[]>([]);
  const [loadingModules, setLoadingModules] = useState(false);
  const [selectedModules, setSelectedModules] = useState<ListModule[]>([]);
  const watchModule = watch('module')
  const actionButtons: ActionButton<ListModule>[] = [
    {
      text: 'Eliminar',
      icon: <CancelOutlinedIcon />,
      onClick: (event, mod) => removeModule(mod),
      disabled: busy,
    },
  ];

  async function fetchOrganizations() {
    setLoadingOrganizations(true);
    const params = {
      paginate: false as false
    }
    getOrganizations(source, params)
      .then(response => {
        setOrganizations(response);
        setLoadingOrganizations(false);
      })
      .catch(error => {
        if (!error.isCanceled) {
          setLoadingOrganizations(false);
        }
      });
  };

  async function fetchModules(organization: Organization) {
    setLoadingModules(true);
    const params = {
      paginate: false as false,
      organization: organization.id,
    }
    getModules(source, params)
      .then(response => {
        setModules(response);
        setLoadingModules(false);
      })
      .catch(error => {
        if (!error.isCanceled) {
          setLoadingModules(false);
        }
      });
  };

  useEffect(() => {
    source = axios.CancelToken.source();
    fetchOrganizations();
    if (isEdit && props.data) {
      reset({
        organization: props.data.organization,
        module: null,
        permissions: [],
      });
      setSelectedModules(props.data.modules);
      fetchModules(props.data.organization);
    }
    return () => {
      source.cancel();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = (data: GroupForm) => {
    if (selectedModules.length === 0) {
      dispatch(showSnackbar({ message: 'Debe agregar al menos un módulo al grupo', type: 'error' }));
      return;
    }
    setBusy(true);
    const dataBody: GroupBody = {
      organization: data.organization?.id || 0,
      modules: selectedModules.map(mod => ({
        id: mod.id,
        permissions: mod.permissions.map(p => p.id),
      })),
    }
    if (!isEdit) {
      dataBody.group = data.group;
    }
    const service = isEdit ? updateGroup(props.data?.group || '', dataBody, source)
      : registerGroup(dataBody, source);
    service
      .then(response => {
        setBusy(false);
        if (props.onSubmit) {
          props.onSubmit(response);
        }
      })
      .catch(error => {
        if (!error.isCanceled) {
          setBusy(false);
        }
      });
  }

  const addModule = () => {
    const data = watch();
    if (!data.module || data.permissions.length === 0) {
      dispatch(showSnackbar({ message: 'Debe ingresar toda la información del módulo', type: 'error' }));
      return;
    }
    if (selectedModules.find(mod => mod.id === data.module?.id)) {
      dispatch(showSnackbar({ message: 'El módulo seleccionado ya se encuentra en la lista', type: 'error' }));
      return;
    }
    const moduleToAdd: ListModule = {
      ...data.module,
      permissions: data.permissions,
    };
    setSelectedModules(prev => prev.concat(moduleToAdd));
    const defaultModule = new GroupFormDefault();
    setValue('module', defaultModule.module);
    setValue('permissions', defaultModule.permissions);
  }

  const removeModule = (mod: ListModule) => {
    setSelectedModules(prev => prev.filter(m => m.id !== mod.id));
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} aria-label="Grupo">
      <Grid container direction="row" justify="center" spacing={1}>
        <Grid item xs={12}>
          <Typography variant="h6" align="center">Información del Grupo</Typography>
        </Grid>
        {
          !isEdit && <Grid item xs={12} sm={6}>
            <Controller
              render={props => <FancyTextInput
                {...props}
                error={!!errors.group}
                helperText={errors.group && errors.group.message}
                label="Nombre"
                id="grupoForm_group"
              />}
              control={control}
              name="group"
            />
          </Grid>
        }
        <Grid item xs={12} sm={isEdit ? 12 : 6}>
          <Controller
            render={props => <VirtualizedAutocompleteComponent<Organization>
              {...props}
              options={organizations}
              onChange={(event, newValue) => {
                props.onChange(newValue);
                setSelectedModules([]);
                setValue('module', new GroupFormDefault().module);
                setValue('permissions', new GroupFormDefault().permissions);
                if (newValue) {
                  fetchModules(newValue);
                }
              }}
              getOptionSelected={(option, selected) => option.id === selected.id}
              getOptionLabel={option => option.name}
              renderOption={option => <Typography noWrap>{option.name}</Typography>}
              loading={loadingOrganizations}
              aria-label="Organización"
              renderInput={params => <FancyTextInput
                {...params}
                label="Organización"
                id="grupoForm_organization"
                error={!!errors.organization}
                helperText={errors.organization && errors.organization.message}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {loadingOrganizations ? <CircularProgress aria-label="Organización" color="inherit" size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
              />}
            />}
            control={control}
            name="organization"
          />
        </Grid>
        <Grid
          item
          xs={12}
          container
          spacing={1}
          alignItems="center"
          className={styles.mt_1}
          component={Paper}
          variant="outlined"
        >
          <Grid item xs={12}>
            <Typography><strong>Información del Módulo</strong></Typography>
          </Grid>
          <Grid item xs={12}>
            <Controller
              render={props => <VirtualizedAutocompleteComponent<Module>
                {...props}
                noOptionsText={watch('organization') ? 'No se encontraron resultados' : 'Seleccione una organización'}
                options={modules}
                onChange={(event, newValue) => {
                  props.onChange(newValue);
                  setValue('permissions', new GroupFormDefault().permissions);
                }}
                getOptionSelected={(option, selected) => option.id === selected.id}
                getOptionLabel={option => option.name}
                renderOption={option => <Typography noWrap>{option.name}</Typography>}
                loading={loadingModules}
                aria-label="Módulo"
                renderInput={params => <FancyTextInput
                  {...params}
                  label="Módulo"
                  id="grupoForm_module"
                  error={!!errors.module}
                  helperText={errors.module && errors.module.message}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {loadingModules ? <CircularProgress aria-label="Módulo" color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />}
              />}
              control={control}
              name="module"
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              render={props => <VirtualizedAutocompleteComponent<Permission, true>
                {...props}
                multiple
                disableCloseOnSelect
                noOptionsText={watchModule ? 'No se encontraron resultados' : 'Seleccione un módulo'}
                options={watchModule ? watchModule.permissions : []}
                onChange={(event, newValue) => props.onChange(newValue)}
                getOptionSelected={(option, selected) => option.id === selected.id}
                getOptionLabel={option => option.name}
                renderOption={option => <Typography noWrap>{option.name}</Typography>}
                aria-label="Permisos"
                renderInput={params => <FancyTextInput
                  {...params}
                  label="Permisos"
                  id="grupoForm_permissions"
                  error={!!errors.permissions}
                  helperText={errors.permissions && errors.permissions.message}
                />}
              />}
              control={control}
              name="permissions"
            />
          </Grid>
          <Grid item xs={12} container justify="center">
            <Grid item>
              <FancyButton
                variant="outlined"
                color='primary'
                type="button"
                disabled={busy}
                onClick={addModule}
              >
                Agregar módulo
              </FancyButton>
            </Grid>
          </Grid>
          {
            selectedModules.length > 0 && <Grid item xs={12}>
              <Typography><strong>Módulos seleccionados</strong></Typography>
              <FancyList<ListModule>
                divider
                disablePadding
                elements={selectedModules}
                ListProps={{ "aria-label": "Módulos seleccionados" }}
                ListItemTextProps={mod => ({
                  primary: mod.name,
                  secondary: mod.permissions.map(p => p.name).join(', '),
                })}
                actionButtons={actionButtons}
              />
            </Grid>
          }
        </Grid>
        <Grid item xs={12} container direction="row" justify="center">
          <FancyButton
            variant="contained"
            color='primary'
            type="submit"
            disabled={busy}
            loading={busy}
          >
            {isEdit ? 'Guardar' : 'Crear'}
          </FancyButton>
        </Grid>
      </Grid>
    </form>
  );
}
