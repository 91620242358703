import { createSlice } from '@reduxjs/toolkit';
import { DialogReducer } from '../../types/redux/dialogTypes';

// Can´t instantiate new classes with new Class() as initalState,
// use spread operator to convert to object instead or don´t use Classes
const initialState: DialogReducer = { ...new DialogReducer() };

export const alertSlice = createSlice({
  name: 'alert',
  initialState,
  reducers: {
    showDialog: state => {
      state.open = true;
    },
    dismissDialog: state => {
      state.open = false;
    },
    showDialogEdit: state => {
      state.openEdit = true;
    },
    dismissDialogEdit: state => {
      state.openEdit = false;
    },
    showDialogView: state => {
      state.openView = true;
    },
    dismissDialogView: state => {
      state.openView = false;
    },
  }
});

export const { showDialog, dismissDialog, showDialogEdit, dismissDialogEdit, showDialogView, dismissDialogView } = alertSlice.actions;

export default alertSlice.reducer;
