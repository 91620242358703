export const MAKE_API_REQUEST: boolean = process.env.NODE_ENV === 'production';

export const ORGANIZATION_ID = 1;

// Services
export const ARYA_CORE: string = process.env.REACT_APP_ARYA_CORE || '';

export const DRIVE_SERVICE: string = process.env.REACT_APP_DRIVE_SERVICE || '';

export const USERMANAGEMENT_SERVICE: string = process.env.REACT_APP_USERMANAGEMENT_SERVICE || '';

export const BANKING_SERVICE: string = process.env.REACT_APP_BANKING_SERVICE || '';

export const ARYA_PROCESS: string = process.env.REACT_APP_ARYA_PROCESS || '';

export const ERP_PROCESS: string = process.env.REACT_APP_ERP_PROCESS || '';

export const ACCOUNTING_PROCESS: string = process.env.REACT_APP_ACCOUNTING_PROCESS || '';

export const CATALOG_SERVICE: string = process.env.REACT_APP_CATALOG_SERVICE || '';

export const SUPERSET_SERVICE: string = process.env.REACT_APP_SUPERSET_SERVICE || '';

export const DATASTUDIO_SUPERSET_SERVICE: string = process.env.REACT_APP_DATASTUDIO_SUPERSET_SERVICE || ''