export const BREADCRUMBS: { [key: string]: string } = {
  '/management': 'Gestión',
  '/management/users': 'Usuarios',
  '/management/users/:id': 'Grupos',
  '/management/clients': 'Clientes',
  '/management/organizations': 'Organizaciones',
  '/management/modules': 'Módulos',
  '/management/permissions': 'Permisos',
  '/management/groups': 'Grupos',
  '/management/business': 'Empresas',
  '/management/catalog': 'Catálogo',
  '/process': 'Procesos',
  '/process/models': 'Modelos',
  '/process/models/files': 'Detalle',
  '/process/banking': 'Bancos',
  '/process/banking/register': 'Registro',
  '/process/banking/dashboard': 'Dashboard',
  '/change-password': 'Cambio de contraseña',
};