import React, { useEffect, useState } from 'react';
// Material UI
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
// Services
import { getGroups } from '../../../services/API/GroupServices';
import { getOrganizations } from '../../../services/API/OrganizationServices';
import { registerUserGroup } from '../../../services/API/UserServices';
// Types
import { Group } from '../../../types/Group';
import { Organization } from '../../../types/Organization';
// Redux
import { useAppDispatch } from '../../../consts/ReduxHooks';
import { showSnackbar } from '../../../store/reducers/alertReducer';
// App
import { FancyProgress, FancyButton, FancyTextInput } from '../../common/FancyComponents';
import FancyList from '../../common/FancyList';
import VirtualizedAutocompleteComponent from '../../common/VirtualizedAutocompleteComponent';
// Other
import axios, { CancelTokenSource } from 'axios';
import { RouteComponentProps, useHistory } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    masteCheckContainer: {
      background: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      margin: theme.spacing(1, 0),
      padding: theme.spacing(0, 1)
    },
  })
);

const source: CancelTokenSource[] = new Array(2).fill(axios.CancelToken.source());

export default function GroupListComponent(props: RouteComponentProps<{ id: string }>) {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [groups, setGroups] = useState<Group[]>([]);
  // const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(false);
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState<string[]>([]);
  const [loadingUserGroups, setLoadingUserGroups] = useState(false);
  const [organizations, setOrganizations] = useState<Organization[]>([]);
  const [loadingOrganizations, setLoadingOrganizations] = useState(false);
  const [organization, setOrganization] = useState<Organization | null>(null);
  // const [permissions, setPermissions] = useState<Permission[]>([]);
  // const [loadingPermissions, setLoadingPermissions] = useState(false);
  // const [modules, setModules] = useState<Module[]>([]);
  // const [loadingModules, setLoadingModules] = useState(false);
  const [busy, setBusy] = useState(false);
  // const limit = 30;

  async function fetchGroups(organization: Organization, reset: boolean = false) {
    if (reset) {
      setLoading(true);
    }
    // const newPage = reset ? 0 : page;
    const params = {
      organization: organization.id,
      // limit: limit,
      // offset: newPage * limit,
    }
    source[0] = axios.CancelToken.source();
    getGroups(source[0], params)
      .then(response => {
        setGroups(response);
        // setGroups(prev => reset ? response.results : prev.concat(response.results));
        // setPage(prev => reset ? 1 : prev + 1);
        // setHasMore(!!response.next);
        setLoading(false);
      })
      .catch(error => {
        if (!error.isCanceled) {
          setGroups([]);
          // setPage(0);
          setHasMore(false);
          setLoading(false);
        }
      });
  };

  async function fetchUserGroups(organization: Organization) {
    setLoadingUserGroups(true);
    const params = {
      // paginate: false as false,
      uid: props.match.params.id,
      organization: organization.id,
    }
    getGroups(source[1], params)
      .then(response => {
        if (response && response.length > 0) {
          setChecked(response.map(group => group.group));
        } else {
          setChecked([]);
        }
        setLoadingUserGroups(false);
      })
      .catch(error => {
        if (!error.isCanceled) {
          setLoadingUserGroups(false);
        }
      });
  };

  async function fetchOrganizations() {
    setLoadingOrganizations(true);
    const params = {
      paginate: false as false,
    }
    getOrganizations(source[1], params)
      .then(response => {
        setOrganizations(response);
        setLoadingOrganizations(false);
      })
      .catch((error) => {
        if (!error.isCanceled) {
          setLoadingOrganizations(false);
        }
      });
  };

  useEffect(() => {
    source[1] = axios.CancelToken.source();
    fetchOrganizations();
    return () => {
      source.forEach(s => s.cancel());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleCheckboxToggle(value: string) {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleCheckSubmit = () => {
    if (!organization) {
      dispatch(showSnackbar({ message: 'Debe seleccionar una organización', type: 'error' }));
      return;
    }
    setBusy(true);
    const data = {
      user: props.match.params.id,
      organization: organization.id,
      groups: checked,
    };
    registerUserGroup(data, source[1])
      .then(response => {
        dispatch(showSnackbar({ message: 'Se han asignado los grupos correctamente', type: 'success' }));
        setBusy(false);
        history.push('/management/users');
      })
      .catch(error => {
        if (!error.isCanceled) {
          setBusy(false);
        }
      });
  };

  const ExpandContent = ({ group }: { group: Group }) => (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Typography variant="subtitle2"><strong>Módulos</strong></Typography>
        <Typography variant="body2">
          {group.modules.map(mod => mod.name).join(', ')}
        </Typography>
      </Grid>
    </Grid>
  );

  return (
    <React.Fragment>
      <Grid container>
        <Grid item xs={12} sm={6} md={4}>
          <VirtualizedAutocompleteComponent<Organization>
            value={organization}
            options={organizations}
            onChange={(event, newValue) => {
              setOrganization(newValue);
              if (newValue) {
                fetchUserGroups(newValue)
                fetchGroups(newValue, true);
              }
            }}
            getOptionSelected={(option, selected) => option.id === selected.id}
            getOptionLabel={option => option.name}
            renderOption={option => <Typography noWrap>{option.name}</Typography>}
            loading={loadingOrganizations}
            aria-label="Organización"
            renderInput={params => <FancyTextInput
              {...params}
              label="Organización"
              id="grupoForm_organization"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {loadingOrganizations ? <CircularProgress aria-label="Organización" color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />}
          />
        </Grid>
      </Grid>
      {
        organization && !loading && !loadingUserGroups && <React.Fragment>
          {
            checked.length > 0 && groups.length > 0 && (
              <Paper
                square
                className={classes.masteCheckContainer}
                role="region"
                aria-label="Opciones Checkbox"
              >
                <ListItem disableGutters ContainerComponent="div">
                  <ListItemText
                    disableTypography
                    primary={(
                      <Grid container justify="space-between" alignItems="center">
                        <Grid item>
                          <Typography>
                            {`${checked.length} seleccionado${checked.length > 1 ? 's' : ''}`}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <FancyButton
                            type="button"
                            color="inherit"
                            variant="outlined"
                            onClick={handleCheckSubmit}
                            disabled={busy}
                            loading={busy}
                          >
                            Asignar al usuario
                          </FancyButton>
                        </Grid>
                      </Grid>
                    )}
                  />
                </ListItem>
              </Paper>
            )
          }
          <FancyList<Group>
            infinite
            divider
            check
            expand
            checked={checked}
            checkboxOnChange={group => handleCheckboxToggle(group.group)}
            idKey="group"
            elements={groups}
            hasMore={hasMore}
            fetchElements={() => fetchGroups(organization, false)}
            ListProps={{ "aria-label": "Grupos" }}
            ListItemTextProps={group => ({
              primary: group.group,
              secondary: group.organization.name,
            })}
            expandContent={group => <ExpandContent group={group} />}
          />
        </React.Fragment>
      }
      {
        (loading || loadingUserGroups) &&
        <FancyProgress aria-label="Lista de grupos" color="primary" size={100} />
      }
    </React.Fragment>
  );
}
