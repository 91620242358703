// v1.0
import { Observable } from 'rxjs';
import { io, Socket } from "socket.io-client";
// Redux
import store from '../../store';
import { showSnackbar } from '../../store/reducers/alertReducer';

const { dispatch } = store;
let socket: Socket | null = null;

export const connectSocket = (email: string) => {
  socket = io((process.env.REACT_APP_SOCKET_SERVICE || ''), {
    query: {
      email: email,
    }
  });

  // Listen for messages from socket
  socket.on('error', (error) => {
    console.log(error);
    dispatch(showSnackbar({ message: error.toString(), type: 'error' }));
  });
}

export const disconnectSocket = () => {
  if (socket) {
    socket.disconnect();
    socket = null;
  }
}

// Listen for socket messages
export const onMessage = <T = any>(event: string) => {
  return new Observable<T>(observer => {
    if (socket) {
      socket.on(event, (data: string) => {
        const dataAsT: T = JSON.parse(data);
        console.log(dataAsT);
        observer.next(dataAsT);
      });
    }
  });
}

// Send message to socket.
export function sendMessageSocket(event: string, data: any) {
  if (socket) {
    socket.emit(event, JSON.stringify(data));
  }
}
