import axios, { CancelTokenSource } from "axios";
// Redux
import store from "../../store";
import { showSnackbar } from "../../store/reducers/alertReducer";
// Types
import {
  ApiInvoice,
  ApiInvoiceBody,
  Category,
  Crop,
  Import,
  Invoice,
  InvoiceClassification,
  PaymentType,
  Provider,
  RandomApiInvoice,
  RandomCategory,
  RandomCrop,
  RandomInvoiceClassification,
  RandomPaymentType,
  RandomProvider,
  Split,
} from "../../types/Invoice";
// App
import { CATALOG_SERVICE, ERP_PROCESS, MAKE_API_REQUEST } from "../../consts/App";
import { Params } from "../../types/Api";
import getListData from "../getListData";

const { dispatch } = store;
const defaultConfig = {
  baseURL: ERP_PROCESS,
};

export async function getInvoice(id: string, source: CancelTokenSource) {
  if (MAKE_API_REQUEST) {
    return axios
      .get<ApiInvoice>(`invoice/${id}/`, {
        ...defaultConfig,
        cancelToken: source.token,
      })
      .then((response) => {
        console.log(response.data);
        const parsedInvoice = parseApiInvoice(response.data, id);
        console.log(parsedInvoice);
        return parsedInvoice;
      });
  } else {
    const response = new RandomApiInvoice() as ApiInvoice;
    return Promise.resolve(parseApiInvoice(response, id));
  }
}

export async function updateInvoice(data: ApiInvoiceBody, source: CancelTokenSource) {
  if (MAKE_API_REQUEST) {
    return axios
      .put(`invoice/`, data, {
        ...defaultConfig,
        cancelToken: source.token,
      })
      .then((response) => {
        dispatch(showSnackbar({ message: "Registro exitoso", type: "success" }));
        return response;
      });
  } else {
    dispatch(showSnackbar({ message: "Registro exitoso", type: "success" }));
    return null;
  }
}

export function parseApiInvoice(invoice: ApiInvoice, filename: string): Invoice {
  const imports: Import[] = [];
  for (let i = 0; i < invoice.imports.bases.length; i++) {
    imports.push({
      base: invoice.imports.bases[i],
      retention: invoice.imports.retention[i],
      iva: invoice.imports.iva[i],
    });
  }
  const splits: Split[] = [];
  for (let i = 0; i < invoice.splits.ids.length; i++) {
    splits.push({
      id: invoice.splits.ids[i],
      crop: invoice.splits.crop[i],
      code: invoice.splits.code[i],
      category: invoice.splits.category[i],
      base: invoice.splits.base[i],
    });
  }
  let i: Invoice = {
    ...invoice,
    fileName: filename,
    provider: {
      name: invoice.providerName,
      cif: invoice.providerCif,
    },
    total: invoice.imports.total,
    imports: imports,
    splits: splits,
  };
  return i;
}

export function parseInvoice(invoice: Invoice): ApiInvoiceBody {
  const partialInvoice: Partial<Invoice> = { ...invoice };
  const provider = { ...invoice.provider };
  delete partialInvoice.imports;
  delete partialInvoice.invoicePdf;
  delete partialInvoice.provider;
  const requireInvoice = partialInvoice as Invoice;
  const splits = {
    ids: invoice.splits.map(split => split.id),
    crop: invoice.splits.map(split => split.crop),
    category: invoice.splits.map(split => split.category),
    code: invoice.splits.map(split => split.code),
    base: invoice.splits.map(split => split.base),
  }
  return {
    ...requireInvoice,
    providerName: provider.name,
    providerCif: provider.cif,
    splits: splits,
  };
}

const defaultConfigCatalogs = {
  baseURL: CATALOG_SERVICE,
};

export async function getProvider(source: CancelTokenSource, params?: Params) {
  if (MAKE_API_REQUEST) {
    return axios.get<{ providerCatalog: Provider[] }>("ocr/providers/", {
      ...defaultConfigCatalogs,
      params: params,
      cancelToken: source.token
    })
      .then((response) => response.data.providerCatalog);
  } else {
    const count = params?.limit || 10;
    const response = getListData<Provider>(count, () => new RandomProvider(), true);
    return Promise.resolve(response);
  }
}

export async function getPaymentType(source: CancelTokenSource, params?: Params) {
  if (MAKE_API_REQUEST) {
    return axios.get<PaymentType[]>("catalogs/catalogs/invoicePaymentTypeCatalog/",
      {
        ...defaultConfigCatalogs,
        params: params,
        cancelToken: source.token
      })
      .then((response) => response.data);
  } else {
    const count = params?.limit || 10;
    const response = getListData<PaymentType>(count, () => new RandomPaymentType(), true);
    return Promise.resolve(response);
  }
}

export async function getInvoiceClassification(source: CancelTokenSource, params?: Params) {
  if (MAKE_API_REQUEST) {
    return axios.get<InvoiceClassification[]>("catalogs/catalogs/invoiceTypeCatalog/",
      {
        ...defaultConfigCatalogs,
        params: params,
        cancelToken: source.token
      })
      .then((response) => response.data);
  } else {
    const count = params?.limit || 10;
    const response = getListData<InvoiceClassification>(count, () => new RandomInvoiceClassification(), true);
    return Promise.resolve(response);
  }
}

// export async function getPaymentNature(source: CancelTokenSource, params?: Params) {
//   if (MAKE_API_REQUEST) {
//     return axios.get<Elements[]>('PaymentNature/', { ...defaultConfigCatalogs, params: params, cancelToken: source.token })
//       .then(response => response.data);
//   } else {
//     const count = params?.limit || 10;
//     const response = getListData<Elements>(count, () => new RandomElements(), true);
//     return Promise.resolve(response);
//   }
// }

export async function getCrop(source: CancelTokenSource, params?: Params) {
  if (MAKE_API_REQUEST) {
    return axios.get<Crop[]>("catalogs/catalogs/subbusiness1InfoCatalog/",
      {
        ...defaultConfigCatalogs,
        params: params,
        cancelToken: source.token
      })
      .then((response) => response.data);
  } else {
    const count = params?.limit || 10;
    const response = getListData<Crop>(count, () => new RandomCrop(), true);
    return Promise.resolve(response);
  }
}

// export async function getCode(source: CancelTokenSource, params?: Params) {
//   if (MAKE_API_REQUEST) {
//     return axios.get<Elements[]>('code/', { ...defaultConfigCatalogs, params: params, cancelToken: source.token })
//       .then(response => response.data);
//   } else {
//     const count = params?.limit || 10;
//     const response = getListData<Elements>(count, () => new RandomElements(), true);
//     return Promise.resolve(response);
//   }
// }

export async function getCategory(source: CancelTokenSource, params?: Params & { cif?: string }) {
  if (MAKE_API_REQUEST) {
    return axios.get<Category[]>(`ocr/categories`,
      {
        ...defaultConfigCatalogs,
        params: params,
        cancelToken: source.token
      })
      .then((response) => response.data);
  } else {
    const count = params?.limit || 10;
    const response = getListData<Category>(count, () => new RandomCategory(), true);
    return Promise.resolve(response);
  }
}
