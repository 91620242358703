import React, { useEffect } from 'react';
// Material UI
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
// App
import { FancyButton } from '../../common/FancyComponents';
// Services
import { downloadDriveFiles } from '../../../services/API/BpmnServices';
// import { driveAuthentication, driveFiles } from '../../services/BpmnServices';
// Redux
import { useAppSelector, useAppDispatch } from '../../../consts/ReduxHooks';
import { showDiagramBpmn } from '../../../store/reducers/diagramsReducer';
import { showSnackbar } from '../../../store/reducers/alertReducer';
// Others
import { Link } from 'react-router-dom';
import axios from 'axios';
import ReactBpmn from 'react-bpmn';
// import BpmnViewer from 'bpmn-js';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      width: '100%',
      height: '100%',
      '& div': {
        width: '100%',
        height: '75vh',
        '&. react-bpmn-diagram-container': {
          width: '100%',
        },
      },
      '&. div.react-bpmn-diagram-container': {
        width: '100%',
        height: '100vh',
      },
      '&. djs-container': {
        width: '100%',
        height: '100vh',
      }
    },
  })
);

let source = axios.CancelToken.source();

export default function Bpmn () {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const fileSelected = useAppSelector(state => state.diagram.fileSelected);
  const [file, setFile] = React.useState('')

  async function download (files) {
    const params = {
      file: files.id,
      mime: files.mimeType,
      filename: files.originalFilename,
    }
    downloadDriveFiles(params, source)
      .then(response => {
        dispatch(showDiagramBpmn(response))
        let bpmnFile = new Blob([response], { type: 'text/plain' });
        setFile(window.URL.createObjectURL(bpmnFile))
      })
  }

  useEffect(() => {
    if (fileSelected.name !== '') {
      dispatch(showSnackbar({ message: 'Cargando archivo bpmn ...' }));
    }

    source = axios.CancelToken.source();
    download(fileSelected)
    return () => {
      source.cancel();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function onShown () {
    console.log('diagram shown');
    dispatch(showSnackbar({ message: 'El diagrama se ha cargado exitosamente', type: 'success' }));
  }

  function onLoading () {

    console.log('diagram loading');
  }

  function onError (err) {
    dispatch(showSnackbar({ message: 'ups, ha ocurrido un problema', type: 'error' }));
    console.log('failed to show diagram');
  }

  return (fileSelected.name === '') ? (
    <Grid container direction="column" alignItems="center" spacing={2}>
      <Grid item>
        <Typography variant="h5">
          Ups, no haz seleccionado ningun archivo para visualizar
        </Typography>
      </Grid>
      <Grid item>
        <FancyButton
          variant="contained"
          color='primary'
          component={Link}
          to="/process/models/list"
        >
          Ver archivos
        </FancyButton>
      </Grid>
    </Grid>
  ) : (
      <Grid container>
        <Grid item xs={12} container alignItems="center">
          <Grid item>
            <IconButton component={Link} to="/process/models/list" color="primary">
              <ArrowBackIcon />
            </IconButton>
          </Grid>
          <Grid item>
            <Typography>{fileSelected.name}</Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} className={classes.root}>
          <ReactBpmn
            url={file}
            onShown={onShown}
            onLoading={onLoading}
            onError={onError}
          />
        </Grid>
      </Grid>
    );
}
